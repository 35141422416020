import Button from "components/button-v2"
import Flex from "components/flex"
import { TbAlertTriangleFilled } from "react-icons/tb"

interface AlertMessageProps {
	title: string
	message: string
	buttonText: string
	buttonAction: () => void
}

export default function AlertMessage({
	title,
	message,
	buttonText,
	buttonAction,
}: AlertMessageProps) {
	return (
		<div className="p-3 bg-[#FFF9EB] border border-[#FDECC8] rounded-[8px] text-[#3D3D3D]">
			<Flex className="gap-1">
				<TbAlertTriangleFilled color="#F7A428" size="16px" />
				<p className=" text-cta-2 font-bold">{title}</p>
			</Flex>

			<p className="mt-1 text-small-1 font-semibold">{message}</p>

			<Button
				variant="text"
				className="!text-[#F7A428] hover:!text-[#F1810F] mt-4"
				onClick={buttonAction}
			>
				{buttonText}
			</Button>
		</div>
	)
}
