import { Position, useReactFlow } from "@xyflow/react"
import Center from "components/center"
import Handle from "../subcomponents/Handle"
import { TbFileInvoice } from "react-icons/tb"
import { useState } from "react"
import { Node, NodeData } from "../subcomponents/Flow"
import { useAtom } from "jotai"
import { graphExecutionAtom } from "../atoms"
import LoadingSpinner from "pages/MyDocumentsPage/subcomponents/LoadingSpinner"
import ReactMarkdown from "react-markdown"
import { useSaveNode } from "../hooks/useSaveNode"
import CustomResizable from "components/custom-resizable"

export default function Preview({ data, id }: { data: NodeData; id: string }) {
	const [isPreview, setIsPreview] = useState(true)
	const [graphExecution] = useAtom(graphExecutionAtom)
	const { setNodes, getNodes, getNode } = useReactFlow()
	const nodes = getNodes()

	const currentNode = getNode(id) as Node | undefined

	const graphResult = graphExecution?.graph_execution?.data?.result?.result

	const { graphId } = data

	function onResizeNode(width: number, height: number) {
		const newNodes = nodes.map((node) => {
			if (node.id === id) {
				return {
					...node,
					size: {
						width,
						height,
					},
				}
			}
			return node
		})
		setNodes(newNodes)
	}

	useSaveNode({
		currentNode,
		graphId,
		id,
	})

	return (
		<>
			<div className="drag p-2 flex flex-col gap-2 bg-white border-[1px] border-brand-white-4 rounded-2xl  min-w-[640px]  h-full w-full">
				<Handle type="target" position={Position.Left} />
				<Handle type="source" position={Position.Right} />

				<div className="flex items-center justify-between bg-[#FFF2E0] p-2 pr-[20px] rounded-lg">
					<div className="  flex items-center text-[#D37C3D] font-semibold  gap-3">
						<Center className="w-[48px] h-[48px] p-2 bg-white rounded">
							<TbFileInvoice size="32px" color="#D37C3D" />
						</Center>

						<h4 className=" text-[#D37C3D] max-w-[248px] truncate">
							Output
						</h4>
					</div>

					<div className="flex items-center gap-[4px] fill-white p-[4px] bg-white rounded nodrag">
						<div
							className="rounded flex items-center justify-center cursor-pointer"
							onClick={() => setIsPreview(true)}
						>
							<p
								className={`text-small-1 font-semibold p-[4px]  rounded ${
									isPreview
										? "text-white bg-[#D37C3D]"
										: "text-[#D37C3D] bg-white"
								} `}
							>
								Ver Preview
							</p>
						</div>
						<div
							className=" flex items-center justify-center rounded cursor-pointer"
							onClick={() => setIsPreview(false)}
						>
							<p
								className={`text-small-1 font-semibold p-[4px] rounded ${
									!isPreview
										? "text-white bg-[#D37C3D]"
										: "text-[#D37C3D] bg-white"
								}`}
							>
								Ver Markdown
							</p>
						</div>
					</div>
				</div>

				<div className="p-4 bg-brand-white-2 rounded-lg overflow-y-auto h-full">
					<CustomResizable
						minWidth={640}
						minHeight={640}
						handleClasses={{
							bottomRight:
								"resize-handle !bottom-[-15px] !right-[-15px]",
						}}
						onResizeStop={(_, __, elementRef) => {
							onResizeNode(
								elementRef.clientWidth,
								elementRef.clientHeight,
							)
						}}
						defaultSize={{
							width: currentNode?.size.width,
							height: currentNode?.size.height,
						}}
					>
						<div className="relative h-full">
							{graphExecution?.graph_execution.reason ===
								"START" && (
								<div className=" absolute inset-0 flex items-center justify-center">
									<div className=" opacity-50 bg-white w-full h-full" />
									<div className="absolute">
										<LoadingSpinner />
									</div>
								</div>
							)}
							{isPreview ? (
								<textarea
									className="nodrag  resize-none p-3 rounded-lg border-[#F0F0F0] placeholder:text-[#B0B0B0] min-h-[400px] h-full w-full"
									value={graphResult?.value || ""}
									onChange={() => {}}
								/>
							) : (
								<div>
									<ReactMarkdown
										className="nodrag nowheel   cursor-auto select-text"
										components={{
											h1(props) {
												const { ...rest } = props
												return (
													<h2
														className="text-[18px] mb-[16px] xl:text-h1 xl:mb-[32px] xl:mt-[72px]"
														{...rest}
													/>
												)
											},
											h2(props) {
												const { ...rest } = props
												return (
													<h2
														className="text-[18px] mb-[16px] xl:text-h1 xl:mb-[32px] xl:mt-[72px]"
														{...rest}
													/>
												)
											},
											h3(props) {
												const { ...rest } = props
												return (
													<h2
														className="text-[18px]  mt-[32px] mb-[16px] xl:text-[28px] xl:mb-[32px] xl:mt-[72px]"
														{...rest}
													/>
												)
											},
											p(props) {
												const { ...rest } = props
												return (
													<p
														className="!text-cta-1 xl:!text-[22px] text-website-gray-2 mb-[16px] xl:mb-[32px] "
														{...rest}
													/>
												)
											},
											ul(props) {
												const { ...rest } = props
												return (
													<ul
														{...rest}
														className="list-disc text-website-gray-2 !text-cta-1 xl:!text-[22px] pl-[20px] xl:pl-[40px] mb-[16px] xl:mb-[32px]"
													>
														{rest.children}
													</ul>
												)
											},
											li(props) {
												const { ...rest } = props
												return (
													<li
														{...rest}
														className="mb-[8px] text-website-gray-2 !text-cta-1 xl:!text-[22px] xl:mb-[12px]"
													>
														{rest.children}
													</li>
												)
											},
										}}
									>
										{`${
											graphResult
												? graphResult?.value
												: ""
										}`}
									</ReactMarkdown>
								</div>
							)}
						</div>
					</CustomResizable>
				</div>
			</div>
		</>
	)
}
