import React from "react"

import type { PlateContentProps } from "@udecode/plate-common"
import type { VariantProps } from "class-variance-authority"

import { cn } from "@udecode/cn"
import { PlateContent } from "@udecode/plate-common"
import { cva } from "class-variance-authority"

const editorVariants = cva(
	cn(
		"relative overflow-x-auto whitespace-pre-wrap break-words",
		"min-h-[80px] w-full rounded-md bg-background px-6 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none",
		"[&_[data-slate-placeholder]]:text-muted-foreground [&_[data-slate-placeholder]]:!opacity-100",
		"[&_[data-slate-placeholder]]:top-[auto_!important]",
		"[&_strong]:font-bold",
	),
	{
		defaultVariants: {
			focusRing: true,
			size: "sm",
			variant: "outline",
		},
		variants: {
			disabled: {
				true: "cursor-not-allowed opacity-50",
			},
			focusRing: {
				false: "",
				true: "focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
			},
			focused: {
				true: "ring-2 ring-ring ring-offset-2",
			},
			size: {
				md: "text-base",
				sm: "text-sm",
			},
			variant: {
				ghost: "",
				outline: "border border-input",
			},
		},
	},
)

export type EditorProps = PlateContentProps &
	VariantProps<typeof editorVariants> & {
		contentRef?: any
	}

const Editor = React.forwardRef<HTMLDivElement, EditorProps>(
	({ className, disabled, readOnly, style, ...props }, ref) => {
		return (
			<div className=" shadow-1 bg-white">
				<div ref={ref}>
					<PlateContent
						id="editor"
						aria-disabled={disabled}
						className={`${className}  border-none shadow-none !font-lato`}
						readOnly={disabled ?? readOnly}
						{...props}
						style={style}
					/>
				</div>
			</div>
		)
	},
)
Editor.displayName = "Editor"

export { Editor }
