import { Position, useReactFlow } from "@xyflow/react"
import Center from "components/center"
import Handle from "../subcomponents/Handle"
import { ButtonVariant } from "components/button/types"
import { TbAdjustmentsHorizontal, TbEye, TbFileSearch } from "react-icons/tb"
import { RefObject, useEffect, useRef, useState } from "react"
import {
	graphExecutionAtom,
	inputVariablesAtom,
	openRetrievalConfigAtom,
	variablesAtom,
} from "../atoms"
import { useAtom } from "jotai"
import ActionNodeHandler from "../subcomponents/ActionNodeHandler"
import { Node, NodeData } from "../subcomponents/Flow"
import AutosizeInput from "react-input-autosize"
import LoadingSpinner from "pages/MyDocumentsPage/subcomponents/LoadingSpinner"
import NodeError from "../subcomponents/NodeError"
import NodeSuccess from "../subcomponents/NodeSuccess"
import { useSaveNode } from "../hooks/useSaveNode"
import CustomResizable from "components/custom-resizable"
import Button from "components/button-v2"
import Select from "components/select"

import { NodeExecutionAtom } from "types/agentBuilder"
import NodeTextArea from "../subcomponents/NodeTextArea"

export default function Retrieval({
	data,
	id,
}: {
	data: NodeData
	id: string
}) {
	const [showOutput, setShowOutput] = useState(true)
	const [nodeName, setNodeName] = useState(data.nodeData.name)
	const [editTitle, setEditTitle] = useState(false)
	const [, setOpenRetrievalConfig] = useAtom(openRetrievalConfigAtom)
	const { nodeData, graphId } = data
	const { setNodes, getNodes, getNode } = useReactFlow()
	const nodes = getNodes()
	const currentNode = getNode(id) as Node | undefined
	const [graphExecution] = useAtom(graphExecutionAtom)

	const inputRef: RefObject<AutosizeInput> &
		(string | RefObject<HTMLInputElement>) = useRef(null)

	const [variables] = useAtom(variablesAtom)
	const [inputVariables] = useAtom(inputVariablesAtom)

	const onRename = () => {
		setEditTitle(true)
	}

	const onSaveTitle = () => {
		const newNodes = nodes.map((node) => {
			if (node.id === id) {
				return {
					...node,
					data: {
						...node.data,
						nodeData: {
							...(typeof node.data.nodeData === "object"
								? node.data.nodeData
								: {}),
							name: nodeName || data.nodeData.name,
						},
					},
				}
			}
			return node
		})
		if (!nodeName) {
			setNodeName(data.nodeData.name)
		}
		setNodes(newNodes)
		setEditTitle(false)
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			event.preventDefault()
			onSaveTitle()
		}
	}

	function handleChangePrompt(e: React.ChangeEvent<HTMLTextAreaElement>) {
		const newNodes = nodes.map((node) => {
			if (node.id === id) {
				return {
					...node,
					data: {
						...node.data,
						nodeData: {
							...(typeof node.data.nodeData === "object"
								? node.data.nodeData
								: {}),
							query: e.target.value,
						},
					},
				}
			}
			return node
		})
		setNodes(newNodes)
	}

	function handleChangeSelectedVariable(e: string) {
		const selectedVariable = selectOptions.find((item) => item.label === e)

		const newNodes = nodes.map((node) => {
			if (node.id === id) {
				return {
					...node,
					data: {
						...node.data,
						nodeData: {
							...(typeof node.data.nodeData === "object"
								? node.data.nodeData
								: {}),
							variable_to_retrieve:
								selectedVariable?.label.replace(/[{}]/g, ""),
						},
					},
				}
			}
			return node
		})
		setNodes(newNodes)
	}

	function getQueryValue() {
		if (nodeData?.query && nodeData?.query !== "prompt") {
			return nodeData.query
		}
		return ""
	}

	function onResizeNode(width: number, height: number) {
		const newNodes = nodes.map((node) => {
			if (node.id === id) {
				return {
					...node,
					size: {
						width,
						height,
					},
				}
			}
			return node
		})
		setNodes(newNodes)
	}

	useEffect(() => {
		if (editTitle && inputRef.current) {
			inputRef.current.select()
		}
	}, [editTitle])

	useSaveNode({
		currentNode,
		graphId,
		id,
	})

	const nodeExecution = graphExecution?.[id] as NodeExecutionAtom | undefined

	const selectOptions = [
		...inputVariables.filter((variable) => variable.id),
		...variables.filter((variable) => variable.value.length > 0),
	]
		.filter((variable) => variable.type === "FILES")
		.map((variable) => ({
			label: variable.name,
			value: variable.id || "",
			files: variable.value || [],
		}))

	return (
		<>
			<ActionNodeHandler
				id={id}
				onRename={onRename}
				isError={
					!!(nodeExecution && nodeExecution?.data?.status === "ERROR")
				}
			>
				<div className="min-w-[400px] p-2 flex flex-col gap-2 bg-white border-[1px] border-brand-white-4 rounded-2xl h-full ">
					<Handle type="target" position={Position.Left} />
					<Handle type="source" position={Position.Right} />

					<div className="flex items-center justify-between bg-[#E8DEEE] p-2 pr-[20px] rounded-lg">
						<div className="  flex items-center text-[#AF6BEF] font-semibold  gap-3">
							<Center className="w-[48px] h-[48px] p-2 bg-white rounded">
								<TbFileSearch size="32px" color="#AF6BEF" />
							</Center>

							<div className="flex flex-col ">
								<p className="text-small-1">Retrieval</p>
								{!editTitle ? (
									<h4 className=" text-[#AF6BEF] max-w-[178px] truncate">
										{nodeName}
									</h4>
								) : (
									<AutosizeInput
										inputClassName="text-[#AF6BEF] p-0  m-0 h-[27px]  text-h4 font-semibold border-none bg-transparent  focus-visible:!outline-none max-w-[178px] overflow-hidden text-ellipsis whitespace-nowrap"
										value={nodeName}
										onChange={(event) =>
											setNodeName(event.target.value)
										}
										onBlur={onSaveTitle}
										autoFocus
										ref={inputRef}
										onKeyDown={handleKeyDown}
										maxLength={50}
									/>
								)}
							</div>
						</div>

						<div className="flex items-center gap-2 fill-white">
							<Button
								variant={ButtonVariant.Contained}
								color="purple"
								icon={<TbAdjustmentsHorizontal size="16px" />}
								onClick={() => setOpenRetrievalConfig(id)}
								size="small"
								iconPosition="right"
								className="bg-[#AF6BEF] hover:bg-[#AF6BEF] !shadow-none"
							/>
						</div>
					</div>

					<CustomResizable
						onResizeStop={(_, __, elementRef) => {
							onResizeNode(
								elementRef.clientWidth,
								elementRef.clientHeight,
							)
						}}
						defaultSize={{
							width: currentNode?.size.width,
							height: currentNode?.size.height,
						}}
					>
						<div className="p-4 bg-brand-white-2 rounded-lg h-full flex flex-col gap-3">
							<p className="text-cta-2">Variável de anexo</p>

							<Select
								placeholder="Selecionar"
								onSelect={handleChangeSelectedVariable}
								options={selectOptions}
								whitoutCreate
								disabled={selectOptions.length === 0}
								currentValue={nodeData?.variable_to_retrieve}
							/>

							<p className="text-cta-2 ">Input de busca</p>

							<textarea
								className="nodrag  resize-none p-3 rounded-lg border-[#F0F0F0] placeholder:text-[#B0B0B0] min-h-[104px] h-full  w-full"
								placeholder="Digite seu comando"
								value={getQueryValue()}
								onChange={(e) => handleChangePrompt(e)}
							/>
						</div>
					</CustomResizable>

					{nodeExecution && nodeExecution?.reason !== "ERROR" && (
						<div className="p-4 bg-brand-white-2 rounded-lg ">
							<div
								className={`flex items-center justify-between w-full  ${
									showOutput && "mb-3"
								}`}
							>
								<p className="text-cta-2">Output</p>
								<Center
									className="p-[4px] rounded border-[1px] border-[#F0F0F0] nodrag cursor-pointer"
									onClick={() => setShowOutput(!showOutput)}
								>
									<TbEye size="16px" color="#6D6D6D" />
								</Center>
							</div>

							{showOutput && (
								<div className="relative">
									{nodeExecution?.reason === "START" && (
										<div className=" absolute inset-0 flex items-center justify-center">
											<div className=" opacity-50 bg-white w-full h-full" />
											<div className="absolute">
												<LoadingSpinner />
											</div>
										</div>
									)}
									<NodeTextArea
										className=" resize-y p-3 rounded-lg  "
										value={
											nodeExecution?.data?.result
												?.result || ""
										}
										onChange={() => {}}
										disabled={
											nodeExecution?.reason === "START"
										}
									/>
								</div>
							)}
						</div>
					)}

					{nodeExecution && nodeExecution?.reason === "ERROR" && (
						<NodeError error={nodeExecution?.detail?.error || ""} />
					)}

					{nodeExecution && nodeExecution?.reason === "SUCCESS" && (
						<NodeSuccess
							nodeExecutionStatus={nodeExecution?.data}
						/>
					)}
				</div>
			</ActionNodeHandler>
		</>
	)
}
