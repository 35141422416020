enum FEATURE_FLAGS {
	legalDocumentsRework = "legal-documents-rework",
	isAdmin = "is-admin",
	isAdminUser = "is-admin-user",
	useDocumentAsModel = "use-document-as-model",
	newBussinessModel = "pl5-new-business-model",
	newDocumentsFlow = "new-documents-flow",
}

export default FEATURE_FLAGS
