import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"
import PlusIcon from "icons/PlusIcon"
import FlowColumnItem from "./subcomponents/FlowColumnItem"
import AddFlowModal from "./subcomponents/AddFlowModal"
import { useMemo, useState } from "react"
import { Navigate } from "react-router-dom"
import { useFeatureFlag } from "hooks/useFeatureFlag"
import FEATURE_FLAGS from "feature-flags"
import useFlowBuilder from "hooks/useFlowBuilder"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import Loading from "components/loading"
import { Flow } from "types/flowBuilder"

const columnHeader = [
	{
		title: "Flow",
		key: "flow",
		width: "w-[30%]",
	},
	{
		title: "Status",
		key: "status",
		width: "w-[10%]",
	},
	{
		title: "Addons",
		key: "addons",
		width: "w-[10%]",
	},
	{
		title: "Título do anexo",
		key: "title",
		width: "w-[15%]",
	},
	{
		title: "Texto placeholder",
		key: "text",
		width: "w-[30%]",
	},
	{
		title: "",
		key: "actions",
		width: "w-[5%]",
	},
]

export default function FlowBuilderPage() {
	const [openModal, setOpenModal] = useState(false)
	const [flowToEdit, setFlowToEdit] = useState<Flow | null>(null)
	const { listFlows, addFlow, listPublishedGraphs, editFlow } =
		useFlowBuilder()

	const { data, isLoading } = useQuery(["listFlows"], listFlows)

	const { data: graphsData } = useQuery(
		["listPublishedGraphs"],
		listPublishedGraphs,
	)
	const queryClient = useQueryClient()

	const addFlowMutation = useMutation({
		mutationFn: addFlow,
		onSuccess: () => {
			setOpenModal(false)
			setFlowToEdit(null)
			queryClient.invalidateQueries(["listFlows"])
		},
	})
	const editFlowMutation = useMutation({
		mutationFn: editFlow,
		onSuccess: () => {
			setOpenModal(false)
			setFlowToEdit(null)
			queryClient.invalidateQueries(["listFlows"])
		},
	})

	const flowItems = useMemo(() => {
		return data
			? data.results
					.slice()
					.sort(
						(a, b) =>
							+new Date(b._created_at) - +new Date(a._created_at),
					)
			: []
	}, [data])

	const { data: isAdminUserFeatureFlagEnabled } = useFeatureFlag(
		FEATURE_FLAGS.isAdminUser,
	)

	if (
		!isAdminUserFeatureFlagEnabled &&
		isAdminUserFeatureFlagEnabled !== undefined
	)
		return <Navigate to="/" />

	if (isLoading) return <Loading />

	return (
		<div className="flex flex-col gap-8 w-full ">
			<div className="flex items-center justify-between ">
				<h3>Flow builder</h3>
				<div className="flex items-center gap-4">
					<Button
						variant={ButtonVariant.Contained}
						icon={<PlusIcon />}
						onClick={() => setOpenModal(true)}
					>
						Novo flow
					</Button>
				</div>
			</div>

			<div className="w-full flex items-center px-4 ">
				{columnHeader.map((column) => (
					<div key={column.key} className={`${column.width}`}>
						<p className="font-semibold text-dark-blue-1">
							{column.title}
						</p>
					</div>
				))}
			</div>

			<div className="flex flex-col gap-6">
				{flowItems.map((result) => (
					<FlowColumnItem
						columnHeader={columnHeader}
						key={result.id}
						result={result}
						setFlowToEdit={setFlowToEdit}
						setOpenModal={setOpenModal}
					/>
				))}
			</div>

			{openModal && (
				<AddFlowModal
					open={openModal}
					setOpen={setOpenModal}
					onAddFlow={(data) => addFlowMutation.mutate(data)}
					onEditFlow={(data) =>
						editFlowMutation.mutate({
							id: data.id || "",
							data,
						})
					}
					graphs={graphsData?.results}
					flowToEdit={flowToEdit}
					resetFlowToEdit={() => setFlowToEdit(null)}
				/>
			)}
		</div>
	)
}
