import { useMutation, useQueryClient } from "@tanstack/react-query"
import Flex from "components/flex"
import { useJwt } from "hooks/useJwt"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import { RefObject, useEffect, useRef, useState } from "react"
import { TbFileDots, TbPencil, TbTrash } from "react-icons/tb"
import AutosizeInput from "react-input-autosize"
import { RetrieveLegalDocumentResponse } from "types/legalDocument"
import { getDocumentStatus } from "utils/getDocumentStatus"
import { TbDownload } from "react-icons/tb"
import DeleteModal from "components/delete-modal"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import DropdownPortal from "./DropdownPortal"
import { useNavigate } from "react-router-dom"
import { areaIcons } from "pages/FlowBuilderPage/constants"

export default function SidebarDocItem({
	doc,
	sidebarIsOpen,
}: {
	doc: RetrieveLegalDocumentResponse
	sidebarIsOpen: boolean
}) {
	const [isEdit, setIsEdit] = useState(false)
	const [openDropdown, setOpenDropdown] = useState(false)
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const [inputTitle, setInputTitle] = useState(doc.title || "")
	const inputRef: RefObject<AutosizeInput> &
		(string | RefObject<HTMLInputElement>) = useRef(null)
	const queryClient = useQueryClient()
	const [jwt] = useJwt()
	const isDowloadDisabled = getDocumentStatus(doc) !== "completed"
	const navigate = useNavigate()
	const { updateLegalDocument, downloadFile, deleteLegalDocument } =
		useLegalDocumentAPI()

	const downloadLegalDocumentMutation = useMutation({
		mutationFn: downloadFile,
	})
	const deleteLegalDocumentMutation = useMutation({
		mutationFn: deleteLegalDocument,
		onSuccess: () => {
			queryClient.invalidateQueries(["listLegalDocuments", jwt])
			showToast(
				`O documento “${doc?.title}” foi excluído.`,
				ToastType.Error,
			)
		},
	})

	const updateLegalDocumentMutation = useMutation({
		mutationFn: updateLegalDocument,
		onSuccess: ({ id }) => {
			onSuccessUpdateDocument(id)
		},
	})
	const sections = [
		{
			items: [
				{
					className: isDowloadDisabled
						? `flex items-center hover:!bg-white p-2`
						: "",
					label: (
						<span
							className={
								isDowloadDisabled ? `text-brand-gray-2 ` : ""
							}
						>
							Baixar
						</span>
					),
					disabled: isDowloadDisabled,
					onClick: () => {
						if (!isDowloadDisabled) handleDownload("docx")
					},
					icon: (
						<TbDownload
							size="20px"
							color={isDowloadDisabled ? `#ACACAC` : ""}
						/>
					),
				},
				{
					label: "Renomear",
					onClick: () => {
						setIsEdit(true)
					},
					icon: <TbPencil size="20px" />,
				},
				{
					label: "Excluir",
					deleteLabel: true,
					onClick: () => {
						setOpenDeleteModal(true)
					},
					icon: <TbTrash size="20px" />,
				},
			],
		},
	]

	async function handleDownload(type: string) {
		await downloadLegalDocumentMutation.mutateAsync({
			id: doc.id,
			type: type,
			name: doc.title || "",
		})
	}

	function onSuccessUpdateDocument(id: string) {
		queryClient.invalidateQueries(["listLegalDocuments", jwt])
		queryClient.refetchQueries({
			queryKey: ["legalDocument", id],
		})
	}

	const onSaveTitle = async () => {
		updateLegalDocumentMutation.mutateAsync({
			id: doc.id,
			payload: {
				title: inputTitle,
			},
		})
		setIsEdit(false)
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			event.preventDefault()
			onSaveTitle()
		}
	}

	useEffect(() => {
		if (isEdit && inputRef.current) {
			inputRef.current.select()
		}
	}, [isEdit])
	return (
		<Flex
			className={` items-center hover:bg-[#F5F5F5] w-[192px] rounded-lg cursor-pointer group relative`}
			onMouseLeave={() => setOpenDropdown(false)}
			onClick={() => {
				navigate(`document/${doc.id}`)
			}}
		>
			<div className="p-3 ">
				<div className="w-[20px] h-[20px]">
					{doc.flow ? (
						areaIcons[
							doc.flow.area_of_law as keyof typeof areaIcons
						]
					) : (
						<TbFileDots color="#59A8FF" size="100%" />
					)}
				</div>
			</div>

			{!isEdit ? (
				<div className="flex items-center ">
					<p
						className={`transition-[margin-left,opacity] duration-150 ease-linear text-small-1 text-[#6D6D6D] truncate max-w-[140px] group-hover:max-w-[115px] ${
							sidebarIsOpen
								? "ml-0 opacity-100"
								: "ml-[-8px] opacity-0"
						}`}
					>
						{inputTitle || doc.title || "Documento sem título"}
					</p>
				</div>
			) : (
				<AutosizeInput
					inputClassName="  p-0 m-0 text-small-1 text-[#6D6D6D] border-none bg-transparent  focus-visible:!outline-none max-w-[115px] overflow-hidden text-ellipsis whitespace-nowrap"
					value={inputTitle || ""}
					onChange={(event) => setInputTitle(event.target.value)}
					onBlur={onSaveTitle}
					autoFocus
					ref={inputRef}
					onKeyDown={handleKeyDown}
				/>
			)}

			<div className="hidden group-hover:block ml-auto text-brand-gray-2 absolute right-[16px]">
				<DropdownPortal
					sections={sections}
					open={openDropdown}
					setOpen={setOpenDropdown}
				/>
			</div>

			<DeleteModal
				open={openDeleteModal}
				setOpen={setOpenDeleteModal}
				title="Excluir documento"
				description={
					<p className="text-[#6D6D6D] mb-1 ">
						Deseja realmente excluir o documento{" "}
						<span className="font-semibold text-[#3D3D3D]">
							“{doc.title}”
						</span>
						? Esta ação é irreversível.
					</p>
				}
				handleDelete={() => {
					deleteLegalDocumentMutation.mutate(doc.id)
				}}
			/>
		</Flex>
	)
}
