import { ToastContainer, ToastContentProps, Zoom } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { ToastType } from "./types"
import { toastClassName } from "./variables"
import { TbAlertTriangleFilled, TbCircleCheckFilled } from "react-icons/tb"
interface ToastDisplay extends Partial<ToastContentProps> {
	message: string
	type: ToastType
	ctaButton?: {
		text: string
		onClick: () => void
	}
}

export const ToastMessage = ({
	closeToast,
	message,
	type = ToastType.Success,
	ctaButton,
}: ToastDisplay) => {
	function getToastIcon() {
		if (type === "success") {
			return <TbCircleCheckFilled color="#00862E" size="24px" />
		}
		if (type === "error") {
			return <TbAlertTriangleFilled color="#FF6363" size="24px" />
		}
	}
	return (
		<div
			className={`!font-Red-Hat-Display font-semibold relative rounded-[8px] flex items-center justify-between p-4 border  ${
				type === "success" ? "border-[#9EFCB0]" : "border-[#FFC7C7]"
			}`}
		>
			<div className="flex items-center gap-2">
				<div>{getToastIcon()}</div>
				<p
					className={`${
						toastClassName[type].text
					} text-[16px] font-semibold ${ctaButton && "mr-[80px]"}`}
				>
					{message}
				</p>
			</div>

			{ctaButton && (
				<button
					onClick={() => {
						ctaButton.onClick()
						if (closeToast) {
							closeToast()
						}
					}}
					className={`${toastClassName[type].text} font-bold`}
				>
					{ctaButton.text}
				</button>
			)}
		</div>
	)
}

const Toast = () => {
	return (
		<div>
			<ToastContainer
				closeButton={false}
				pauseOnHover={false}
				autoClose={3000}
				transition={Zoom}
				limit={1}
				position="bottom-center"
				hideProgressBar={true}
			/>
		</div>
	)
}

export default Toast
