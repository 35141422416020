import { GraphNode } from "types/agentBuilder"
import { Node } from "../subcomponents/Flow"

interface Ranges {
	nodeId: string
	llm?: string
	ranges: {
		title: string
		max: number
		value: number
		id: string
	}[]
}
export function serializeNode(
	node: Node,
	graphId: string,
	promptRanges?: Ranges,
) {
	const commonProperties = {
		id: node.id,
		name: node.data.nodeData.name || "",
		pos_x: node.position.x,
		pos_y: node.position.y,
		graph: graphId,
		width: node.size.width,
		height: node.size.height,
		output_format: "TEXT",
	}

	const getPromptRangeValue = (rangeId: string, defaultValue = 0) =>
		promptRanges?.ranges.find((range) => range.id === rangeId)?.value ||
		defaultValue

	const getRetrievalRangeValue = (rangeId: string, defaultValue = 0) =>
		promptRanges?.ranges.find((range) => range.id === rangeId)?.value ||
		defaultValue

	const nodeConfigurations: Record<string, object> = {
		parser: {
			node_type: "PARSER",
			parser_id: node.data.nodeData.parser || "comma_parser",
		},
		prompt: {
			node_type: "PROMPT",
			prompt: node.data.nodeData.prompt || "prompt",
			temperature: getPromptRangeValue("temperature", 0),
			top_p: getPromptRangeValue("top_p", 0),
			frequency_penalty: getPromptRangeValue("frequency_penalty", 0),
			presence_penalty: getPromptRangeValue("presence_penalty", 0),
			max_tokens: getPromptRangeValue("max_tokens", 2048),
			llm_model: promptRanges?.llm || "gpt-4o-mini-2024-07-18",
		},
		variables: {
			node_type: "START",
			output_format: node.data.nodeData.inputVariable || "TEXT",
		},
		preview: {
			node_type: "END",
		},
		retrieval: {
			node_type: "RETRIEVAL",
			top_k: getRetrievalRangeValue("top_k"),
			similarity_score: getRetrievalRangeValue("similarity_score"),
			variable_to_retrieve:
				node.data.nodeData.variable_to_retrieve || null,
			query: node.data.nodeData.query || "prompt",
		},
		predefinedInput: {
			node_type: "PREDEFINED_INPUT",
		},
		transform: {
			node_type: "TOOL",
			tool_id: "string_replace",
			tool_input: {
				text: node.data.nodeData.query,
			},
		},
		default: {
			node_type: "TOOL",
			tool_id: "search_jurisprudence",
			tool_input: {
				query: node.data.nodeData.query,
			},
		},
	}

	const nodeConfig =
		nodeConfigurations[node.type] || nodeConfigurations.default

	return {
		...commonProperties,
		...nodeConfig,
	}
}

export function sanitizeNode(nodes: GraphNode[]): Node[] {
	const getCommonProperties = (node: GraphNode) => ({
		id: node.id,
		position: { x: node.pos_x, y: node.pos_y },
		size: { width: node.width, height: node.height },
		data: { graphId: node.graph },
	})

	const nodeConfigurations: Record<
		string,
		(node: GraphNode) => Partial<Node>
	> = {
		PARSER: (node) => ({
			type: "parser",
			data: {
				...getCommonProperties(node).data,
				nodeData: {
					parser: node.parser_id,
					name: node.name,
				},
			},
		}),
		PROMPT: (node) => ({
			type: "prompt",
			data: {
				...getCommonProperties(node).data,
				nodeData: {
					prompt: node.prompt,
					name: node.name,
				},
			},
		}),
		RETRIEVAL: (node) => ({
			type: "retrieval",
			data: {
				...getCommonProperties(node).data,
				nodeData: {
					name: node.name,
					query: node.query,
					variable_to_retrieve: node.variable_to_retrieve,
				},
			},
		}),
		START: (node) => ({
			type: "variables",
			data: {
				...getCommonProperties(node).data,
				nodeData: { name: node.name },
			},
		}),
		END: (node) => ({
			type: "preview",
			data: {
				...getCommonProperties(node).data,
				nodeData: { name: node.name },
			},
		}),
		PREDEFINED_INPUT: (node) => ({
			type: "predefinedInput",
			data: {
				...getCommonProperties(node).data,
				nodeData: { name: node.name },
			},
		}),

		default: (node) => ({
			type:
				node.tool_id === "string_replace"
					? "transform"
					: "jurisprudence",
			data: {
				...getCommonProperties(node).data,
				nodeData: {
					name: node.name,
					query:
						node.tool_id === "string_replace"
							? node.tool_input.text
							: node.tool_input.query,
				},
			},
		}),
	}

	return nodes.map((node) => {
		const config =
			nodeConfigurations[node.node_type] || nodeConfigurations.default
		const sanitizedNode = {
			...getCommonProperties(node),
			...config(node),
		}

		if (!sanitizedNode.type) {
			throw new Error(`Node type is missing for node with ID: ${node.id}`)
		}

		return sanitizedNode as Node
	})
}

export function formatVariableName(name: string, addBrackets: boolean) {
	const trimmed = name.trim()
	return addBrackets && trimmed
		? `{${trimmed}}`
		: trimmed.replace(/^{|}$/g, "")
}

export const typesTranslate = {
	variables: "Variáveis",
	prompt: "Prompt",
	jurisprudence: "Jurisprudência",
	parser: "Parser",
	preview: "Preview",
	retrieval: "Retrieval",
	input: "Input",
	transform: "Transform",
}

export const nodesDefaultSizes = {
	prompt: { width: 400, height: 145 },
	variables: { width: 200, height: 100 },
	parser: { width: 200, height: 100 },
	preview: { width: 640, height: 640 },
	retrieval: { width: 400, height: 400 },
	input: { width: 400, height: 300 },
	transform: { width: 400, height: 145 },
}
