import { useState, useEffect } from "react"
import Center from "components/center"
import Flex from "components/flex"
import CircleIcon1 from "icons/CircleIcon1"
import CircleIcon2 from "icons/CircleIcon2"
import SparkleIcon from "icons/SparkleIcon"
import TagShortcut from "../subcomponents/TagShortcut"
import { Tag } from ".."
import Select from "../subcomponents/Select"
import { useMutation, useQuery } from "@tanstack/react-query"
import useFlowBuilder from "hooks/useFlowBuilder"
import useLegalDocumentV2 from "hooks/useLegalDocumentV2"
import { useNavigate } from "react-router-dom"
import { TbBriefcase, TbPrison, TbUsers } from "react-icons/tb"
import { otherCourts, TJs, TRTs, TRFs } from "pages/GraphPage/constants"
import { hideSidebarAtom } from "components/sidebar/atoms"
import { useAtom } from "jotai"
import Loading from "components/loading"

const tags: Tag[] = [
	{
		icon: <TbUsers color="#9195FF" size="20px" />,
		text: "Petição Inicial",
	},
	{
		icon: <TbBriefcase color="#D58C8C" size="20px" />,
		text: "Reclamação Trabalhista",
	},
	{
		icon: <TbBriefcase color="#D58C8C" size="20px" />,
		text: "Contestação",
	},
	{
		icon: <TbPrison color="#E4AB55" size="20px" />,
		text: "Agravo de Instrumento (cível)",
	},
	{
		icon: <TbUsers color="#9195FF" size="20px" />,
		text: "Apelação",
	},
	{
		icon: <TbUsers color="#9195FF" size="20px" />,
		text: "Embargos de Declaração",
	},
	{
		icon: <TbUsers color="#9195FF" size="20px" />,
		text: "Contrarrazões à Apelação",
	},
	{
		icon: <TbUsers color="#9195FF" size="20px" />,
		text: "Alegações finais",
	},
	{
		icon: <TbUsers color="#9195FF" size="20px" />,
		text: "Embargos de Terceiro",
	},
]

const allCourts = [
	...Object.values(otherCourts),
	...Object.values(TJs),
	...Object.values(TRTs),
	...Object.values(TRFs),
]

const DissolveText = ({ text }: { text: string }) => {
	const [currentText, setCurrentText] = useState(text)
	const [prevText, setPrevText] = useState<string | null>(null)
	const [animating, setAnimating] = useState(false)
	const animationDuration = 800

	useEffect(() => {
		if (text !== currentText) {
			setPrevText(currentText)
			setCurrentText(text)
			setAnimating(true)
			const timeoutId = setTimeout(() => {
				setPrevText(null)
				setAnimating(false)
			}, animationDuration)
			return () => clearTimeout(timeoutId)
		}
	}, [text, currentText])

	return (
		<>
			{prevText && (
				<span
					key={prevText}
					className="absolute top-0 left-0 right-0 animate-fadeOut"
				>
					{prevText}
				</span>
			)}
			<span
				key={currentText}
				className={animating ? "animate-fadeIn" : ""}
			>
				{currentText}
			</span>
		</>
	)
}

const CreateWithAiStep = () => {
	const [showMoreTags, setShowMoreTags] = useState(false)
	const [currentIndex, setCurrentIndex] = useState(0)
	const { listValidFlows } = useFlowBuilder()
	const { createLegalDocument } = useLegalDocumentV2()
	const navigate = useNavigate()
	const [, setHideSidebar] = useAtom(hideSidebarAtom)
	const { data: flows } = useQuery(["listValidFlows"], listValidFlows)
	const placeholders = [
		"Petição Inicial",
		"Reclamação Trabalhista",
		"Contestação",
		"Agravo de Instrumento",
	]

	const createLegalDocumentMutation = useMutation({
		mutationFn: createLegalDocument,
		onSuccess: (data) => {
			navigate(`/document/${data.id}`)
		},
	})

	function onSelectFlow(docFlowId: string) {
		createLegalDocumentMutation.mutate({
			flow: docFlowId,
			courts: allCourts,
			writing_tone: "objetiva",
			optional_attachments: [],
		})
	}

	const groupedData = flows?.reduce<Record<string, typeof flows>>(
		(acc, curr) => {
			const area = curr.area_of_law

			if (!acc[area]) {
				acc[area] = []
			}

			acc[area].push(curr)

			return acc
		},
		{},
	)

	useEffect(() => {
		setHideSidebar(false)
	}, [])

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentIndex(
				(prevIndex) => (prevIndex + 1) % placeholders.length,
			)
		}, 2000)
		return () => clearInterval(intervalId)
	}, [placeholders.length])

	const tagsToRender = tags.filter((tag) => {
		if (!flows) return
		const curentFlow = flows.find((flow) => flow.name === tag.text)
		if (!curentFlow) return
		return {
			...tag,
		}
	})

	if (!flows) return <Loading />

	return (
		<Center className="w-full h-[calc(100vh-24px)] !font-Red-Hat-Display flex-col bg-[#FCFCFC] ">
			<Flex className="gap-4 relative">
				<SparkleIcon />
				<p className="text-h2 text-[#3D3D3D] font-semibold">
					Crie sua peça com IA
				</p>
				<div className="absolute top-[-80px] left-[-80px]">
					<CircleIcon1 />
				</div>
				<div className="absolute top-[-80px] right-[-90px]">
					<CircleIcon2 />
				</div>
			</Flex>

			<div className="my-8 relative">
				<Select
					onSelect={(e) => {
						if (e) {
							onSelectFlow(e)
						}
					}}
					options={groupedData}
					placeholder={
						<DissolveText text={placeholders[currentIndex]} />
					}
				/>
				<Flex className="flex-wrap gap-2 justify-center w-[600px] z-10 absolute top-[80px] left-1/2 transform -translate-x-1/2">
					{tagsToRender.map((item, index) => {
						if (!showMoreTags && index > 2) return null
						const currentFlow = flows.find(
							(flow) => flow.name === item.text,
						)
						if (!currentFlow) return null

						return (
							<div
								onClick={() => onSelectFlow(currentFlow.id)}
								key={item.text}
							>
								<TagShortcut
									icon={item.icon}
									text={item.text}
								/>
							</div>
						)
					})}
					{!showMoreTags && tagsToRender.length > 3 && (
						<div onClick={() => setShowMoreTags(true)}>
							<TagShortcut text="Mais" />
						</div>
					)}
				</Flex>
			</div>
		</Center>
	)
}

export default CreateWithAiStep
