import { atom } from "jotai"
import { defaultPromptRanges } from "../constants"
import { NodeTypes, Variables } from "../types"
import { GraphExecutionAtom } from "types/agentBuilder"
import { initialGraphExecution } from "../hooks/useNodeExecutionStatus"

interface Ranges {
	title: string
	max: number
	value: number
	id: string
}

export const typeAtom = atom<NodeTypes | null>(null)
export const openPromptConfigAtom = atom<string | null>("")
export const openRetrievalConfigAtom = atom<string | null>("")
export const retrievalRangesAtom = atom<
	{
		nodeId: string
		retrievalRanges: Ranges[]
	}[]
>([])
export const promptRangesAtom = atom<
	{
		nodeId: string
		promptRanges: Ranges[]
		llm: string
	}[]
>(defaultPromptRanges)

export const variablesAtom = atom<Variables[]>([])

export const courtAtom = atom<string[]>([
	"STF",
	"CNJ",
	"STJ",
	"TJAC",
	"TJAL",
	"TJAP",
	"TJAM",
	"TJBA",
	"TJCE",
	"TJDFT",
	"TJES",
	"TJGO",
	"TJMA",
	"TJMT",
	"TJMS",
	"TJMG",
	"TJPA",
	"TJPB",
	"TJPR",
	"TJPE",
	"TJPI",
	"TJRJ",
	"TJRN",
	"TJRS",
	"TJRO",
	"TJRR",
	"TJSC",
	"TJSE",
	"TJSP",
	"TJTO",
	"TRT1",
	"TRT2",
	"TRT3",
	"TRT4",
	"TRT5",
	"TRT6",
	"TRT7",
	"TRT8",
	"TRT9",
	"TRT10",
	"TRT11",
	"TRT12",
	"TRT13",
	"TRT14",
	"TRT15",
	"TRT16",
	"TRT17",
	"TRT18",
	"TRT19",
	"TRF1",
	"TRF2",
	"TRF3",
	"TRF4",
	"TRF5",
	"TRF6",
])

export const inputVariablesAtom = atom<Variables[]>([
	{
		name: "{detalhes_do_caso}",
		type: "TEXT",
		value: "",
	},
	{
		name: "{upload_opcional}",
		type: "FILES",
		value: [],
	},
	{
		name: "{tom_escrita}",
		type: "TEXT",
		value: "",
	},
	{
		name: "{tribunais}",
		type: "COURTS",
		value: "",
	},
	{
		name: "{main_upload}",
		type: "FILES",
		value: [],
	},
])

export const graphExecutionAtom = atom<GraphExecutionAtom>(
	initialGraphExecution,
)
