import { useReactFlow } from "@xyflow/react"
import { useState } from "react"
import ClickAwayListener from "react-click-away-listener"
import { TbCopy, TbPencil, TbTrash } from "react-icons/tb"
import { sanitizeNode, serializeNode } from "../utils"
import { Node, NodeData } from "./Flow"
import { useParams } from "react-router-dom"
import useAgentBuilder from "hooks/useAgentBuilder"
import { useMutation } from "@tanstack/react-query"
import { defaultPromptRanges } from "../constants"
import { promptRangesAtom } from "../atoms"
import { useAtom } from "jotai"

export default function ActionNodeHandler({
	children,
	id,
	onRename,
	isError,
}: {
	children: React.ReactNode
	id: string
	onRename: () => void
	isError?: boolean
}) {
	const [focused, setFocused] = useState(false)
	const { setNodes, getNode, deleteElements } = useReactFlow()
	const { graphId = "" } = useParams()
	const [, setPromptRanges] = useAtom(promptRangesAtom)

	const { addNode } = useAgentBuilder()

	const addNodeMutation = useMutation({
		mutationFn: addNode,
	})

	async function onDuplicate() {
		const currentNode = getNode(id) as Node

		if (currentNode) {
			const newNode: Node = {
				id: `${id}-copy`,
				data: {
					graphId: graphId,
					nodeData: {
						name: `${
							(currentNode?.data?.nodeData as NodeData)?.name ||
							""
						} - copia`,
					},
				},
				position: {
					x: currentNode.position?.x + 100 || 0,
					y: currentNode.position?.y + 100 || 0,
				},
				type: currentNode.type || "",
				size: currentNode.size,
			}

			const serializedNode = serializeNode(newNode, graphId)
			const data = await addNodeMutation.mutateAsync(serializedNode)

			setPromptRanges((prev) => [
				...prev,
				{
					nodeId: data.id,
					promptRanges: defaultPromptRanges[0].promptRanges,
					llm: "gpt-4o-mini-2024-07-18",
				},
			])

			const sanitizedNode = sanitizeNode([data])
			setNodes((prev) => [...prev, sanitizedNode[0]])
		}
	}

	function onDelete() {
		deleteElements({
			nodes: [{ id }],
		})
	}

	return (
		<ClickAwayListener onClickAway={() => setFocused(false)}>
			<div
				className={`relative  h-full w-full ${
					isError &&
					"outline outline-2 outline-offset-2 rounded-2xl outline-[#FF6363]"
				} ${
					focused
						? "outline outline-2 outline-offset-2 outline-[#3083FF] rounded-2xl"
						: ""
				}`}
				onClick={() => setFocused(true)}
				onBlur={() => setFocused(false)}
			>
				{children}
				{focused && (
					<div className="absolute z-50 w-[165px] rounded-lg bg-white top-0 right-[-173px] shadow-3 cursor-pointer">
						<div
							className="p-2 flex items-center gap-2 text-[#6D6D6D]  hover:bg-[#F5F5F5]"
							onClick={onRename}
						>
							<TbPencil size="20px" />
							<p className="text-small-1 font-semibold">
								Renomear
							</p>
						</div>

						<div
							className="p-2 flex items-center gap-2 text-[#6D6D6D] hover:bg-[#F5F5F5]"
							onClick={onDuplicate}
						>
							<TbCopy size="20px" />
							<p className="text-small-1 font-semibold">
								Duplicar
							</p>
						</div>

						<div
							className="p-2 flex items-center gap-2 text-[#FF6363] hover:bg-[#F5F5F5]"
							onClick={onDelete}
						>
							<TbTrash size="20px" />
							<p className="text-small-1 font-semibold">
								Excluir
							</p>
						</div>
					</div>
				)}
			</div>
		</ClickAwayListener>
	)
}
