import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"
import InputField from "components/input-field"
import Modal from "components/modal"
import Select from "components/select"
import PromptIcon from "icons/PromptIcon"

import {
	addonsIcons,
	areas,
	translatedAreaIcons,
} from "pages/FlowBuilderPage/constants"

import { useState } from "react"
import { Flow, PublishedGraphs } from "types/flowBuilder"

const initialForm = {
	name: "",
	area_of_law: "",
	attachment_title: "",
	text_placeholder: "",
	graph: "",
	active: true,
}

export default function AddFlowModal({
	open,
	setOpen,
	onAddFlow,
	graphs = [],
	flowToEdit,
	resetFlowToEdit,
	onEditFlow,
}: {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	onAddFlow: (flow: Partial<Flow>) => void
	onEditFlow: (flow: Partial<Flow>) => void
	graphs: PublishedGraphs[] | undefined
	flowToEdit: Flow | null
	resetFlowToEdit: () => void
}) {
	const [form, setForm] = useState(flowToEdit || initialForm)
	const selectedAgent = graphs.find((agent) => agent.id === form.graph)
	const selectedArea = areas.find((area) => area.value === form.area_of_law)
	const areasOptions = areas.map((area) => ({
		...area,

		startIcon:
			translatedAreaIcons[area.label as keyof typeof translatedAreaIcons],
	}))

	const agentsOptions = graphs.map((graph) => ({
		value: graph.id,
		label: graph.name,
		startIcon: <PromptIcon size="100%" />,
		// endIcon: (
		// 	<Tag
		// 		background="bg-[#DBEDDB]"
		// 		textColor="text-[#006600]"
		// 		small
		// 		text="Civil"
		// 		className="px-3 !py-0"
		// 	/>
		// ),
	}))

	function isValidIconKey(key: string): key is keyof typeof addonsIcons {
		return key in addonsIcons
	}

	return (
		<Modal
			openModal={open}
			setOpenModal={() => {
				setOpen(false)
				setForm(initialForm)
				resetFlowToEdit()
			}}
			title={flowToEdit ? "Editar Flow" : "Criar novo flow"}
			width="w-[550px] p-8"
		>
			<div className="mt-6">
				<div className="flex w-full gap-4 items-center justify-between">
					<div className="w-full max-w-[270px]">
						<p className="text-brand-gray-3 mb-1">Nome</p>
						<InputField
							placeholder="Réplica Trabalhista"
							className="w-full"
							name="name"
							value={form.name}
							onChange={(e) =>
								setForm({ ...form, name: e.target.value })
							}
						/>
					</div>
					<div>
						<p className="text-brand-gray-3 mb-1">
							Área do direito
						</p>

						<Select
							options={areasOptions}
							onSelect={(e) => {
								const selectedArea = areas.find(
									(area) => area.label === e,
								)
								setForm({
									...form,
									area_of_law: selectedArea?.value || "",
								})
							}}
							whitoutCreate
							placeholder="Selecionar"
							placeholderWithIcon
							clearable
							currentValue={selectedArea?.label}
						/>
					</div>
				</div>

				<div className="mt-4">
					<div className="flex items-center justify-between">
						<p className="text-brand-gray-3 mb-1">Agente</p>

						<div className="flex items-center gap-4">
							{selectedAgent?.addons.map((addon) => {
								if (isValidIconKey(addon)) {
									return (
										<span key={addon}>
											{addonsIcons[addon]}
										</span>
									)
								}
							})}
						</div>
					</div>

					<Select
						options={agentsOptions}
						onSelect={(e) => {
							if (!e) return
							const selectedAgent = graphs.find(
								(agent) => agent.name === e,
							)

							setForm({
								...form,
								graph: selectedAgent?.id || "",
							})
						}}
						whitoutCreate
						placeholder="Selecionar"
						placeholderWithIcon
						clearable
						currentValue={selectedAgent?.name}
					/>
				</div>

				{selectedAgent?.addons.find(
					(addon) => addon === "main_upload",
				) && (
					<div className="w-full mt-4">
						<p className="text-brand-gray-3 mb-1">
							Título do anexo
						</p>
						<InputField
							placeholder="Anexe sua inicial"
							className="w-full"
							name="attachment_title"
							onChange={(e) =>
								setForm({
									...form,
									attachment_title: e.target.value,
								})
							}
							value={form.attachment_title}
						/>
					</div>
				)}

				<div className="mt-4">
					<p className="text-brand-gray-3 mb-1">Texto placeholder </p>
					<textarea
						placeholder="João Silva, demitido sem justa causa após 5 anos na empresa ABC Ltda., não recebeu verbas rescisórias. Réu alega falta grave, mas provas demonstram excelente desempenho do autor e ausência de advertências."
						className=" placeholder:text-[#ACACAC] w-full h-[120px] resize-none rounded border-[#DCDCDC]"
						name="text_placeholder"
						onChange={(e) =>
							setForm({
								...form,
								text_placeholder: e.target.value,
							})
						}
						value={form.text_placeholder}
					/>
				</div>

				<div className="w-full flex items-center justify-between gap-4 mt-8">
					<Button
						variant={ButtonVariant.Outlined}
						onClick={() => {
							setOpen(false)
							setForm(initialForm)
							resetFlowToEdit()
						}}
						className="w-full  justify-center"
					>
						Cancelar
					</Button>

					<Button
						variant={ButtonVariant.Contained}
						className="w-full  justify-center"
						disabled={
							!form.name ||
							!form.area_of_law ||
							!form.text_placeholder ||
							!form.graph ||
							!!(
								selectedAgent?.addons.find(
									(addon) => addon === "main_upload",
								) && !form.attachment_title
							)
						}
						onClick={() => {
							const filteredForm = Object.fromEntries(
								Object.entries(form).filter(
									([, value]) => value !== "",
								),
							)
							if (flowToEdit) {
								onEditFlow(filteredForm)
							} else {
								onAddFlow(filteredForm)
							}
						}}
					>
						{flowToEdit ? "Salvar" : "Criar"}
					</Button>
				</div>
			</div>
		</Modal>
	)
}
