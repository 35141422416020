interface Props extends React.HTMLAttributes<HTMLDivElement> {
	children?: React.ReactNode
	className?: string
}

export default function Flex({ children, className, ...props }: Props) {
	return (
		<div className={`flex items-center ${className}`} {...props}>
			{children}
		</div>
	)
}
