import { useAtom } from "jotai"
import { plansModalAtom } from "pages/BasePage/atoms"
import { TbStarFilled } from "react-icons/tb"
interface UpgradeButtonProps {
	isBig?: boolean
	className?: string
	onClick?: () => void
	text?: string
}

const UpgradeButton = ({
	isBig,
	className,
	onClick,
	text = "Assinar agora",
}: UpgradeButtonProps) => {
	const [, setPlansModalOpen] = useAtom(plansModalAtom)

	return (
		<button
			className={` ${
				isBig ? "gap-2 w-[156px] h-[48px]" : "h-[24px] w-[64px] gap-1"
			} text-base px-2 py-1 rounded-[8px] flex items-center  cursor-pointer text-white  !outline-none bg-[#F7A428] hover:bg-[#F1810F] ${className}`}
			onClick={() => {
				if (onClick) {
					onClick()
				} else {
					setPlansModalOpen(true)
				}
			}}
		>
			<div>
				<TbStarFilled size="16px" />
			</div>
			{isBig ? (
				<span className="font-bold whitespace-nowrap">{text}</span>
			) : (
				<span className="text-[14px] font-bold">PRO</span>
			)}
		</button>
	)
}

export default UpgradeButton
