import { AxiosResponse } from "axios"
import axiosInstance from "axiosInstance"
import { Flow, FlowResponse, PublishedGraphsResponse } from "types/flowBuilder"
const useFlowBuilder = () => {
  const listFlows = async () => {
    const response: AxiosResponse<FlowResponse> =
      await axiosInstance.get("/flow-builder/flows/")

    return response.data
  }

  const listValidFlows = async () => {
    const response: AxiosResponse<Flow[]> =
      await axiosInstance.get("/flow-builder/flows/valid/")

    return response.data
  }

  const listPublishedGraphs = async () => {
    const response: AxiosResponse<PublishedGraphsResponse> =
      await axiosInstance.get("/flow-builder/published-graphs/")

    return response.data

  }

  const getFlow = async (id: number) => {
    const response: AxiosResponse<Flow> =
      await axiosInstance.get(`/flow-builder/flows/${id}/`)

    return response.data
  }

  const addFlow = async (data: Partial<Flow>) => {
    const response =
      await axiosInstance.post("/flow-builder/flows/", data)
    return response.data
  }

  const editFlow = async ({ id, data }: {
    id: string
    data: Partial<Flow>
  }) => {
    const response =
      await axiosInstance.put(`/flow-builder/flows/${id}/`, data)
    return response.data
  }

  const deleteFlow = async (id: string) => {
    const response =
      await axiosInstance.delete(`/flow-builder/flows/${id}/`)
    return response.data
  }

  return {
    listFlows,
    addFlow,
    getFlow,
    editFlow,
    deleteFlow,
    listPublishedGraphs,
    listValidFlows
  }
}
export default useFlowBuilder
