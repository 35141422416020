export default function NewCreditIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<circle cx="10" cy="10" r="10" fill="#A734FC" />
			<g filter="url(#filter0_i_826_3120)">
				<circle
					cx="10"
					cy="10"
					r="8.42604"
					stroke="url(#paint0_linear_826_3120)"
					strokeWidth="1.14793"
				/>
			</g>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.29999 13.7036C7.20244 14.5016 8.39758 14.9875 9.7084 14.9875C9.98041 14.9875 10.2474 14.9666 10.5079 14.9263V13.3034C10.2511 13.3619 9.98349 13.3929 9.7084 13.3929C8.97422 13.3929 8.29295 13.1724 7.73068 12.7958V5.39071C7.20238 5.60933 6.71955 5.91287 6.29999 6.28393V13.7036ZM10.5079 12.316V6.68411C12.0515 7.03595 13.201 8.3842 13.201 9.99375C13.201 11.4222 12.2956 12.6449 11.0129 13.1478V14.8221C13.1929 14.2571 14.8 12.3095 14.8 9.99375C14.8 7.23577 12.5204 5 9.7084 5C9.49467 5 9.28403 5.01292 9.07722 5.038V12.3751C9.27915 12.4368 9.49061 12.4696 9.7084 12.4696C9.98756 12.4696 10.2563 12.4157 10.5079 12.316Z"
				fill="white"
			/>
			<defs>
				<filter
					id="filter0_i_826_3120"
					x="1"
					y="1"
					width="18"
					height="18"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feColorMatrix
						in="SourceAlpha"
						type="matrix"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						result="hardAlpha"
					/>
					<feMorphology
						radius="0.382642"
						operator="dilate"
						in="SourceAlpha"
						result="effect1_innerShadow_826_3120"
					/>
					<feOffset dx="0.382642" dy="0.765285" />
					<feComposite
						in2="hardAlpha"
						operator="arithmetic"
						k2="-1"
						k3="1"
					/>
					<feColorMatrix
						type="matrix"
						values="0 0 0 0 0.537255 0 0 0 0 0.203922 0 0 0 0 0.815686 0 0 0 0.15 0"
					/>
					<feBlend
						mode="normal"
						in2="shape"
						result="effect1_innerShadow_826_3120"
					/>
				</filter>
				<linearGradient
					id="paint0_linear_826_3120"
					x1="12.4821"
					y1="19.9698"
					x2="4.90922"
					y2="-5.13737"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#45126A" stopOpacity="0.3" />
					<stop offset="1" stopColor="#283394" stopOpacity="0" />
					<stop offset="1" stopColor="#B174FF" stopOpacity="0.2" />
				</linearGradient>
			</defs>
		</svg>
	)
}
