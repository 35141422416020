import Center from "components/center"
import Flex from "components/flex"
import { TbGavel, TbPaperclip, TbPencil, TbSparkles } from "react-icons/tb"
import ReactTextareaAutosize from "react-textarea-autosize"
import TagShortcut from "../subcomponents/TagShortcut"
import UploadCard from "../subcomponents/UploadCard"
import { OptionalUpload } from ".."
import Button from "components/button-v2"
import { useState } from "react"
import ClickAwayListener from "react-click-away-listener"
import { Flow } from "types/flowBuilder"
import { areaIcons } from "pages/FlowBuilderPage/constants"
import { RetrieveLegalDocumentResponse } from "types/legalDocumentV2"

const writeTranslate: Record<string, string> = {
	detalhada: "Detalhada",
	objetiva: "Objetiva",
}

interface StepThreeProps {
	focusTextarea: boolean
	setFocusTextarea: (value: boolean) => void
	selectedCourts: string[]
	setOpenCourtModal: (value: boolean) => void
	handleFileSelect: (e: React.ChangeEvent<HTMLInputElement>) => void
	optionalUploads: OptionalUpload[]
	selectedWriting: "objetiva" | "detalhada"
	setSelectedWriting: React.Dispatch<
		React.SetStateAction<"objetiva" | "detalhada">
	>
	selectedFlow: Flow | null
	handleEditLegalDocument: (
		data: Partial<RetrieveLegalDocumentResponse>,
	) => void
	userInput: string
	setUserInput: React.Dispatch<React.SetStateAction<string>>
	handleGenerateContent: () => void
	handleRemoveOptionalUpload: (id: string) => void
}

const AddDetailsStep = ({
	focusTextarea,
	setFocusTextarea,
	selectedCourts,
	setOpenCourtModal,
	handleFileSelect,
	optionalUploads,
	selectedWriting,
	setSelectedWriting,
	selectedFlow,
	handleEditLegalDocument,
	userInput,
	setUserInput,
	handleGenerateContent,
	handleRemoveOptionalUpload,
}: StepThreeProps) => {
	const [openWritingPoppover, setOpenWritingPoppover] = useState(false)

	function handleSelectWriting(writing: "objetiva" | "detalhada") {
		setSelectedWriting(writing)
		handleEditLegalDocument({ writing_tone: writing })
	}
	const isErrorInput = !focusTextarea ? userInput.length > 10000 : false

	return (
		<Center className="w-full h-[calc(100vh-24px)] !font-Red-Hat-Display flex-col bg-[#FCFCFC] relative">
			<p className="text-h2 text-[#3D3D3D] font-semibold">
				Adicione detalhes do caso
			</p>
			<div
				className={`mt-6 p-4 w-[800px] rounded-[16px] overflow-visible border border-[#F0F0F0] relative bg-white ${
					focusTextarea ? " !border-[#3083FF]" : ``
				} ${isErrorInput ? "!border-[#FF6363]" : ""}`}
			>
				<ReactTextareaAutosize
					className="resize-none p-0 pr-4 rounded border-none placeholder:text-[#B0B0B0] min-h-[100px] 0xl:min-h-[200px] max-h-[140px] 0xl:max-h-[355px] h-full w-full textarea-custom-scrollbar "
					placeholder={selectedFlow?.text_placeholder}
					onFocus={() => setFocusTextarea(true)}
					onBlur={() => {
						setFocusTextarea(false)
						handleEditLegalDocument({ user_input: userInput })
					}}
					value={userInput}
					onChange={(e) => setUserInput(e.target.value)}
				/>
				<Flex className="bg-white w-full max-w-[754px] gap-2 mt-4">
					{selectedFlow?.addons.includes("tribunais") && (
						<TagShortcut
							text={`Tribunais ${
								selectedCourts.length > 0
									? `(${selectedCourts.length})`
									: ""
							}`}
							className="rounded-[8px] h-[36px]"
							textClassName="leading-[20px]"
							icon={<TbGavel size="20px" />}
							onClick={() => setOpenCourtModal(true)}
						/>
					)}
					<input
						type="file"
						accept="application/pdf, text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
						multiple
						onChange={handleFileSelect}
						style={{ display: "none" }}
						id="attachments-input"
					/>
					<label htmlFor="attachments-input">
						<TagShortcut
							text={`Anexos ${
								optionalUploads.length > 0
									? `(${optionalUploads.length})`
									: ""
							}`}
							className="rounded-[8px] h-[36px]"
							textClassName="leading-[20px]"
							icon={<TbPaperclip size="20px" />}
						/>
					</label>
					<div className="relative">
						{openWritingPoppover && (
							<ClickAwayListener
								onClickAway={() =>
									setOpenWritingPoppover(false)
								}
							>
								<div className="absolute top-[-162px] left-0 bg-white w-[320px] rounded-[8px] text-cta-2 shadow-3 z-10">
									<div className="p-2">
										<p className="text-[#B0B0B0] font-semibold">
											Tipo de escrita
										</p>
									</div>
									<fieldset className="leading-3">
										<Flex
											className="gap-2  p-2 h-[56px] cursor-pointer hover:bg-[#F5F5F5]"
											onClick={() => {
												handleSelectWriting("objetiva")
												setOpenWritingPoppover(false)
											}}
										>
											<input
												type="radio"
												className="w-[20px] h-[20px] text-[#3083FF]"
												value="objetiva"
												checked={
													selectedWriting ===
													"objetiva"
												}
												onChange={() => {}}
											/>
											<Flex className="text-[#6D6D6D] flex-col !items-start justify-center gap-2 ">
												<p className="font-semibold">
													Objetiva
												</p>
												<p className="text-small-1">
													Argumentação clara e direta,
													mais precisa
												</p>
											</Flex>
										</Flex>

										<Flex
											className="gap-2  p-2 h-[56px] cursor-pointer hover:bg-[#F5F5F5]"
											onClick={() => {
												handleSelectWriting("detalhada")
												setOpenWritingPoppover(false)
											}}
										>
											<input
												type="radio"
												className="w-[20px] h-[20px] text-[#3083FF]"
												value="detalhada"
												checked={
													selectedWriting ===
													"detalhada"
												}
												onChange={() => {}}
											/>
											<Flex className="text-[#6D6D6D] flex-col !items-start justify-center gap-2 ">
												<p className="font-semibold">
													Detalhada
												</p>
												<p className="text-small-1">
													Explora mais argumentos,
													mais repetitiva
												</p>
											</Flex>
										</Flex>
									</fieldset>
								</div>
							</ClickAwayListener>
						)}
						{selectedFlow?.addons.includes("tom_escrita") && (
							<TagShortcut
								text={`Escrita: ${writeTranslate[selectedWriting]}`}
								className="rounded-[8px] h-[36px]"
								textClassName="leading-[20px]"
								icon={<TbPencil size="20px" />}
								onClick={() =>
									setOpenWritingPoppover(!openWritingPoppover)
								}
							/>
						)}
					</div>
				</Flex>
				{optionalUploads.length > 0 && (
					<Flex className="mt-4 flex-wrap gap-2 max-h-[140px] overflow-y-auto textarea-custom-scrollbar 0xl:max-h-[200px]">
						{optionalUploads.map((upload, index) => (
							<UploadCard
								key={index}
								file={upload}
								handleRemoveOptionalUpload={
									handleRemoveOptionalUpload
								}
							/>
						))}
					</Flex>
				)}

				{isErrorInput && userInput.length > 10000 && (
					<p className="text-cta-2 text-[#FF6363] absolute bottom-[-25px] right-0">
						-{userInput.length - 10000} caracteres
					</p>
				)}
			</div>

			<Center className="mt-12">
				<Button
					variant="contained"
					color="purple"
					icon={<TbSparkles size="24px" />}
					onClick={handleGenerateContent}
					disabled={userInput.length > 10000 || userInput.length < 20}
				>
					Gerar documento
				</Button>
			</Center>
			{selectedFlow && (
				<Center className="gap-2 justify-center mt-4">
					<div className="w-[20px] h-[20px]">
						{areaIcons[selectedFlow.area_of_law]}
					</div>

					<p className="text-cta-2 font-semibold text-[#6D6D6D]">
						{selectedFlow.name}
					</p>
				</Center>
			)}
		</Center>
	)
}

export default AddDetailsStep
