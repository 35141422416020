import AutoComplete from "components/auto-complete"
import { autocompleteSuggestions } from "../constants"

interface JurisprudenceAutocompleteProps {
	onSubmit: (value: string) => void
	className?: string
	value?: string
	isLoading?: boolean
	showClearIcon?: boolean
}
const JurisprudenceAutocomplete = ({
	onSubmit,
	className,
	value,
	isLoading,
	showClearIcon,
}: JurisprudenceAutocompleteProps) => {
	return (
		<AutoComplete
			optionsList={autocompleteSuggestions}
			className={className || ""}
			onSubmit={onSubmit}
			value={value}
			isLoading={isLoading}
			showClearIcon={showClearIcon}
			placeholder="Incêndio em veículo"
		/>
	)
}

export default JurisprudenceAutocomplete
