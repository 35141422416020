import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"

import NewDocumentIcon from "icons/NewDocumentIcon"
import { Button } from "components/button"
import { ROUTES } from "routes"
import SearchIcon from "icons/SearchIcon"
import { useEffect, useRef, useState } from "react"
import CloseIcon from "icons/CloseIcon"
import InputField from "components/input-field"
import { useJwt } from "hooks/useJwt"
import Footer from "components/footer"
import DeleteDocumentModal from "./subcomponents/DeleteDocumentModal"
import { useQueryString } from "./utils"
import queryString from "query-string"
import { ColumnFilter, SortingState } from "@tanstack/react-table"
import { useAtom } from "jotai"
import DashboardDocumentDisplay from "./subcomponents/DashboardDocumentDisplay"
import ExclusionButton from "components/exclusion-button"
import { scrollToTop } from "utils/scrollToTop"
import HeaderAlert from "./subcomponents/HeaderAlert"
import useCurrentUser from "hooks/useCurrentUser"
import { haveFooterAtom } from "pages/AnalizeDocumentPage/subcomponents/LexAssistant/atoms"
import { ButtonVariant } from "components/button/types"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import ImportDocumentIcon from "icons/ImportDocumentIcon"
import Tooltip from "components/tooltip"
import { TooltipPosition } from "components/tooltip/enums"
import LoadingSpinner from "./subcomponents/LoadingSpinner"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import { plansModalAtom } from "pages/BasePage/atoms"
import { LegalDocumentType } from "types/legalDocument"
import { useFeatureFlag } from "hooks/useFeatureFlag"
import FEATURE_FLAGS from "feature-flags"
import CreateWithAiStep from "pages/NewDocumentPage/steps/CreateWithAiStep"

const MyDocumentsPage = () => {
	const [filterValue, setFilterValue] = useState("")
	const [openDeleteDocumentModal, setOpenDeleteDocumentModal] =
		useState(false)
	const [focusSearchBar, setFocusSearchBar] = useState(false)
	const queryClient = useQueryClient()
	const [jwt] = useJwt()
	const [, setOpenPlansModal] = useAtom(plansModalAtom)

	const [idsToDelete, setIdsToDelete] = useState<string[]>()
	const [rowSelection, setRowSelection] = useState({})
	const [sorting, setSorting] = useState<SortingState>([
		{ desc: true, id: "_updated_at" },
	])
	const [columnFilterValue, setColumnFilterValue] = useState<ColumnFilter[]>()
	const [active, setActive] = useState(false)
	const [isSearchOpen, setIsSearchOpen] = useState(false)
	const user = useCurrentUser()
	const searchInputRef = useRef<HTMLInputElement>(null)
	const { data: newDocumentsFlow } = useFeatureFlag(
		FEATURE_FLAGS.newDocumentsFlow,
	)
	const {
		createLegalDocument,
		uploadDocument,
		listLegalDocuments,
		legalDocumentPropmts,
	} = useLegalDocumentAPI()
	const { data: listLegalDocumentsData } = useQuery(
		["listLegalDocuments", jwt],
		() => listLegalDocuments(),
	)
	const { data: optionsData } = useQuery(
		["legalDocumentPrompts"],
		legalDocumentPropmts,
		{
			refetchOnWindowFocus: false,
		},
	)

	const inputRef = useRef<HTMLInputElement>(null)

	const [, setHaveFooter] = useAtom(haveFooterAtom)

	const navigate = useNavigate()
	const query = useQueryString()

	const uploadDocumentMutation = useMutation({
		mutationFn: uploadDocument,
		onSuccess: (data) => {
			queryClient.invalidateQueries({
				queryKey: ["listLegalDocuments", jwt],
			})
			navigate(`/document/${data.id}`)
		},
		onError: () => {
			showToast(
				"Tivemos um problema ao carregar o arquivo, tente novamente",
				ToastType.Error,
			)
		},
	})
	const legalDocumentMutation = useMutation({
		mutationFn: createLegalDocument,
		onSuccess: (data) => {
			navigate(ROUTES.documentDetail({ id: data.id }), {
				state: { isOpenSelect: !listLegalDocumentsData?.length },
			})
		},
	})

	const onFileChange = async (event: any) => {
		const fileData = event.target.files[0]
		if (!fileData) return
		if (fileData && fileData.size > 5242880) {
			showToast(
				"Erro ao carregar, tente novamente com documentos em PDF, TXT, DOCX de até 5 MB",
				ToastType.Error,
			)
			return
		}
		const formData = new FormData()
		formData.append("file_path", fileData)

		await uploadDocumentMutation.mutateAsync(formData)
	}

	function handleClearSearchBar() {
		setFilterValue("")
	}

	function onCreateNewDocument() {
		const lastCreatedAtDocument = listLegalDocumentsData
			?.filter(
				(document) =>
					document.legal_document_type !== "TEMPLATE" &&
					document.type !== LegalDocumentType.Uploaded,
			)
			.sort(
				(a, b) =>
					new Date(b._created_at).getTime() -
					new Date(a._created_at).getTime(),
			)[0]

		legalDocumentMutation.mutate({
			title: "",
			legal_document_type:
				lastCreatedAtDocument?.legal_document_type || "Petição Inicial",
			type: "petition",
		})
	}

	function onImportModel() {
		if (user.total_storage_usage >= user.subscription.max_upload_size) {
			if (user.subscription.product_name === "Profissional") {
				showToast(
					"Armazenamento cheio. Remova arquivos para liberar espaço de armazenamento.",
					ToastType.Error,
				)
			} else {
				showToast(
					"Armazenamento cheio. Faça upgrade ou remova arquivos para liberar espaço de armazenamento.",
					ToastType.Error,
				)
				setOpenPlansModal(true)
			}
			return
		}

		if (inputRef.current) {
			inputRef.current.click()
		}
	}

	useEffect(() => {
		const statusFilterValue = query.get("status")?.split(",")
			? { id: "status", value: query.get("status")!.split(",") }
			: null
		const typeFilterValue = query.get("type")?.split(",")
			? { id: "type", value: query.get("type")!.split(",") }
			: null
		const columnFilters = []
		if (statusFilterValue) columnFilters.push(statusFilterValue)
		if (typeFilterValue) columnFilters.push(typeFilterValue)
		setColumnFilterValue(columnFilters)

		if (query.get("search") !== null) {
			setFilterValue(query.get("search")!)
		}
	}, [])

	useEffect(() => {
		scrollToTop()
		const interval = setInterval(() => {
			if (newDocumentsFlow) return
			queryClient.invalidateQueries({
				queryKey: ["listLegalDocuments", jwt],
			})
		}, 10000)

		return () => clearInterval(interval)
	}, [])

	useEffect(() => {
		if (user?.subscription?.status === "past_due") {
			setActive(true)
		} else {
			setActive(false)
		}
	}, [user && user?.subscription?.status === "past_due"])

	useEffect(() => {
		if (idsToDelete && idsToDelete?.length > 0) {
			setHaveFooter(true)
		} else {
			setHaveFooter(false)
		}
		return () => {
			setHaveFooter(false)
		}
	}, [idsToDelete])

	useEffect(() => {
		const sortingValue = sorting[0]
			? `${sorting[0].desc ? "-" : ""}${sorting[0].id}`
			: undefined
		const filterStatusValue =
			columnFilterValue?.find((filter) => filter.id === "status") &&
			Array.isArray(
				columnFilterValue?.find((filter) => filter.id === "status")
					?.value,
			)
				? (columnFilterValue?.find((filter) => filter.id === "status")
						?.value as string[])
				: []
		const filterTypeValue =
			columnFilterValue?.find((filter) => filter.id === "type") &&
			Array.isArray(
				columnFilterValue?.find((filter) => filter.id === "type")
					?.value,
			)
				? (columnFilterValue?.find((filter) => filter.id === "type")
						?.value as string[])
				: []
		const params = {
			status:
				filterStatusValue.length > 0
					? filterStatusValue.toString()
					: undefined,
			type:
				filterTypeValue.length > 0
					? filterTypeValue.toString()
					: undefined,
			search: filterValue !== "" ? filterValue : undefined,
			order:
				sorting[0].id === "_updated_at" && sorting[0].desc
					? undefined
					: sortingValue,
		}
		const queryStringValue = queryString.stringify(params)
		if (queryStringValue) {
			navigate(`?${queryStringValue}`)
		}
	}, [columnFilterValue, filterValue, sorting])

	useEffect(() => {
		if (isSearchOpen) {
			searchInputRef.current?.focus()
		}
	}, [isSearchOpen])

	if (newDocumentsFlow) {
		return <CreateWithAiStep />
	}

	if (optionsData?.length === 0) return null

	return (
		<div className="h-full" id="document">
			<HeaderAlert active={active} />
			<div className="flex items-center justify-between ">
				<h1 className="font-semibold">Meus Documentos</h1>

				<div className="flex-end flex items-center gap-6">
					<div onBlur={() => setFocusSearchBar(false)}>
						{isSearchOpen ? (
							<InputField
								className="w-[300px] h-[48px]"
								type="text"
								name="searchBar"
								placeholder="Buscar"
								value={filterValue}
								ref={searchInputRef}
								onChange={(e) => setFilterValue(e.target.value)}
								onFocus={() => {
									setFocusSearchBar(true)
								}}
								onBlur={(e) => {
									setFocusSearchBar(false)
									if (!e.target.value) {
										setIsSearchOpen(false)
									}
								}}
								leftIcon={
									<SearchIcon
										className={
											focusSearchBar || filterValue
												? "fill-current"
												: "fill-[#ACACAC]"
										}
									/>
								}
								rightIcon={
									(focusSearchBar || !!filterValue) && (
										<div className="pt-1">
											{" "}
											<div
												className="cursor-pointer"
												onFocus={() => {
													setFocusSearchBar(true)
												}}
												onClick={() => {
													handleClearSearchBar()
													searchInputRef.current?.focus()
												}}
											>
												<CloseIcon />
											</div>
										</div>
									)
								}
							/>
						) : (
							<div
								className="cursor-pointer"
								onClick={() => {
									setIsSearchOpen(true)
								}}
							>
								<SearchIcon className="fill-dark-blue-1 hover:fill-dark-blue-2" />
							</div>
						)}
					</div>

					{uploadDocumentMutation.isLoading ? (
						<div>
							<LoadingSpinner />
						</div>
					) : (
						<Tooltip
							position={TooltipPosition.Below}
							text="Importar modelo"
						>
							<div
								className="text-dark-blue-1 hover:text-dark-blue-2 cursor-pointer"
								onClick={() => {
									onImportModel()
								}}
							>
								<ImportDocumentIcon />
							</div>
						</Tooltip>
					)}

					<input
						type="file"
						className="hidden"
						onChange={onFileChange}
						value=""
						ref={inputRef}
						accept=".pdf, .txt, .docx"
					></input>
					<Button
						onClick={onCreateNewDocument}
						icon={<NewDocumentIcon />}
						variant={ButtonVariant.Contained}
						data-cy="primary-action-button"
					>
						Novo documento
					</Button>
				</div>
			</div>

			<DashboardDocumentDisplay
				filterValue={filterValue}
				columnFilterValue={columnFilterValue}
				sorting={sorting}
				rowSelection={rowSelection}
				setColumnFilterValue={setColumnFilterValue}
				setFilterValue={setFilterValue}
				setSorting={setSorting}
				setRowSelection={setRowSelection}
				setIdsToDelete={setIdsToDelete}
				onCreateNewDocument={onCreateNewDocument}
			/>
			{idsToDelete?.length ? (
				<Footer
					stepText={`Documentos selecionados ${idsToDelete.length}`}
				>
					<div className="flex items-center">
						<Button
							variant={ButtonVariant.Text}
							onClick={() => setRowSelection([])}
						>
							Limpar seleção
						</Button>
						<ExclusionButton
							className="ml-4"
							onClick={() => setOpenDeleteDocumentModal(true)}
						>
							Excluir
						</ExclusionButton>
					</div>
				</Footer>
			) : null}
			<DeleteDocumentModal
				open={openDeleteDocumentModal}
				setOpen={setOpenDeleteDocumentModal}
				documentIds={idsToDelete}
				onRowSelectionChange={setRowSelection}
				onIdsToDeleteChange={setIdsToDelete}
			/>
		</div>
	)
}

export default MyDocumentsPage
