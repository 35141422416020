import { useMutation } from "@tanstack/react-query"
import useAgentBuilder from "hooks/useAgentBuilder"
import { useRef, useEffect } from "react"
import { serializeNode } from "../utils"
import { Node } from "../subcomponents/Flow"
import { useAtom } from "jotai"
import { promptRangesAtom, retrievalRangesAtom } from "../atoms"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"

interface SaveNodeParams {
	id?: string
	graphId: string
	currentNode: Node | undefined
	additionalParamToSave?: any
}

export const useSaveNode = ({
	id,
	graphId,
	currentNode,
	additionalParamToSave,
}: SaveNodeParams) => {
	const saveTimeout = useRef<NodeJS.Timeout | null>(null)
	const { editNode } = useAgentBuilder()
	const [promptRanges] = useAtom(promptRangesAtom)
	const [retrievalRanges] = useAtom(retrievalRangesAtom)

	const editNodeMutation = useMutation({
		mutationFn: editNode,
		onError: (error) => {
			showToast((error as Error)?.message, ToastType.Error)
		},
	})
	async function saveNode() {
		if (!currentNode) return

		let currentRange: any = undefined

		if (currentNode.type === "prompt" && promptRanges) {
			currentRange = promptRanges.find((r) => r.nodeId === id)
		}

		if (currentNode.type === "retrieval" && retrievalRanges) {
			currentRange = retrievalRanges.find((r) => r.nodeId === id)
		}

		const serializedNode = serializeNode(currentNode, graphId, {
			nodeId: currentRange?.nodeId || "",
			llm: currentRange?.llm,
			ranges:
				currentRange?.promptRanges ||
				currentRange?.retrievalRanges ||
				[],
		})
		await editNodeMutation.mutateAsync(serializedNode)
	}

	useEffect(() => {
		if (saveTimeout.current) {
			clearTimeout(saveTimeout.current)
		}

		saveTimeout.current = setTimeout(() => {
			saveNode()
		}, 1000)

		return () => {
			if (saveTimeout.current) {
				clearTimeout(saveTimeout.current)
			}
		}
	}, [currentNode, additionalParamToSave])
}
