const PromptIcon = ({ size = "24" }: { size?: string }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.31388 19.1125L13.2292 4.50049L14.6781 4.88872L10.7628 19.5008L9.31388 19.1125ZM0.75 11.9998L6 6.74978L7.0575 7.80728L2.8725 11.9998L7.0575 16.1923L6 17.2498L0.75 11.9998ZM23.25 11.9998L18 17.2498L16.9425 16.1923L21.1275 11.9998L16.9425 7.80728L18 6.74978L23.25 11.9998Z"
				fill="currentColor"
			/>
		</svg>
	)
}

export default PromptIcon
