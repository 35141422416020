import CloseIcon from "icons/CloseIcon"
import RangeSlider from "components/range-slider"
import { useAtom } from "jotai"
import { promptRangesAtom } from "../atoms"
import { useQuery } from "@tanstack/react-query"
import useAgentBuilder from "hooks/useAgentBuilder"
import { ButtonVariant } from "components/button/types"
import Center from "components/center"
import { defaultPromptRanges } from "../constants"
import { useSaveNode } from "../hooks/useSaveNode"
import { useReactFlow } from "@xyflow/react"
import { Node } from "./Flow"
import { useParams } from "react-router-dom"
import Button from "components/button-v2"
import ClickAwayListener from "react-click-away-listener"

export default function PromptConfig({
	setOpenPromptConfig,
	openPromptConfig,
}: {
	setOpenPromptConfig: (value: string | null) => void
	openPromptConfig: string
}) {
	const [promptRanges, setPromptRanges] = useAtom(promptRangesAtom)
	const { getAvailableLLMS } = useAgentBuilder()
	const currentRange = promptRanges.find((r) => r.nodeId === openPromptConfig)
	const { data: llms } = useQuery(["llms"], getAvailableLLMS)
	const { getNode } = useReactFlow()
	const { graphId = "" } = useParams()

	const currentNode = getNode(openPromptConfig) as Node | undefined

	if (!currentRange) return null

	function onResetDefault() {
		const newRanges = promptRanges.map((r) => {
			if (r.nodeId === openPromptConfig) {
				return {
					...r,
					promptRanges: defaultPromptRanges[0].promptRanges,
				}
			}
			return r
		})
		setPromptRanges(newRanges)
	}

	useSaveNode({
		currentNode,
		graphId,
		id: openPromptConfig,
		additionalParamToSave: promptRanges,
	})
	if (!llms) return

	return (
		<ClickAwayListener onClickAway={() => setOpenPromptConfig(null)}>
			<div className="bg-white w-[330px] h-[calc(100vh-52px)] fixed z-50 right-0 top-[52px] border-l-[1px] border-[#F0F0F0]">
				<div className="h-full flex flex-col justify-between">
					<div className="h-full">
						<div className="p-6 flex flex-col gap-6">
							<div className="flex items-center justify-between text-dark-blue-1">
								<p className="text-cta-1">Configurações</p>
								<div
									onClick={() => setOpenPromptConfig(null)}
									className="cursor-pointer"
								>
									<CloseIcon size="20" />
								</div>
							</div>
							<select
								value={currentRange.llm}
								onChange={(e) => {
									setPromptRanges((prev) =>
										prev.map((r) =>
											r.nodeId === openPromptConfig
												? {
														...r,
														llm: e.target.value,
														promptRanges:
															r.promptRanges.map(
																(rr) =>
																	rr.id ===
																	"max_tokens"
																		? {
																				...rr,
																				value:
																					llms.find(
																						(
																							llm,
																						) =>
																							llm.model_name ===
																							e
																								.target
																								.value,
																					)
																						?.context_window ||
																					0,
																		  }
																		: rr,
															),
												  }
												: r,
										),
									)
								}}
								id="parser"
								className="text-gray-900 text-sm rounded-md focus:ring-blue-500  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 bg-white dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-[1px] border-brand-gray-1 py-3 placeholder:text-[#ACACAC] focus:border-light-blue-4"
							>
								{llms &&
									llms.map((llml, index) => (
										<option
											value={llml.model_name}
											key={index}
										>
											{llml.model_name}
										</option>
									))}
							</select>

							<div className="w-full h-[1px] bg-[#F0F0F0] " />
						</div>
						<div className="p-6 pt-0 flex flex-col gap-6">
							{currentRange.promptRanges.map((range, index) => {
								const currentLlm = llms.find(
									(llm) =>
										llm.model_name === currentRange.llm,
								)
								if (
									currentLlm?.valid_parameters.find(
										(llm) => llm === range.id,
									)
								) {
									return (
										<RangeSlider
											title={range.title}
											max={
												range.id === "max_tokens"
													? currentLlm?.context_window
													: range.max
											}
											step={
												range.id === "max_tokens"
													? 1
													: 0.05
											}
											onChange={(value: number) => {
												setPromptRanges((prev) =>
													prev.map((r) =>
														r.nodeId ===
														openPromptConfig
															? {
																	...r,
																	promptRanges:
																		r.promptRanges.map(
																			(
																				rr,
																			) =>
																				rr.id ===
																				range.id
																					? {
																							...rr,
																							value,
																					  }
																					: rr,
																		),
															  }
															: r,
													),
												)
											}}
											value={range.value}
											key={index}
										/>
									)
								} else {
									return null
								}
							})}
						</div>
					</div>

					<Center className="px-6 pb-6">
						<Button
							variant={ButtonVariant.Outlined}
							className="w-full"
							onClick={onResetDefault}
						>
							Reset default
						</Button>
					</Center>
				</div>
			</div>
		</ClickAwayListener>
	)
}
