import React, { useEffect, useRef, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import {
	TbBell,
	TbFilePlus,
	TbGavel,
	TbHeadset,
	TbLogout,
	TbSettings,
	TbSparkles,
	TbSwitchHorizontal,
} from "react-icons/tb"

import LogoIcon from "icons/LogoIcon"
import Center from "components/center"
import { Dropdown, DropdownSectionProps } from "components/dropdown"
import { useFeatureFlag } from "hooks/useFeatureFlag"
import FEATURE_FLAGS from "feature-flags"

import { ROUTES } from "routes"
import useFreePlan from "hooks/useFreePlan"
import useBasicPlan from "hooks/useBasicPlan"
import { useAuth0 } from "@auth0/auth0-react"
import useCurrentUser from "hooks/useCurrentUser"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useJwt } from "hooks/useJwt"
import { RetrieveLegalDocumentResponse } from "types/legalDocument"
import {
	addDays,
	format,
	isToday,
	isYesterday,
	parseISO,
	subMonths,
} from "date-fns"
import { ptBR } from "date-fns/locale"
import SidebarDocItem from "./subcomponents/SidebarDocItem"
import Credits from "pages/BasePage/subcomponents/Credits"
import Profile from "./subcomponents/Profile"
import Toast from "components/toast"
import AlertMessage from "./subcomponents/AlertMessage"
import useSubscriptionAPI from "hooks/useSubscriptionAPI"
import handleCheckoutError from "utils/handleCheckoutError"
import { showToast } from "components/toast/functions"
import { formatDate } from "utils/formatDate"
import { useAtom } from "jotai"
import { hideSidebarAtom } from "./atoms"
import UpgradeButton from "components/upgrade-button"
import { WS_SERVER } from "config"
import { getOldDocumentStatus } from "utils/getOldDocumentStatus"

interface IconItemProps {
	icon: React.ReactNode
	sidebarIsOpen: boolean
	name: string
	href: string
}

const IconItem: React.FC<IconItemProps> = ({
	icon,
	sidebarIsOpen,
	name,
	href,
}) => {
	const { pathname } = useLocation()
	const navigate = useNavigate()
	const isNewDocument = href === "/"
	const isSamePath = pathname === href

	function getContainerClasses() {
		if (isNewDocument) {
			if (sidebarIsOpen) {
				return "!text-white bg-[#3083FF] w-full"
			}
		}

		if (isSamePath) {
			if (sidebarIsOpen) {
				return "text-[#3083FF] bg-[#EEF6FF] w-full"
			} else {
				return "text-[#3083FF] bg-[#EEF6FF]"
			}
		} else {
			if (sidebarIsOpen) {
				return "text-[#3D3D3D] bg-transparent w-full hover:bg-[#F5F5F5]"
			} else {
				return "text-[#6D6D6D]"
			}
		}
	}

	return (
		<div
			className={`p-3 flex items-center gap-3 transition-[width,opacity] duration-150 ease-linear h-[44px] rounded-lg overflow-hidden cursor-pointer ${getContainerClasses()}`}
			onClick={() => navigate(href)}
		>
			<div>{icon}</div>

			<p
				className={`font-semibold text-cta-2 text-nowrap whitespace-nowrap duration-150 ease-linear   ${
					sidebarIsOpen
						? "opacity-100 ml-[0px]"
						: "opacity-0 ml-[-8px]"
				}`}
			>
				{name}
			</p>
		</div>
	)
}

export default function NewSidebar() {
	const [hideSidebar] = useAtom(hideSidebarAtom)

	const [sidebarIsOpen, setSidebarIsOpen] = useState(false)
	const [openProfileMenu, setOpenProfileMenu] = useState(false)
	const [hoverCredits, setHoverCredits] = useState(false)
	const {
		subscriptionCustomerPortal,
		subscriptionReactivatePlan,
		getDefaultCard,
	} = useSubscriptionAPI()
	const { data: isAdminUserFeatureFlagEnabled } = useFeatureFlag(
		FEATURE_FLAGS.isAdminUser,
	)
	const { listLegalDocuments } = useLegalDocumentAPI()

	const { logout } = useAuth0()
	const isFreeUser = useFreePlan()
	const isBasicUser = useBasicPlan()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const user = useCurrentUser()
	const [jwt] = useJwt()
	const [documents, setDocuments] = useState<
		Record<string, RetrieveLegalDocumentResponse[]>
	>({})

	const { data: listLegalDocumentsData } = useQuery(
		["listLegalDocuments", jwt],
		() => listLegalDocuments(),
	)
	const { data: defaultCard } = useQuery(["defaultCard", jwt], () =>
		getDefaultCard(),
	)

	const subscriptionCustomerPortalMutation = useMutation({
		mutationFn: subscriptionCustomerPortal,
	})

	const subscriptionReactivatePlanMutation = useMutation({
		mutationFn: subscriptionReactivatePlan,
	})

	const sectionsData = [
		isAdminUserFeatureFlagEnabled && {
			label: "Painel usuário",

			onClick: () => navigate(ROUTES.admin()),
			icon: <TbSwitchHorizontal size="20px" />,
		},
		{
			label: "Notificações",

			onClick: () => navigate(ROUTES.notification()),
			icon: <TbBell size="20px" />,
		},
		{
			label: "Configurações",
			onClick: () => navigate(ROUTES.settings()),
			icon: <TbSettings size="20px" />,
		},
		!isFreeUser &&
			!isBasicUser && {
				label: "Suporte WhatsApp",
				onClick: () =>
					window.open("https://wa.me/554197081727", "_blank"),
				icon: <TbHeadset size="20px" />,
			},
		{
			label: "Sair",
			deleteLabel: true,
			onClick: () => {
				logout({
					logoutParams: {
						returnTo: window.location.origin,
					},
				})
			},
			icon: <TbLogout size="20px" />,
		},
	].filter(Boolean)

	const sections = [
		{
			items: sectionsData,
		},
	]

	function handleChangeCreditCard() {
		const tab = window.open(`${window.location.origin}/redirect`)
		subscriptionCustomerPortalMutation
			.mutateAsync({
				returnUrl: `${window.location.href}`,
			})
			.then((data) => (tab!.location.href = data.customer_portal_url))
			.catch(() => handleCheckoutError(tab))
	}

	function handleKeepPlan() {
		subscriptionReactivatePlanMutation.mutateAsync().then(() => {
			queryClient.invalidateQueries(["current-user", jwt])
			showToast("Sucesso! Sua assinatura será mantida.")
		})
	}

	function groupByUpdatedAt(): Record<
		string,
		RetrieveLegalDocumentResponse[]
	> {
		if (!listLegalDocumentsData) return {}
		const grouped: Record<string, RetrieveLegalDocumentResponse[]> = {}

		listLegalDocumentsData
			.filter(
				(doc) =>
					doc.graph_execution_status === "SUCCESS" ||
					doc.graph_execution_status === "START" ||
					getOldDocumentStatus(doc) === "SUCCESS",
			)
			.forEach((doc) => {
				const date = parseISO(doc._updated_at)

				let key: string
				if (isToday(date)) {
					key = "Hoje"
				} else if (isYesterday(date)) {
					key = "Ontem"
				} else {
					key = format(date, "dd 'de' MMMM 'de' yyyy", {
						locale: ptBR,
					})
				}

				if (!grouped[key]) {
					grouped[key] = []
				}
				grouped[key].push(doc)
			})

		return grouped
	}
	const wsRef = useRef<WebSocket>()

	useEffect(() => {
		if (listLegalDocumentsData) {
			const newDocuments = groupByUpdatedAt()
			setDocuments(newDocuments)
		}
	}, [listLegalDocumentsData])

	useEffect(() => {
		const socket = new WebSocket(`${WS_SERVER.baseUrl}/?token=${jwt}`)
		wsRef.current = socket
		socket.onopen = () => {}
		socket.onmessage = (e) => {
			if (e.data === "ping") {
				socket.send("pong")
				return
			}
			try {
				const data = JSON.parse(e.data)
				if (data.event_type === "GRAPH_EXECUTION_STATUS_UPDATE") {
					queryClient.invalidateQueries(["listLegalDocuments", jwt])
				}
			} catch (error) {
				return
			}
		}
		socket.onerror = () => {}
		socket.onclose = () => {}
		return () => {
			socket.close(1000, "finished")
		}
	}, [jwt])

	return (
		<div className="flex relative justify-end w-full !font-Red-Hat-Display bg-[#FCFCFC]">
			{!hideSidebar && (
				<div
					className={` z-50  ${
						sidebarIsOpen ? "w-[344px]" : "w-[68px]"
					} fixed top-0 left-0 bg-transparent`}
					onMouseLeave={() => {
						setSidebarIsOpen(false)
						setOpenProfileMenu(false)
						setHoverCredits(false)
					}}
				>
					<div
						className={`  p-3 flex flex-col justify-between  bg-[#FCFCFC] h-screen duration-150 ease-linear ${
							sidebarIsOpen ? "w-[224px]" : "w-[68px]"
						}  ${
							sidebarIsOpen
								? "border-r border-[#F0F0F0] bg-[#FFF]"
								: ""
						}`}
						onMouseEnter={() => setSidebarIsOpen(true)}
					>
						<div>
							<div
								className={`duration-150 ease-linear flex justify-start items-center ${
									sidebarIsOpen ? "w-full" : "w-[44px]"
								}`}
							>
								<div>
									<Center className="w-[44px] h-[44px]">
										<LogoIcon className="fill-light-blue-4" />
									</Center>
								</div>
								<p
									className={`duration-150 ease-linear text-dark-blue-1 text-h3 text-nowrap whitespace-nowrap !font-lato ${
										sidebarIsOpen
											? "ml-0 opacity-1"
											: "ml-[-8px] opacity-0"
									}`}
								>
									Lawdeck
								</p>
							</div>

							<div className="mt-6 flex flex-col gap-1">
								<IconItem
									icon={<TbFilePlus size="20px" />}
									sidebarIsOpen={sidebarIsOpen}
									name="Criar peça"
									href={"/"}
								/>
								<IconItem
									icon={<TbSparkles size="20px" />}
									sidebarIsOpen={sidebarIsOpen}
									name="Assistente jurídico"
									href={"/legal-assistant"}
								/>
								<IconItem
									icon={<TbGavel size="20px" />}
									sidebarIsOpen={sidebarIsOpen}
									name="Jurisprudência"
									href={"/search-jurisprudence"}
								/>
							</div>
						</div>

						<div
							className={`h-[calc(100vh-270px)] mt-6 overflow-y-auto overflow-x-hidden custom-scrollbar duration-150 ease-linear ${
								sidebarIsOpen ? " opacity-1" : " opacity-0 "
							}`}
						>
							{Object.entries(documents).map(
								([dateLabel, docs]) => (
									<div key={dateLabel} className="mb-4">
										<p className="text-small-1 text-[#B0B0B0] ml-3 text-nowrap whitespace-nowrap">
											{dateLabel}
										</p>

										{docs.map((doc) => {
											return (
												<SidebarDocItem
													key={doc.id}
													doc={doc}
													sidebarIsOpen={
														sidebarIsOpen
													}
												/>
											)
										})}
									</div>
								),
							)}
						</div>

						<div className="flex flex-col  mt-2">
							{sidebarIsOpen &&
								(user?.subscription?.status === "past_due" ||
									user?.subscription
										?.cancel_at_period_end) && (
									<AlertMessage
										title={
											user?.subscription?.status ===
											"past_due"
												? "Pagamento recusado!"
												: "Assinatura cancelada!"
										}
										message={
											user?.subscription?.status ===
											"past_due"
												? `Atualize seu cartão final *${defaultCard?.last4} até 	${
														user?.subscription
															?.end_period &&
														format(
															subMonths(
																addDays(
																	new Date(
																		user
																			?.subscription
																			?.end_period,
																	),
																	3,
																),
																1,
															),
															"dd/MM/yy",
														)
												  } para manter seu plano ativo.`
												: `Você solicitou o cancelamento da sua assinatura, que ficará ativa até ${
														formatDate(
															user?.subscription
																?.end_period,
														).split(" ")[0]
												  }.`
										}
										buttonText={
											user?.subscription?.status ===
											"past_due"
												? "Atualizar cartão"
												: "Manter assinatura"
										}
										buttonAction={() => {
											if (
												user?.subscription?.status ===
												"past_due"
											) {
												handleChangeCreditCard()
											} else {
												handleKeepPlan()
											}
										}}
									/>
								)}
							{isFreeUser && (
								<div
									className={`w-full duration-150 ease-linear mb-2 ${
										sidebarIsOpen
											? "opacity-100 "
											: "opacity-0 "
									}`}
								>
									<UpgradeButton
										isBig
										className={"w-full justify-center"}
										text={"Fazer upgrade"}
									/>
								</div>
							)}
							<Credits
								sidebarIsOpen={sidebarIsOpen}
								isHovered={hoverCredits}
								setIsHovered={setHoverCredits}
							/>
							<Dropdown
								className={`right-[5px] bottom-[58px] min-w-[190px]`}
								sections={sections as DropdownSectionProps[]}
								shouldCloseAfterClick
								open={openProfileMenu}
								setOpen={setOpenProfileMenu}
							>
								<div className="align-center flex cursor-pointer">
									{user && (
										<Profile
											user={user}
											openProfileMenu={openProfileMenu}
											sidebarIsOpen={sidebarIsOpen}
										/>
									)}
								</div>
							</Dropdown>
						</div>
					</div>
				</div>
			)}

			<div
				className={`${
					hideSidebar ? "w-full h-screen" : "w-[calc(100vw-76px)] p-3"
				}  bg-[#FCFCFC] `}
			>
				<Outlet />
			</div>
			<Toast />
		</div>
	)
}
