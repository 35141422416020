import {
	TbBriefcase,
	TbFileDots,
	TbOld,
	TbPencil,
	TbPrison,
	TbUsers,
} from "react-icons/tb"
import { TbGavel, TbPaperclip } from "react-icons/tb"

export const areas = [
	{ label: "Trabalhista", value: "LABOR" },
	{ label: "Previdenciário", value: "PREVIDENCIARY" },
	{ label: "Penal", value: "CRIMINAL" },
	{ label: "Civil", value: "CIVIL" },
	{ label: "Outros", value: "OTHER" },
]

export const translatedAreaIcons = {
	Trabalhista: <TbBriefcase color="#D58C8C" size="100%" />,
	Previdenciário: <TbOld color="#A76AAC" size="100%" />,
	Penal: <TbPrison color="#E4AB55" size="100%" />,
	Civil: <TbUsers color="#9195FF" size="100%" />,
	Outros: <TbFileDots color="#59A8FF" size="100%" />,
}

export const areaIcons = {
	LABOR: <TbBriefcase color="#D58C8C" size="100%" />,
	PREVIDENCIARY: <TbOld color="#A76AAC" size="100%" />,
	CRIMINAL: <TbPrison color="#E4AB55" size="100%" />,
	CIVIL: <TbUsers color="#9195FF" size="100%" />,
	OTHER: <TbFileDots color="#59A8FF" size="100%" />,
}

export const addonsIcons = {
	tom_escrita: <TbPencil size="16px" color="#6D6D6D" />,
	tribunais: <TbGavel size="16px" color="#6D6D6D" />,
	main_upload: <TbPaperclip size="16px" color="#6D6D6D" />,
}
