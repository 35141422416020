import { useEffect } from "react"
import { useState } from "react"

interface StatusChipProps {
	status?: "SUCCESS" | "START" | "ERROR"
	small?: boolean
	className?: string
}

function ProcessingText() {
	const [dots, setDots] = useState(0)

	useEffect(() => {
		const interval = setInterval(() => {
			setDots(dots < 2 ? dots + 1 : 0)
		}, 500)

		return () => clearInterval(interval)
	}, [dots])

	return (
		<div className="relative inline-block">
			<span>
				Processando.
				{dots > 0 ? (
					<span className={"opacity-100"}>.</span>
				) : (
					<span className={"opacity-0"}>.</span>
				)}
				{dots > 1 ? (
					<span className={"opacity-100"}>.</span>
				) : (
					<span className={"opacity-0"}>.</span>
				)}
			</span>
		</div>
	)
}

const StatusChip = ({ status, small, className }: StatusChipProps) => {
	function getStatus() {
		switch (status) {
			case "SUCCESS":
				return {
					text: "Completo",
					color: "bg-green-opacity text-brand-green",
				}
			case "START":
				return {
					text: <ProcessingText />,
					color: "bg-semi-white-3 text-light-blue-1",
				}
			case "ERROR":
				return {
					text: "Erro",
					color: "bg-red-opacity text-brand-red",
				}
			default:
				return {
					text: "Erro",
					color: "bg-red-opacity text-brand-red",
				}
		}
	}

	return (
		<div
			className={`rounded p-1 text-center ${getStatus()
				?.color} w-[130px] gap-2  mr-8 ${
				small && "h-[28px] w-fit text-small-1 font-semibold px-2"
			} ${className}`}
		>
			{getStatus()?.text}
		</div>
	)
}

export default StatusChip
