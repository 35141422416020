import Center from "components/center"
import { useState } from "react"
import ReactTextareaAutosize from "react-textarea-autosize"
import { TbSearch, TbUpload } from "react-icons/tb"
import { Variables } from "../types"
import InputField from "components/input-field"
import ClickAwayListener from "react-click-away-listener"
import useAgentBuilder from "hooks/useAgentBuilder"
import { useMutation } from "@tanstack/react-query"
import { useParams } from "react-router-dom"
import { useAtom } from "jotai"
import { courtAtom } from "../atoms"
import LoadingSpinner from "pages/MyDocumentsPage/subcomponents/LoadingSpinner"
import { ToastType } from "components/toast/types"
import { showToast } from "components/toast/functions"

export default function VariableValues({
	currentVariable,
	setVariables,
	variables,
	inputId,
	setOpenCourtModal,
	onBlur,
	disabled,
}: {
	currentVariable: Variables
	setVariables: (variable: Variables[]) => void
	variables: Variables[]
	inputId: string
	setOpenCourtModal?: (value: boolean) => void
	onBlur?: () => void
	disabled?: boolean
}) {
	const [focused, setFocused] = useState(false)
	const { addAttachDocument, addInputVariable } = useAgentBuilder()
	const addAttachDocumentMutation = useMutation({
		mutationFn: addAttachDocument,
	})
	const { graphId = "" } = useParams()

	const [selectedCourts] = useAtom(courtAtom)
	const addInputVariableMutation = useMutation({
		mutationFn: addInputVariable,
		onError: (error) => {
			showToast((error as Error)?.message, ToastType.Error)
		},
	})

	const handleFileChange = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const { files } = event.target
		if (!files || files.length === 0) return

		const fileList = Array.from(files)

		let variableId = currentVariable?.id
		if (!variableId) {
			try {
				const response = await addInputVariableMutation.mutateAsync({
					name: currentVariable.name.slice(1, -1),
					type: "FILES",
					graph: graphId,
					is_global: true,
					value: { value: [] },
				})
				variableId = response.id
			} catch (error) {
				console.error("Erro ao criar variável:", error)
				return
			}
		}

		for (const file of fileList) {
			try {
				const data = await addAttachDocumentMutation.mutateAsync({
					variableId: variableId || "",
					file_path: { file_path: file },
				})

				const newValue =
					data?.value?.value ??
					fileList.map((f) => ({ name: f.name }))

				const updatedVariables = variables.map((variable) =>
					variable.id === currentVariable.id
						? { ...variable, value: newValue, id: variableId }
						: variable,
				)
				setVariables(updatedVariables)
				handleBlur()
			} catch (error) {
				console.error("Erro ao anexar documento:", error)
			}

			await new Promise((resolve) => setTimeout(resolve))
		}
	}

	function handleBlur() {
		if (onBlur) {
			onBlur()
		}
	}

	if (currentVariable?.type === "FILES") {
		return (
			<div className="relative" onBlur={handleBlur}>
				<label
					htmlFor={inputId}
					className={`w-[156px] h-[44px] mt-3 nodrag bg-white rounded-md border-[1px] border-brand-gray-1 flex items-center px-3 cursor-pointer ${
						(disabled || !currentVariable.name) && "!bg-[#F5F5F5]"
					} `}
				>
					{currentVariable?.value.length > 0 ? (
						<p className="text-cta-2 text-[#3D3D3D] ">
							{currentVariable?.value.length} Anexo
							{currentVariable?.value.length > 1 ? "s" : ""}
						</p>
					) : (
						<p className="text-cta-2 text-[#B0B0B0] ">Upload</p>
					)}
				</label>

				<input
					id={inputId}
					type="file"
					multiple
					accept=".pdf,.docx"
					className="hidden"
					onChange={handleFileChange}
					disabled={
						disabled ||
						addAttachDocumentMutation.isLoading ||
						!currentVariable.name
					}
				/>
				<Center
					className="w-[44px] h-[44px] bg-[#FCFCFC] absolute top-0 right-0 rounded-r-md border-[1px] border-brand-gray-1 nodrag cursor-pointer"
					onChange={handleFileChange}
				>
					<label htmlFor={inputId}>
						{addAttachDocumentMutation.isLoading ? (
							<LoadingSpinner />
						) : (
							<TbUpload
								color={disabled ? "#B0B0B0" : "#6D6D6D"}
								size="16px"
							/>
						)}
					</label>
				</Center>
			</div>
		)
	}

	if (currentVariable?.type === "NUMBER") {
		return (
			<InputField
				placeholder="Valor"
				className="w-[156px] h-[44px] mt-3 nodrag truncate text-cta-2"
				value={currentVariable?.value}
				onChange={(e) => {
					const newVariables = variables.map((variable) => {
						if (variable.id === currentVariable.id) {
							return {
								...variable,
								value: e.target.value,
							}
						}
						return variable
					})
					setVariables(newVariables)
				}}
				type="number"
				onBlur={handleBlur}
				disabled={disabled}
			/>
		)
	}

	if (currentVariable?.type === "COURTS" && setOpenCourtModal) {
		return (
			<div
				className="relative"
				onClick={() => !disabled && setOpenCourtModal(true)}
			>
				<label
					className={`w-[156px] h-[44px] mt-3 nodrag bg-white rounded-md border-[1px] border-brand-gray-1 flex items-center px-3 cursor-pointer ${
						disabled && "!bg-[#F5F5F5]"
					}`}
				>
					{selectedCourts.length > 0 && !disabled ? (
						<p className="text-cta-2 text-[#3D3D3D] ">
							{`${selectedCourts.length} Tribuna${
								selectedCourts.length > 1 ? "is" : "l"
							}`}
						</p>
					) : (
						<p className="text-cta-2 text-[#B0B0B0] ">Selecione</p>
					)}
				</label>
				<Center
					className="w-[44px] h-[44px] bg-[#FCFCFC] absolute top-0 right-0 rounded-r-md border-[1px] border-brand-gray-1 nodrag cursor-pointer"
					onChange={handleFileChange}
				>
					<TbSearch
						color={disabled ? "#B0B0B0" : "#6D6D6D"}
						size="16px"
					/>
				</Center>
			</div>
		)
	}

	return (
		<ClickAwayListener
			onClickAway={() => {
				setFocused(false)
			}}
		>
			<div className="relative">
				{!focused ? (
					<InputField
						placeholder="Valor"
						className="w-[156px] h-[44px] mt-3 nodrag truncate"
						value={currentVariable?.value}
						onChange={() => {}}
						onFocus={() => setFocused(true)}
						disabled={disabled}
					/>
				) : (
					<ReactTextareaAutosize
						placeholder="Valor"
						className={` z-20 absolute top-0 min-h-[44px] left-0 max-h-[470px] w-[156px] text-cta-2 placeholder:absolute placeholder:top-[9px]   mt-3 nodrag !outline-offset-0 block rounded-md border !border-1 resize-none focus:!outline-none border-brand-gray-1 focus:border focus:border-light-blue-4  placeholder:text-[#B0B0B0]`}
						onBlur={() => {
							setFocused(false)
							handleBlur()
						}}
						autoFocus
						value={currentVariable?.value}
						onChange={(e) => {
							const newVariables = variables.map((variable) => {
								if (variable.id === currentVariable.id) {
									return {
										...variable,
										value: e.target.value,
									}
								}
								return variable
							})

							setVariables(newVariables)
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter" && !e.shiftKey) {
								e.preventDefault()
								setFocused(false)
							}
						}}
					/>
				)}
			</div>
		</ClickAwayListener>
	)
}
