import { Position, useReactFlow } from "@xyflow/react"
import Center from "components/center"
import InputField from "components/input-field"
import Handle from "../subcomponents/Handle"
import { useEffect, useRef, useState } from "react"

import IconBox from "../subcomponents/IconBox"
import VariableValues from "../subcomponents/VariableValues"
import { TbEye, TbEyeOff, TbForms, TbPaperclip } from "react-icons/tb"
import CloseIcon from "icons/CloseIcon"
import TagFile1 from "icons/TagFile1"
import TagFile2 from "icons/TagFile2"
import useAgentBuilder from "hooks/useAgentBuilder"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Node, NodeData } from "../subcomponents/Flow"
import { useAtom } from "jotai"
import { inputVariablesAtom } from "../atoms"
import { serializeNode } from "../utils"
import CourtModal from "../subcomponents/CourtModal"
import { defaultInputVariables } from "../constants"
import { InputVariable } from "types/agentBuilder"
import { Variables } from "../types"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"

export default function Input({ data, id }: { data: NodeData; id: string }) {
	const [inputVariables, setInputVariables] = useAtom(inputVariablesAtom)
	const [openCourtModal, setOpenCourtModal] = useState(false)
	const { editNode, getGraph, deleteInputVariable, addInputVariable } =
		useAgentBuilder()
	const { getNode } = useReactFlow()
	const saveTimeout = useRef<NodeJS.Timeout | null>(null)
	const currentNode = getNode(id) as Node | undefined
	const queryClient = useQueryClient()

	const { data: graph } = useQuery(
		["graph", data.graphId],
		() => getGraph(data.graphId),

		{
			refetchOnWindowFocus: false,
		},
	)
	const editNodeMutation = useMutation({
		mutationFn: editNode,
		onError: (error) => {
			showToast((error as Error)?.message, ToastType.Error)
		},
	})

	const deleteInputVariableMutation = useMutation({
		mutationFn: deleteInputVariable,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["graph", data.graphId],
			})
		},
		onError: (error) => {
			showToast((error as Error)?.message, ToastType.Error)
		},
	})

	const addInputVariableMutation = useMutation({
		mutationFn: addInputVariable,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["graph", data.graphId],
			})
		},
		onError: (error) => {
			showToast((error as Error)?.message, ToastType.Error)
		},
	})

	async function onEyeClick(index: number) {
		const currentVariable = inputVariables[index]
		if (currentVariable?.id) {
			queryClient.setQueryData(
				["graph", data.graphId],
				(oldData: any) => {
					return {
						...oldData,
						input_variables: oldData.input_variables.filter(
							(variable: InputVariable) =>
								variable.id !== currentVariable.id,
						),
					}
				},
			)
			if (currentVariable.name === "tom_escrita") {
				setInputVariables((oldVariables) => {
					const newVariables = [...oldVariables]
					newVariables[index].value = ""
					return newVariables
				})
			}

			await deleteInputVariableMutation.mutateAsync(currentVariable.id)
		} else {
			queryClient.setQueryData(
				["graph", data.graphId],
				(oldData: any) => {
					return {
						...oldData,
						input_variables: [
							...oldData.input_variables,
							{
								name: currentVariable.name,
								type: currentVariable.type,
								value: currentVariable.value,
								graph: data.graphId,
								is_global: false,
							},
						],
					}
				},
			)

			const foundVariable = defaultInputVariables[index]

			const variable = {
				name: foundVariable.name,
				type: foundVariable.type,
				graph: data.graphId,
				is_global: false,
			}
			await addInputVariableMutation.mutateAsync(variable)
		}
	}

	async function saveNode() {
		if (!currentNode) return
		const serializedNode = serializeNode(currentNode, data.graphId)
		await editNodeMutation.mutateAsync(serializedNode)
	}

	useEffect(() => {
		if (saveTimeout.current) {
			clearTimeout(saveTimeout.current)
		}
		saveTimeout.current = setTimeout(() => {
			saveNode()
		}, 1000)

		return () => {
			if (saveTimeout.current) {
				clearTimeout(saveTimeout.current)
			}
		}
	}, [currentNode])

	useEffect(() => {
		if (graph) {
			const filteredVariables = graph.input_variables.filter(
				(variable) => !variable.is_global,
			)

			const newVariables = defaultInputVariables.map((variable) => {
				const foundVariable = filteredVariables.find(
					(v) => v.name === variable.name,
				) as InputVariable

				const currentVariable = inputVariables.find(
					(v) => v.name === variable.name,
				)

				return {
					name: variable.name,
					value: currentVariable?.value
						? currentVariable.value
						: variable.type === "FILES"
						? []
						: "",
					type: variable.type,
					id: foundVariable?.id || "",
				}
			})
			setInputVariables(newVariables)
		}
	}, [graph])

	return (
		<div className="w-[400px] p-2 flex flex-col gap-2 bg-white border-[1px] border-brand-white-4 rounded-2xl">
			<Handle type="target" position={Position.Left} />
			<Handle type="source" position={Position.Right} />
			<div className="p-2 bg-[#FFF2E0] flex  items-center text-[#D37C3D] font-semibold rounded-lg gap-3">
				<Center className="w-[48px] h-[48px] p-2 bg-white rounded">
					<TbForms size="32px" color="#D37C3D" />
				</Center>

				<div className="flex flex-col ">
					<h4 className=" text-[#D37C3D]">Input</h4>
				</div>
			</div>

			<div className="p-4 bg-brand-white-2 rounded-lg">
				{defaultInputVariables.map((variable, index) => {
					const foundVariable = inputVariables.find(
						(v) => v.name === variable.name,
					) as Variables

					const currentGraphVariable = graph?.input_variables.find(
						(v) => v.name === variable.name,
					)

					return (
						<div key={index}>
							<div className=" flex gap-2 text-cta-2">
								<div>
									{index === 0 && (
										<p className="font-semibold">Nome</p>
									)}
									<div className="mt-3 relative nodrag">
										<InputField
											placeholder="Nome"
											className="w-[156px] h-[44px] truncate disabled:text-[#B0B0B0]"
											value={variable.name}
											disabled
											onChange={(e) => {
												const newVariables = [
													...inputVariables,
												]
												newVariables[index].name =
													e.target.value
												setInputVariables(newVariables)
											}}
											onBlur={() => {
												const newVariables = [
													...inputVariables,
												]
												let name =
													newVariables[
														index
													].name.trim()
												if (name) {
													name = `{${name}}`
												}
												newVariables[index].name = name
												setInputVariables(newVariables)
											}}
											onFocus={() => {
												const newVariables = [
													...inputVariables,
												]
												let name =
													newVariables[
														index
													].name.trim()

												if (
													name.startsWith("{") &&
													name.endsWith("}")
												) {
													name = name.slice(1, -1)
												}
												newVariables[index].name = name
												setInputVariables(newVariables)
											}}
										/>
									</div>
								</div>
								<div className="relative">
									{index === 0 && (
										<p className="font-semibold">Valor</p>
									)}
									<div className="w-[156px]">
										<VariableValues
											setVariables={setInputVariables}
											variables={inputVariables}
											currentVariable={foundVariable}
											inputId={`${index}-input`}
											setOpenCourtModal={
												setOpenCourtModal
											}
											disabled={!currentGraphVariable}
										/>
									</div>
									{index > 1 && (
										<IconBox
											className="absolute top-[21px] right-[-36px] nodrag"
											onClick={() => onEyeClick(index)}
										>
											{currentGraphVariable ? (
												<TbEye
													size="16px"
													color="#6D6D6D"
												/>
											) : (
												<TbEyeOff
													size="16px"
													color="#6D6D6D"
												/>
											)}
										</IconBox>
									)}
								</div>
							</div>
							<div className="nodrag">
								{foundVariable?.type === "FILES" &&
									foundVariable?.value && (
										<>
											{foundVariable?.value.map(
												(file: File, i: number) => {
													return (
														<div
															className="flex items-center"
															key={i}
														>
															{i ===
															foundVariable.value
																.length -
																1 ? (
																<TagFile2 />
															) : (
																<TagFile1 />
															)}

															<div className="w-[288px] flex items-center justify-between p-2 bg-[#F5F5F5] border-[1px] border-[#F0F0F0] rounded-lg text-[#6D6D6D] text-small-1">
																<div className="flex items-center gap-1">
																	<TbPaperclip size="16px" />
																	<p className="w-[208px] truncate">
																		{
																			file.name
																		}
																	</p>
																</div>
																<div
																	className="cursor-pointer"
																	onClick={() => {
																		const newVariables =
																			[
																				...inputVariables,
																			]
																		newVariables[
																			index
																		].value =
																			newVariables[
																				index
																			].value.filter(
																				(
																					_: any,
																					fileIndex: number,
																				) =>
																					fileIndex !==
																					i,
																			)
																		setInputVariables(
																			newVariables,
																		)
																	}}
																>
																	<CloseIcon
																		size="16"
																		className="fill-[#6D6D6D]"
																	/>
																</div>
															</div>
														</div>
													)
												},
											)}
										</>
									)}
							</div>
						</div>
					)
				})}
			</div>
			{openCourtModal && (
				<CourtModal open={openCourtModal} setOpen={setOpenCourtModal} />
			)}
		</div>
	)
}
