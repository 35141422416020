import React, { useState } from "react"
import ClickAwayListener from "react-click-away-listener"
import { Button } from "../button"
import { motion } from "framer-motion"
import DropdownSectionTitle from "./subcomponents/DropdownSectionTitle"
import DropdownSectionItem from "./subcomponents/DropdownItem"
import { ButtonVariant } from "components/button/types"

export interface DropdownItemProps {
	label: string | React.ReactNode
	deleteLabel?: boolean
	className?: string
	onClick?: any
	onMouseEnter?: () => void
	onMouseLeave?: () => void
	icon?: React.ReactNode
	rightIcon?: React.ReactNode
	labelClassName?: string
	disabled?: boolean
	type?: string
}

export interface DropdownSectionProps {
	title?: React.ReactNode
	titleClassName?: string
	items: DropdownItemProps[]
}

export interface DropdownMenuProps {
	sections: DropdownSectionProps[]
	footerButtonLabel?: string
	shouldCloseAfterClick?: boolean
	onFooterButtonClick?: () => void
	className?: string
	itemClassName?: string
	open?: boolean
	setOpen?: (value: boolean) => void
	children: React.ReactNode
	closeOnClickOption?: boolean
	containerClassname?: string
}

export const Dropdown: React.FC<DropdownMenuProps> = ({
	sections,
	footerButtonLabel,
	shouldCloseAfterClick,
	onFooterButtonClick,
	className,
	children,
	itemClassName,
	open,
	setOpen,
	closeOnClickOption,
	containerClassname,
}) => {
	const [isOpen, setIsOpen] = useState(false)

	return (
		<ClickAwayListener
			onClickAway={() => {
				setOpen ? setOpen(false) : setIsOpen(false)
			}}
		>
			<div
				className={`relative ${
					isOpen || open ? "z-[999]" : ""
				}  ${containerClassname}`}
			>
				<div
					onClick={() => {
						setOpen ? setOpen(!open) : setIsOpen(!isOpen)
					}}
				>
					{children}
				</div>
				{isOpen && (
					<motion.div
						initial={{ opacity: 0, scale: 0.7 }}
						animate={{ opacity: 1, scale: 1 }}
						transition={{ ease: "easeOut", duration: 0.2 }}
					>
						<div
							className={`absolute mt-2 rounded bg-white shadow-custom z-[9999] shadow-[#00000024] ${className} `}
						>
							{sections.map((section, idx) => (
								<div key={idx}>
									{section.title && (
										<DropdownSectionTitle
											title={section.title}
											className={section.titleClassName}
										/>
									)}
									{section.items.map((item, index) => (
										<DropdownSectionItem
											closeOnClickOption={
												closeOnClickOption
											}
											key={index}
											item={item}
											index={index}
											shouldCloseAfterClick={
												shouldCloseAfterClick
											}
											itemClassName={itemClassName}
											setIsOpen={setIsOpen}
										/>
									))}
								</div>
							))}
							{footerButtonLabel && (
								<div className="flex items-start justify-center py-[4px]">
									<Button
										variant={ButtonVariant.Text}
										onClick={onFooterButtonClick}
										className="!text-cta-2"
									>
										{footerButtonLabel}
									</Button>
								</div>
							)}
						</div>
					</motion.div>
				)}
				{open && setOpen && (
					<motion.div
						initial={{ opacity: 0, scale: 0.7 }}
						animate={{ opacity: 1, scale: 1 }}
						transition={{ ease: "easeOut", duration: 0.2 }}
					>
						<div
							className={`absolute mt-2 rounded bg-white shadow-custom z-[9999] shadow-[#00000024] ${className} `}
						>
							{sections.map((section, idx) => (
								<div key={idx}>
									{section.title && (
										<DropdownSectionTitle
											title={section.title}
											className={section.titleClassName}
										/>
									)}
									{section.items.map((item, index) => (
										<DropdownSectionItem
											key={index}
											item={item}
											index={index}
											shouldCloseAfterClick={
												shouldCloseAfterClick
											}
											itemClassName={itemClassName}
											setIsOpen={setOpen}
										/>
									))}
								</div>
							))}
							{footerButtonLabel && (
								<div className="flex items-start justify-center py-[4px]">
									<Button
										variant={ButtonVariant.Text}
										onClick={onFooterButtonClick}
										className="!text-cta-2"
									>
										{footerButtonLabel}
									</Button>
								</div>
							)}
						</div>
					</motion.div>
				)}
			</div>
		</ClickAwayListener>
	)
}
