import { AxiosResponse } from "axios"
import axiosInstance from "axiosInstance"
import {
  RetrieveLegalDocumentResponse,
  UpdateLegalDocumentRequestBody,
  UpdateLegalDocumentResponse,
  PaginatedResponse,
  DefaultQueryParams,
  LegalDocumentGenerateContent,
  CreatePetitionRequestBody,
  LegalDocumentPropmts,
} from "types/legalDocumentV2"

const useLegalDocumentV2 = () => {
  const createLegalDocument = async (payload: CreatePetitionRequestBody) => {
    const response: AxiosResponse<RetrieveLegalDocumentResponse> =
      await axiosInstance.post("/v2/legal-document/", payload)

    return response.data
  }

  const editLegalDocument = async ({ id, payload }: {
    id: string
    payload: Partial<RetrieveLegalDocumentResponse>
  }) => {
    const response: AxiosResponse<RetrieveLegalDocumentResponse> =
      await axiosInstance.patch(`/v2/legal-document/${id}/`, payload)

    return response.data
  }

  const deleteLegalDocument = async (id: string) => {
    await axiosInstance.delete(`/v2/legal-document/${id}/`)
  }

  const retrieveLegalDocument = async (id: string) => {
    if (!id) throw new Error("No id provided")

    const response: AxiosResponse<RetrieveLegalDocumentResponse> =
      await axiosInstance.get(`/v2/legal-document/${id}/`)

    return response.data
  }

  const listLegalDocuments = async (params?: DefaultQueryParams) => {
    const response: AxiosResponse<
      PaginatedResponse<RetrieveLegalDocumentResponse>
    > = await axiosInstance.get("/v2/legal-document/", { params })

    return response.data.results
  }

  const restoreLegalDocument = async (id: string) => {
    const response: AxiosResponse<RetrieveLegalDocumentResponse> =
      await axiosInstance.post(`/v2/legal-document/${id}/restore/`)

    return response.data
  }

  const uploadDocument = async (payload: any) => {
    const response: AxiosResponse<any> = await axiosInstance.post(
      "/v2/legal-document/upload/",
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    )

    return response.data
  }

  const uploadFileToDocument = async ({ id, payload }: any) => {
    try {

      const response: AxiosResponse<any> = await axiosInstance.post(
        `/v2/legal-document/${id}/attach_document/`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      )

      return response.data
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  const deleteUploadedFile = async (id: string) => {
    await axiosInstance.delete(`/attached_documents/${id}/`)
  }

  const downloadFile = async (params: {
    id: string
    type: string
    name: string
  }) => {
    const response = await axiosInstance.get(
      `/v2/legal-document/${params.id}/download?doc_type=${params.type}`,
      {
        responseType: "blob",
      },
    )

    const replacedName = params.name.replace(/ /g, "-")
    const file = new Blob([response.data], {
      type: "application/octet-stream",
    })

    const fileURL = URL.createObjectURL(file)
    const link = document.createElement("a")
    link.href = fileURL
    link.setAttribute("download", `${replacedName}.${params.type}`)
    document.body.appendChild(link)
    link.click()

    if (link && link.parentNode) {
      link.parentNode.removeChild(link)
    }

    URL.revokeObjectURL(fileURL)
  }

  const updateLegalDocument = async ({
    id,
    payload,
  }: {
    id: string
    payload: UpdateLegalDocumentRequestBody
  }) => {
    const response: AxiosResponse<UpdateLegalDocumentResponse> =
      await axiosInstance.patch(`/v2/legal-document/${id}/`, payload)

    return response.data
  }

  const legalDocumentGenerateContent = async ({ id, payload }: {
    id: string
    payload: LegalDocumentGenerateContent
  }) => {
    const response: AxiosResponse<any> = await axiosInstance.post(
      `/v2/legal-document/${id}/generate_content/`, payload
    )

    return response.data
  }

  const copyLegalDocument = async (id: string) => {
    const response: AxiosResponse<RetrieveLegalDocumentResponse> =
      await axiosInstance.post(`/v2/legal-document/${id}/create_copy/`)

    return response.data
  }

  const legalDocumentPropmts = async () => {
    const response: AxiosResponse<PaginatedResponse<LegalDocumentPropmts>> = await axiosInstance.get(
      "/v2/legal-document-prompts/"
    )
    const otherOption = response.data.results.find((option) => option.document_name === "Outros")
    const sortedOptions = response.data.results.sort((a, b) => a.document_name.localeCompare(b.document_name)).filter((option) => option.id !== otherOption?.id)
    return [...sortedOptions, otherOption] as LegalDocumentPropmts[]
  }

  const updateLegalDocumentText = async ({
    id,
    payload,
  }: {
    id: string
    payload: UpdateLegalDocumentRequestBody
  }) => {
    const response: AxiosResponse<UpdateLegalDocumentResponse> =
      await axiosInstance.patch(`/v2/legal-document/${id}/update_document/`, payload)

    return response.data
  }

  const uploadMainAttachment = async ({ id, payload }: any) => {
    try {
      const response: AxiosResponse<any> = await axiosInstance.post(
        `/v2/legal-document/${id}/attach_main_document/`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      )

      return response.data
    }
    catch (e) {
      console.log(e);
      throw e;
    }
  }
  const uploadOptionalAttachment = async ({ id, payload }: any) => {
    try {
      const response: AxiosResponse<any> = await axiosInstance.post(
        `/v2/legal-document/${id}/attach_optional_documents/`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      )

      return response.data
    }
    catch (e) {
      console.log(e);
      throw e;
    }
  }

  return {
    createLegalDocument,
    retrieveLegalDocument,
    listLegalDocuments,
    deleteLegalDocument,
    restoreLegalDocument,
    uploadDocument,
    uploadFileToDocument,
    downloadFile,
    updateLegalDocument,
    legalDocumentGenerateContent,
    copyLegalDocument,
    legalDocumentPropmts,
    updateLegalDocumentText,
    editLegalDocument,
    deleteUploadedFile,
    uploadMainAttachment,
    uploadOptionalAttachment
  }
}

export default useLegalDocumentV2
