import Flex from "components/flex"
import LoadingSpinner from "pages/MyDocumentsPage/subcomponents/LoadingSpinner"
import { TbFileInvoice, TbTrash } from "react-icons/tb"
import { OptionalUpload } from ".."

interface UploadCardProps {
	file: OptionalUpload
	handleRemoveOptionalUpload: (id: string) => void
}

export default function UploadCard({
	file,
	handleRemoveOptionalUpload,
}: UploadCardProps) {
	return (
		<Flex className="p-2 gap-2  w-[182px] border border-[#F0F0F0] rounded-[8px] group relative h-[56px]">
			<div className="rounded-[4px] bg-[#EEF6FF] w-[28px] h-[28px] flex items-center justify-center">
				{file.status === "loading" ? (
					<LoadingSpinner className="!w-[14px] !h-[14px]" />
				) : (
					<TbFileInvoice size="20px" color="#3083FF" />
				)}
			</div>
			<div>
				<p className="text-cta-2 font-semibold truncate max-w-[125px] group-hover:max-w-[105px]">
					{file.file.name}
				</p>
				<p className="text-small-1 text-[#6D6D6D]">
					{((file?.file.size || file?.fileSize || 0) / 1024).toFixed(
						2,
					)}{" "}
					KB
				</p>
			</div>
			<div
				className="p-1 rounded-[4px] border border-[#F0F0F0] bg-white cursor-pointer absolute top-[17px] right-2 group-hover:opacity-100 hover:bg-[#F0F0F0] opacity-0"
				onClick={() => handleRemoveOptionalUpload(file?.id || "")}
			>
				<TbTrash color="#6D6D6D" />
			</div>
		</Flex>
	)
}
