export default function CircleIcon1() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="360"
			height="360"
			viewBox="0 0 360 360"
			fill="none"
		>
			<g filter="url(#filter0_f_3_884)">
				<path
					d="M270 180C270 229.706 229.706 270 180 270C130.294 270 90 229.706 90 180C90 130.294 130.294 90 180 90C229.706 90 270 130.294 270 180Z"
					fill="#AE30FC"
					fillOpacity="0.05"
				/>
			</g>
			<defs>
				<filter
					id="filter0_f_3_884"
					x="0"
					y="0"
					width="360"
					height="360"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feGaussianBlur
						stdDeviation="45"
						result="effect1_foregroundBlur_3_884"
					/>
				</filter>
			</defs>
		</svg>
	)
}
