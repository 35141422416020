import PlanName from "components/plan-name"
import { CurrentUser } from "types/currentUser"
import { formatUserName } from "utils/profile"
import { TbSelector } from "react-icons/tb"

const Profile = ({
	user,
	openProfileMenu,
	sidebarIsOpen,
}: {
	user: CurrentUser
	openProfileMenu: boolean
	sidebarIsOpen: boolean
}) => {
	return (
		<div
			className={`${
				openProfileMenu && "bg-ice-white"
			} w-full flex h-[48px] items-center gap-2 rounded px-[6px] py-[12px] hover:bg-[#F5F5F5] !font-Red-Hat-Display`}
		>
			<img
				src={user?.picture}
				height={32}
				width={32}
				className="rounded-full "
				referrerPolicy="no-referrer"
			/>

			<div
				className={`duration-150 ease-linear flex items-center gap-2 ${
					sidebarIsOpen ? "ml-0 opacity-100" : " ml-[-8px] opacity-0"
				}`}
			>
				<nav>
					<div className={`overflow-hidden  w-[100px]`}>
						<p className="max-w-[120px] overflow-hidden text-ellipsis whitespace-nowrap text-sm font-semibold">
							{formatUserName(user)}
						</p>
						<PlanName
							name={user.subscription?.product_name || ""}
							className="text-small-1 leading-[14px]  !font-normal"
						/>
					</div>
				</nav>

				<div className={`${openProfileMenu && "rotate-180"} ml-[18px]`}>
					<TbSelector size="16px" color="#6D6D6D" />
				</div>
			</div>
		</div>
	)
}

export default Profile
