export const defaultPromptRanges = [
	{
		nodeId: "",
		llm: "gpt-4o-mini-2024-07-18",
		promptRanges: [
			{
				title: "Temperatura",
				max: 2,
				value: 1,
				id: "temperature",
			},
			{
				title: "Comprimento máximo",
				max: 200000,
				value: 2048,
				id: "max_tokens",
			},
			{
				title: "Top P",
				max: 1,
				value: 1,
				id: "top_p",
			},
			{
				title: "Penalidade por Repetição",
				max: 2,
				value: 0,
				id: "frequence_penalty",
			},
			{
				title: "Penalidade por Presença",
				max: 2,
				value: 0,
				id: "presence_penalty",
			},
		],
	},
]

export const defaultRetrievalRanges = [
	{
		nodeId: "",
		retrievalRanges: [
			{
				title: "Proximidade",
				max: 1,
				value: 0.8,
				id: "similarity_score",
			},
			{
				title: "Número de resultados",
				max: 100,
				value: 3,
				id: "top_k",
			},
		],
	},
]

export const defaultInputVariables = [
	{
		name: "detalhes_do_caso",
		type: "TEXT",
		is_global: false,
	},
	{
		name: "upload_opcional",
		type: "FILES",
		is_global: false,
	},
	{
		name: "tom_escrita",
		type: "TEXT",
		is_global: false,
	},
	{
		name: "tribunais",
		type: "COURTS",
		is_global: false,
	},
	{
		name: "main_upload",
		type: "FILES",
		is_global: false,
	},
]

// Tribunais Regionais Federais
export const TRFs = {
	"Tribunal Regional Federal da 1ª Região": "TRF1",
	"Tribunal Regional Federal da 2ª Região": "TRF2",
	"Tribunal Regional Federal da 3ª Região": "TRF3",
	"Tribunal Regional Federal da 4ª Região": "TRF4",
	"Tribunal Regional Federal da 5ª Região": "TRF5",
	"Tribunal Regional Federal da 6ª Região": "TRF6",
}

// Tribunais Regionais do Trabalho
export const TRTs = {
	"Tribunal Regional do Trabalho da 1ª Região": "TRT1",
	"Tribunal Regional do Trabalho da 2ª Região": "TRT2",
	"Tribunal Regional do Trabalho da 3ª Região": "TRT3",
	"Tribunal Regional do Trabalho da 4ª Região": "TRT4",
	"Tribunal Regional do Trabalho da 5ª Região": "TRT5",
	"Tribunal Regional do Trabalho da 6ª Região": "TRT6",
	"Tribunal Regional do Trabalho da 7ª Região": "TRT7",
	"Tribunal Regional do Trabalho da 8ª Região": "TRT8",
	"Tribunal Regional do Trabalho da 9ª Região": "TRT9",
	"Tribunal Regional do Trabalho da 10ª Região": "TRT10",
	"Tribunal Regional do Trabalho da 11ª Região": "TRT11",
	"Tribunal Regional do Trabalho da 12ª Região": "TRT12",
	"Tribunal Regional do Trabalho da 13ª Região": "TRT13",
	"Tribunal Regional do Trabalho da 14ª Região": "TRT14",
	"Tribunal Regional do Trabalho da 15ª Região": "TRT15",
	"Tribunal Regional do Trabalho da 16ª Região": "TRT16",
	"Tribunal Regional do Trabalho da 17ª Região": "TRT17",
	"Tribunal Regional do Trabalho da 18ª Região": "TRT18",
	"Tribunal Regional do Trabalho da 19ª Região": "TRT19",
}

// Tribunais de Justiça Estaduais e do DF
export const TJs = {
	"Tribunal de Justiça do Acre": "TJAC",
	"Tribunal de Justiça de Alagoas": "TJAL",
	"Tribunal de Justiça do Amapá": "TJAP",
	"Tribunal de Justiça do Amazonas": "TJAM",
	"Tribunal de Justiça da Bahia": "TJBA",
	"Tribunal de Justiça do Ceará": "TJCE",
	"Tribunal de Justiça do Distrito Federal e Territórios": "TJDFT",
	"Tribunal de Justiça do Espírito Santo": "TJES",
	"Tribunal de Justiça de Goiás": "TJGO",
	"Tribunal de Justiça do Maranhão": "TJMA",
	"Tribunal de Justiça de Mato Grosso": "TJMT",
	"Tribunal de Justiça de Mato Grosso do Sul": "TJMS",
	"Tribunal de Justiça de Minas Gerais": "TJMG",
	"Tribunal de Justiça do Pará": "TJPA",
	"Tribunal de Justiça da Paraíba": "TJPB",
	"Tribunal de Justiça do Paraná": "TJPR",
	"Tribunal de Justiça de Pernambuco": "TJPE",
	"Tribunal de Justiça do Piauí": "TJPI",
	"Tribunal de Justiça do Rio de Janeiro": "TJRJ",
	"Tribunal de Justiça do Rio Grande do Norte": "TJRN",
	"Tribunal de Justiça do Rio Grande do Sul": "TJRS",
	"Tribunal de Justiça de Rondônia": "TJRO",
	"Tribunal de Justiça de Roraima": "TJRR",
	"Tribunal de Justiça de Santa Catarina": "TJSC",
	"Tribunal de Justiça de Sergipe": "TJSE",
	"Tribunal de Justiça de São Paulo": "TJSP",
	"Tribunal de Justiça do Tocantins": "TJTO",
}

export const otherCourts = {
	"Supremo Tribunal Federal": "STF",
	"Conselho Nacional de Justiça": "CNJ",
	"Superior Tribunal de Justiça": "STJ",
}
