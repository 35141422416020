import { Modal as FlowModal } from "flowbite-react"
import CloseIcon from "icons/CloseIcon"
import { motion } from "framer-motion"
import { TbX } from "react-icons/tb"
export interface BaseModalProps extends React.ComponentProps<typeof FlowModal> {
	children: React.ReactNode
	openModal: boolean
	setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
	title?: string
	subtitle?: string
	width?: string
	onlyCloseButton?: boolean
	whitoutCloseButton?: boolean
	className?: string
	titleClassName?: string
	newButtonClose?: boolean
	dimissible?: boolean
}

const BaseModal = ({
	children,
	openModal,
	setOpenModal,
	title,
	subtitle,
	width,
	onlyCloseButton,
	whitoutCloseButton,
	className,
	titleClassName,
	newButtonClose,
	dismissible = true,
	...props
}: BaseModalProps) => {
	return (
		<FlowModal
			show={openModal}
			onClose={() => setOpenModal(false)}
			dismissible={dismissible}
			className={`focus-visible:!outline-none ${className} !w-auto `}
			{...props}
			theme={{
				content: {
					inner: "shadow-none flex items-center justify-center",
				},
			}}
		>
			<motion.div
				initial={{ opacity: 0, scale: 0.7 }}
				animate={{ opacity: 1, scale: 1 }}
				transition={{ ease: "easeOut", duration: 0.2 }}
				className="flex items-center justify-center"
			>
				<FlowModal.Body
					className={`rounded-lg bg-[white] p-[24px] ${
						onlyCloseButton && !whitoutCloseButton ? "relative" : ""
					} ${width} focus-visible:!outline-none `}
				>
					{title && (
						<div className="mb-2 flex items-center justify-between relative">
							<h3
								className={`font-semibold text-[20px] text-[#3D3D3D]  ${titleClassName}`}
							>
								{title}
							</h3>
							{!whitoutCloseButton && (
								<>
									{newButtonClose ? (
										<div
											className="p-2 hover:bg-[#F0F0F0] rounded-[8px] cursor-pointer"
											onClick={() => setOpenModal(false)}
										>
											<TbX size="20px" color="#6D6D6D" />
										</div>
									) : (
										<div
											className="absolute top-[-5px] cursor-pointer right-0"
											onClick={() => setOpenModal(false)}
										>
											<CloseIcon size="24px" />
										</div>
									)}
								</>
							)}
						</div>
					)}

					{onlyCloseButton && !whitoutCloseButton && (
						<div
							className="absolute cursor-pointer top-6 right-6"
							onClick={() => setOpenModal(false)}
						>
							{newButtonClose ? (
								<div className="p-2 hover:bg-[#F0F0F0] rounded-[8px]">
									<TbX size="20px" color="#6D6D6D" />
								</div>
							) : (
								<CloseIcon size="24px" />
							)}
						</div>
					)}

					{subtitle && (
						<div className="mb-8">
							<p className="text-cta-1 text-[#606060]">
								{subtitle}
							</p>
						</div>
					)}

					{children}
				</FlowModal.Body>
			</motion.div>
		</FlowModal>
	)
}

const Modal = ({
	openModal,
	setOpenModal,
	children,
	title,
	subtitle,
	width,
	onlyCloseButton,
	className,
	titleClassName,
	newButtonClose,
	...props
}: BaseModalProps) => {
	return (
		<BaseModal
			openModal={openModal}
			setOpenModal={setOpenModal}
			title={title}
			subtitle={subtitle}
			width={width}
			onlyCloseButton={onlyCloseButton}
			className={className}
			titleClassName={titleClassName}
			newButtonClose={newButtonClose}
			{...props}
		>
			{children}
		</BaseModal>
	)
}

export default Modal
