import PlanName from "components/plan-name"
import UpgradeButton from "components/upgrade-button"
import useCurrentUser from "hooks/useCurrentUser"
import NewCreditIcon from "icons/NewCreditIcon"
import NewCreditIcon2 from "icons/NewCreditIcon2"
import { useRef } from "react"
import { CurrentUser } from "types/currentUser"

const CreditsInfo = ({
	show,
	productName,
	user,
	containerRef,
	onMouseEnter,
	onMouseLeave,
}: {
	show: boolean
	productName?: string
	user?: CurrentUser
	containerRef: React.RefObject<HTMLDivElement>
	onMouseEnter: () => void
	onMouseLeave: (event: React.MouseEvent) => void
}) => {
	return (
		<div
			className={`w-fit h-fit bg-brand-white-2 fixed bottom-[124px] left-[14px] rounded shadow-outlined-hover z-50 p-4 ${
				show ? "block" : "hidden"
			}`}
			ref={containerRef}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<PlanName
				name={productName || ""}
				className="text-cta-1 leading-[20px]"
			/>

			<div className="flex gap-2 items-center mt-4">
				<div>
					<NewCreditIcon2 />
				</div>
				<p className="leading-5 text-cta-2 font-semibold text-[#6D6D6D]">
					<span className="font-bold text-[#3D3D3D] ">
						{user?.credits_remaining
							? Math.ceil(user.credits_remaining)
							: 0}
						/{user?.subscription?.credits}
					</span>{" "}
					créditos disponíveis
				</p>
			</div>

			{user?.extra_credits && user?.extra_credits > 0 ? (
				<div className="flex gap-2 items-center mt-4 text-cta-2">
					<NewCreditIcon2 />
					<p className="font-semibold text-[#6D6D6D]">
						<span className=" font-bold text-[#3D3D3D]">
							{Math.ceil(user.extra_credits)}
						</span>{" "}
						créditos extras
					</p>
				</div>
			) : null}

			<UpgradeButton
				isBig
				className={"mt-4 w-full justify-center h-[38px]"}
				text={"Fazer upgrade"}
			/>
		</div>
	)
}

export default function Credits({
	sidebarIsOpen,
	isHovered,
	setIsHovered,
}: {
	sidebarIsOpen: boolean
	isHovered: boolean
	setIsHovered: (value: boolean) => void
}) {
	const user = useCurrentUser()

	const containerRef = useRef<HTMLDivElement>(null)
	const creditsInfoRef = useRef<HTMLDivElement>(null)
	const spaceRef = useRef<HTMLDivElement>(null)
	const extraCredits = user?.extra_credits ? user?.extra_credits.toFixed() : 0
	const handleMouseEnter = () => {
		setIsHovered(true)
	}
	const productName = user?.subscription?.product_name

	const handleMouseLeave = (event: React.MouseEvent) => {
		if (
			containerRef.current &&
			creditsInfoRef.current &&
			spaceRef.current &&
			!containerRef.current.contains(event.relatedTarget as Node) &&
			!creditsInfoRef.current.contains(event.relatedTarget as Node) &&
			!spaceRef.current.contains(event.relatedTarget as Node)
		) {
			setIsHovered(false)
		}
	}
	return (
		<div
			className={`${sidebarIsOpen ? "w-full" : "w-[44px]"} mb-2`}
			ref={containerRef}
			//funcao de abrir poppover removida por enquanto
			// onMouseEnter={handleMouseEnter}
			// onMouseLeave={handleMouseLeave}
		>
			{isHovered && (
				<div
					className="h-2 w-full"
					ref={spaceRef}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
				/>
			)}
			<CreditsInfo
				show={isHovered}
				productName={productName}
				user={user}
				containerRef={creditsInfoRef}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			/>
			<div
				className={`relative duration-150 ease-linear  overflow-hidden   py-3 bg-[#F2F6FB] rounded-lg ${
					sidebarIsOpen
						? "flex items-center justify-between px-3"
						: " flex flex-col items-center"
				} `}
			>
				<div className="text-dark-blue-1">
					<NewCreditIcon />
				</div>

				<p
					className={`duration-150 ease-linear  z-0 overflow-hidden text-small-1 text-[#6D6D6D] absolute bottom-[14px]  ${
						sidebarIsOpen
							? "left-[36px] opacity-100"
							: "!left-[10px] opacity-0 "
					}`}
				>
					Créditos
				</p>

				<div
					className={`duration-150 ease-linear  ${
						sidebarIsOpen ? "mt-0" : "mt-3"
					}`}
				>
					<p className="text-cta-2 font-bold text-center bg-[#F2F6FB]">
						{user?.credits_remaining || user?.extra_credits
							? Math.ceil(user?.credits_remaining) +
							  Math.ceil(Number(extraCredits))
							: 0}
					</p>
				</div>
			</div>
		</div>
	)
}
