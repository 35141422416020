import DotsIcon from "icons/DotsIcon"
import PromptTag from "../PromptTag"
import { PromptColumnHeader } from "types/prompts"
import Center from "components/center"
import { LuClock7 } from "react-icons/lu"
import { TbCurrencyDollar } from "react-icons/tb"
import { Graph } from "types/agentBuilder"
import { useNavigate } from "react-router-dom"
import { formatExecutionTime } from "utils/formatExecutionTime"
import { formatCost } from "utils/formatCost"
import { Dropdown } from "components/dropdown"
import AutosizeInput from "react-input-autosize"
import { RefObject, useEffect, useRef, useState } from "react"
import useAgentBuilder from "hooks/useAgentBuilder"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { format } from "date-fns"
import { useAtom } from "jotai"
import { graphExecutionAtom } from "pages/GraphPage/atoms"
import { initialGraphExecution } from "pages/GraphPage/hooks/useNodeExecutionStatus"

export default function Prompts({
	columnHeader,
	graph,
	setGraphToDelete,
}: {
	columnHeader: PromptColumnHeader[]
	graph: Graph
	setGraphToDelete: (graph: Graph | false) => void
}) {
	const navigate = useNavigate()
	const [inputTitle, setInputTitle] = useState(graph?.name || "")
	const [isEdit, setIsEdit] = useState(false)
	const queryClient = useQueryClient()
	const inputRef: RefObject<AutosizeInput> &
		(string | RefObject<HTMLInputElement>) = useRef(null)
	const [, setGraphExecution] = useAtom(graphExecutionAtom)
	const { editGraph, createCopy } = useAgentBuilder()

	const editGraphMutation = useMutation({
		mutationFn: editGraph,
	})
	const createCopyMutation = useMutation({
		mutationFn: createCopy,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["listGraphs"],
			})
		},
	})

	const onSaveTitle = async () => {
		editGraphMutation.mutateAsync({
			id: graph.id,
			name: inputTitle,
		})
		setIsEdit(false)
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			event.preventDefault()
			onSaveTitle()
		}
	}

	useEffect(() => {
		if (isEdit && inputRef.current) {
			inputRef.current.select()
		}
	}, [isEdit])

	const sections = [
		{
			items: [
				{
					label: "Renomear",
					onClick: () => {
						setIsEdit(true)
					},
				},
				{
					label: "Duplicar",
					onClick: () => {
						createCopyMutation.mutate(graph.id)
					},
				},
				{
					label: "Excluir",
					deleteLabel: true,
					onClick: () => {
						setGraphToDelete(graph)
					},
				},
			],
		},
	]

	const lastPublication = graph.last_publication
		? format(new Date(graph.last_publication), "dd/MM/yyyy - HH:mm")
		: "-"

	return (
		<div
			className="relative rounded bg-white shadow-1 hover:bg-[#FCFCFC] cursor-pointer"
			onClick={() => {
				setGraphExecution(initialGraphExecution)
				navigate(`/graph/${graph.id}`)
			}}
		>
			<div className="p-4 flex items-center w-full ">
				<div className={`flex flex-col gap-2 ${columnHeader[0].width}`}>
					{isEdit ? (
						<AutosizeInput
							inputClassName="text-dark-blue-1 p-0 m-0 text-cta-1 font-normal border-none bg-transparent  focus-visible:!outline-none max-w-[calc(100vw-600px)] overflow-hidden text-ellipsis whitespace-nowrap"
							value={inputTitle || ""}
							onChange={(event) =>
								setInputTitle(event.target.value)
							}
							onBlur={onSaveTitle}
							autoFocus
							ref={inputRef}
							onKeyDown={handleKeyDown}
						/>
					) : (
						<p>{inputTitle || graph.name || "Novo agente"}</p>
					)}

					<p className="text-small-1 text-brand-gray-2">
						Criado por: Arthur Penni
					</p>
				</div>

				<div className={`${columnHeader[1].width}`}>
					<PromptTag graph={graph} />
				</div>

				<div className={`${columnHeader[2].width}`}>
					<p className="text-cta-2">
						{format(
							new Date(graph._updated_at),
							"dd/MM/yyyy - HH:mm",
						)}
					</p>
				</div>

				<div className={`${columnHeader[3].width}`}>
					<p className="text-cta-2">{lastPublication}</p>
				</div>

				<div className={`${columnHeader[4].width}`}>
					<div className="flex gap-2">
						<Center className="rounded p-1 gap-1  border-[1px] border-[#ECF5FF] bg-[#F5F5F5]">
							<LuClock7 size="16px" />
							<p className="font-semibold text-small-1">
								{formatExecutionTime(
									graph?.average_execution_time,
								)}
							</p>
						</Center>

						<Center className="rounded p-1 gap-1  border-[1px] border-[#ECF5FF] bg-[#F5F5F5]">
							<TbCurrencyDollar size="16px" />
							<p className="font-semibold text-small-1">
								{formatCost(graph?.average_cost)}
							</p>
						</Center>
					</div>
				</div>

				<div
					className={`${columnHeader[5].width} flex items-center justify-end gap-4 `}
					onClick={(e) => e.stopPropagation()}
				>
					<Dropdown sections={sections} className="right-0 w-[165px]">
						<div className="fill-dark-blue-1 hover:fill-dark-blue-2 cursor-pointer">
							<DotsIcon className="fill-dark-blue-1 hover:fill-dark-blue-2" />
						</div>
					</Dropdown>
				</div>
			</div>
		</div>
	)
}
