import {
	RetrieveLegalDocumentResponse,
	LDProcess,
	ProcessType,
} from "types/legalDocument"

export function getOldDocumentStatus(document?: RetrieveLegalDocumentResponse) {
	if (!document) return "START"
	if (document.type === "uploaded_document") return "SUCCESS"

	const processes = document?.processes

	if (!processes || !Array.isArray(processes)) {
		console.error(`Invalid processes type "${typeof processes}" `, document)
		return "START"
	}

	const process = processes?.at(-1)

	if (!process) return "START"

	const { process_type } = process

	const processState = legalDocumentProcessState(process)

	if (processState.isError) return "ERROR"

	if (processState.isProcessing) {
		return "START"
	}

	if (processState.isCompleted) {
		if (
			processes
				?.map((process) => process.process_type)
				?.includes(ProcessType.GenerateContent)
		) {
			return "SUCCESS"
		}

		if ([ProcessType.GenerateContent].includes(process_type)) {
			return "SUCCESS"
		}
	}

	return "START"
}

const legalDocumentProcessState = (process: LDProcess) => {
	const { start_time, end_time, max_processing_time, error } = process

	const max_time = new Date(
		Date.parse(start_time) + max_processing_time * 1000,
	)

	const current_time = new Date()

	const isExpired = !end_time && max_time < current_time
	const isError = error || isExpired
	const isProcessing = !isError && !end_time
	const isCompleted = end_time && !isError

	return {
		isError: isError,
		isProcessing: isProcessing,
		isExpired: isExpired,
		isCompleted: isCompleted,
	}
}
