import Button from "components/button-v2"
import Center from "components/center"
import { areaIcons } from "pages/FlowBuilderPage/constants"
import LoadingSpinner from "pages/MyDocumentsPage/subcomponents/LoadingSpinner"
import { useDropzone } from "react-dropzone/."
import { TbUpload } from "react-icons/tb"
import { Flow } from "types/flowBuilder"

interface StepTwoProps {
	mainFile: ReturnType<typeof useDropzone>
	selectedFlow: Flow | null
	onSkipStep: () => void
	isLoading: boolean
}

const AttachInitialStep = ({
	mainFile,
	selectedFlow,
	onSkipStep,
	isLoading,
}: StepTwoProps) => {
	return (
		<Center className="w-full h-[calc(100vh-24px)] !font-Red-Hat-Display flex-col bg-[#FCFCFC] ">
			<p className="text-h2 text-[#3D3D3D] font-semibold">
				{selectedFlow?.attachment_title}
			</p>
			<div className="w-full max-w-[600px] h-[286px] my-6">
				<div
					{...mainFile.getRootProps()}
					className={`text-[#B0B0B0] border border-dashed border-[#B0B0B0] rounded-[16px] p-8 text-center transition-colors bg-[#FFF] hover:bg-[#F5F5F5] cursor-pointer h-[300px] ${
						mainFile.isDragActive ? "#F5F5F5" : ""
					}`}
				>
					{isLoading ? (
						<Center className="h-full">
							<LoadingSpinner className="w-[40px] h-[40px]" />
						</Center>
					) : (
						<>
							<input {...mainFile.getInputProps()} />
							<Center>
								<img
									src="https://lawdeck-static.s3.sa-east-1.amazonaws.com/docs.png"
									className="h-[82px] w-[82px]"
								/>
							</Center>
							<p className="my-2">
								Clique ou arraste aqui para{" "}
								<span className="text-[#3D3D3D] font-semibold">
									anexar o arquivo
								</span>
							</p>
							<p className="text-cta-2">
								PDF, DOCX, TXT até 15 MB
							</p>
							<Center className="mt-8">
								<Button
									variant="contained"
									icon={<TbUpload size="24px" />}
								>
									Escolher arquivo
								</Button>
							</Center>
						</>
					)}
				</div>
			</div>
			<Center className="mt-6">
				<p className="text-[#B0B0B0]">
					Não tem o arquivo?{" "}
					<span
						className={`text-[#3083FF] font-semibold cursor-pointer ${
							isLoading
								? "pointer-events-none text-[#B0B0B0]"
								: ""
						}`}
						onClick={() => {
							if (isLoading) return
							onSkipStep()
						}}
					>
						Pular esta etapa.
					</span>
				</p>
			</Center>
			{selectedFlow && (
				<Center className="gap-2 justify-center mt-4">
					<div className="w-[20px] h-[20px]">
						{areaIcons[selectedFlow.area_of_law]}
					</div>

					<p className="text-cta-2 font-semibold text-[#6D6D6D]">
						{selectedFlow.name}
					</p>
				</Center>
			)}
		</Center>
	)
}

export default AttachInitialStep
