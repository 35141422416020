import { useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { useAtom } from "jotai"
import { useEffect, useState } from "react"
import Loading from "components/loading"
import { getDocumentStatus } from "utils/getDocumentStatus"
import {
	openLexAtom,
	showLexAtom,
} from "pages/SidebarPages/subcomponents/atoms"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import Error404Page from "pages/Error404"

import DocumentWrapper from "./subcomponents/DocumentWrapper"
import { useFeatureFlag } from "hooks/useFeatureFlag"
import FEATURE_FLAGS from "feature-flags"
import NewDocumentPage from "pages/NewDocumentPage"

const DocumentPage = () => {
	const { documentId } = useParams()
	const [, setShowLex] = useAtom(showLexAtom)
	const { data: newDocumentsFlow } = useFeatureFlag(
		FEATURE_FLAGS.newDocumentsFlow,
	)
	if (!documentId) {
		return <div>Documento não encontrado</div>
	}
	const [, setOpenLex] = useAtom(openLexAtom)
	const [enableFetch, setEnableFetch] = useState(true)
	const { retrieveLegalDocument } = useLegalDocumentAPI()

	const { data, isError } = useQuery(
		["legalDocument", documentId],
		() => retrieveLegalDocument(documentId),
		{ retry: 1, enabled: enableFetch },
	)

	useEffect(() => {
		if (isError) {
			setEnableFetch(false)
			return
		}
		if (!data) return

		if (
			getDocumentStatus(data) === "completed" ||
			data.type === "uploaded_document"
		) {
			setShowLex(true)
		} else {
			setShowLex(false)
		}
	}, [data, getDocumentStatus(data), isError])

	useEffect(() => {
		setOpenLex(true)
	}, [])

	if (newDocumentsFlow) {
		return <NewDocumentPage />
	}
	if (!data && !isError) return <Loading />

	if (isError) {
		return <Error404Page />
	}

	return <DocumentWrapper documentId={documentId} />
}

export default DocumentPage
