import { useState, useEffect } from "react"
import Modal from "components/modal"
import { otherCourts, TJs, TRFs, TRTs } from "../constants"
import { IoIosArrowUp } from "react-icons/io"
import Center from "components/center"
import Button from "components/button-v2"
import { ButtonVariant } from "components/button/types"
import { useAtom } from "jotai"
import { courtAtom } from "../atoms"

interface CheckboxLabelProps {
	label: string
	value: string
	checked: boolean
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

function CheckboxLabel({
	label,
	value,
	checked,
	onChange,
}: CheckboxLabelProps) {
	return (
		<div className="flex items-center py-2 gap-2">
			<input
				type="checkbox"
				value={value}
				checked={checked}
				onChange={onChange}
				className="rounded-sm focus:shadow-none focus:ring-0 focus:outline-none"
			/>
			<p>{label}</p>
		</div>
	)
}

export default function CourtModal({
	open,
	setOpen,
}: {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
	const [groupsVisibility, setGroupsVisibility] = useState({
		TJs: true,
		TRTs: true,
		TRFs: true,
	})
	const [selectedCourts, setSelectedCourts] = useAtom(courtAtom)
	const [tempSelectedCourts, setTempSelectedCourts] =
		useState<string[]>(selectedCourts)

	useEffect(() => {
		if (open) {
			setTempSelectedCourts(selectedCourts)
		}
	}, [open, selectedCourts])

	const toggleGroup = (group: "TJs" | "TRTs" | "TRFs") => {
		setGroupsVisibility((prev) => ({
			...prev,
			[group]: !prev[group],
		}))
	}

	const handleCheckbox = (
		e: React.ChangeEvent<HTMLInputElement>,
		value: string,
	) => {
		setTempSelectedCourts((prev) =>
			e.target.checked
				? [...prev, value]
				: prev.filter((court) => court !== value),
		)
	}

	const handleGroupCheckbox = (
		e: React.ChangeEvent<HTMLInputElement>,
		groupValues: string[],
	) => {
		setTempSelectedCourts((prev) => {
			if (e.target.checked) {
				const novosValores = groupValues.filter(
					(val) => !prev.includes(val),
				)
				return [...prev, ...novosValores]
			} else {
				return prev.filter((val) => !groupValues.includes(val))
			}
		})
	}

	const allCourts = [
		...Object.values(otherCourts),
		...Object.values(TJs),
		...Object.values(TRTs),
		...Object.values(TRFs),
	]

	const allSelected = allCourts.every((court) =>
		tempSelectedCourts.includes(court),
	)

	const tjsValues = Object.values(TJs)
	const tjsAllSelected = tjsValues.every((val) =>
		tempSelectedCourts.includes(val),
	)

	const trtsValues = Object.values(TRTs)
	const trtsAllSelected = trtsValues.every((val) =>
		tempSelectedCourts.includes(val),
	)

	const trfsValues = Object.values(TRFs)
	const trfsAllSelected = trfsValues.every((val) =>
		tempSelectedCourts.includes(val),
	)

	return (
		<Modal
			openModal={open}
			setOpenModal={setOpen}
			title="Escolher tribunais"
			width="w-[500px] p-8"
			titleClassName="!text-[20px] text-[#3D3D3D]"
		>
			<div className="mt-6 text-[14px] text-[#6D6D6D] max-h-[600px] overflow-y-auto">
				<CheckboxLabel
					label="Todos"
					value="todos"
					checked={allSelected}
					onChange={(e) => {
						if (e.target.checked) {
							setTempSelectedCourts(allCourts)
						} else {
							setTempSelectedCourts([])
						}
					}}
				/>
				<div className="ml-[36px]">
					{Object.entries(otherCourts).map(([key, value]) => (
						<CheckboxLabel
							key={key}
							label={`${key} (${value})`}
							value={value}
							checked={tempSelectedCourts.includes(value)}
							onChange={(e) => handleCheckbox(e, value)}
						/>
					))}
					<div className="flex items-center py-2 gap-2 relative">
						<div
							className="cursor-pointer absolute top-1/5 left-[-20px]"
							onClick={() => toggleGroup("TJs")}
						>
							<IoIosArrowUp
								className={`text-[#6D6D6D] transform transition-transform duration-200 ${
									groupsVisibility.TJs ? "" : "rotate-180"
								}`}
							/>
						</div>
						<CheckboxLabel
							label="Tribunais de Justiça (TJ)"
							value="tjs-group"
							checked={tjsAllSelected}
							onChange={(e) => handleGroupCheckbox(e, tjsValues)}
						/>
					</div>
					{groupsVisibility.TJs &&
						Object.entries(TJs).map(([key, value]) => (
							<CheckboxLabel
								key={key}
								label={`${key} (${value})`}
								value={value}
								checked={tempSelectedCourts.includes(value)}
								onChange={(e) => handleCheckbox(e, value)}
							/>
						))}
					<div className="flex items-center py-2 gap-2 relative">
						<div
							className="cursor-pointer absolute top-1/5 left-[-20px]"
							onClick={() => toggleGroup("TRTs")}
						>
							<IoIosArrowUp
								className={`text-[#6D6D6D] transform transition-transform duration-200 ${
									groupsVisibility.TRTs ? "" : "rotate-180"
								}`}
							/>
						</div>
						<CheckboxLabel
							label="Tribunais Regionais do Trabalho (TRT)"
							value="trts-group"
							checked={trtsAllSelected}
							onChange={(e) => handleGroupCheckbox(e, trtsValues)}
						/>
					</div>
					{groupsVisibility.TRTs &&
						Object.entries(TRTs).map(([key, value]) => (
							<CheckboxLabel
								key={key}
								label={`${key} (${value})`}
								value={value}
								checked={tempSelectedCourts.includes(value)}
								onChange={(e) => handleCheckbox(e, value)}
							/>
						))}
					<div className="flex items-center py-2 gap-2 relative">
						<div
							className="cursor-pointer absolute top-1/5 left-[-20px]"
							onClick={() => toggleGroup("TRFs")}
						>
							<IoIosArrowUp
								className={`text-[#6D6D6D] transform transition-transform duration-200 ${
									groupsVisibility.TRFs ? "" : "rotate-180"
								}`}
							/>
						</div>
						<CheckboxLabel
							label="Tribunais Regionais Federais (TRF)"
							value="trfs-group"
							checked={trfsAllSelected}
							onChange={(e) => handleGroupCheckbox(e, trfsValues)}
						/>
					</div>
					{groupsVisibility.TRFs &&
						Object.entries(TRFs).map(([key, value]) => (
							<CheckboxLabel
								key={key}
								label={`${key} (${value})`}
								value={value}
								checked={tempSelectedCourts.includes(value)}
								onChange={(e) => handleCheckbox(e, value)}
							/>
						))}
				</div>
			</div>
			<Center className="w-full gap-4 mt-6">
				<Button
					variant={ButtonVariant.Outlined}
					className="w-full"
					onClick={() => setOpen(false)}
				>
					Cancelar
				</Button>
				<Button
					className="w-full"
					onClick={() => {
						if (tempSelectedCourts.length === 0) {
							setSelectedCourts(allCourts)
						} else {
							setSelectedCourts(tempSelectedCourts)
						}
						setOpen(false)
					}}
				>
					Salvar
				</Button>
			</Center>
		</Modal>
	)
}
