import InputField from "components/input-field"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import FitArrowIcon from "icons/FitArrowIcon"
import PlusIcon from "icons/PlusIcon"

interface SelectProps {
	options: {
		value: string
		label: string
		startIcon?: React.ReactNode
		endIcon?: React.ReactNode
	}[]
	onSelect: (group: string) => void
	whitoutCreate?: boolean
	placeholder: string
	placeholderWithIcon?: boolean
	clearable?: boolean
	disabled?: boolean
	currentValue?: string
}

export default function Select({
	options,
	onSelect,
	whitoutCreate,
	placeholder,
	placeholderWithIcon,
	clearable,
	disabled,
	currentValue,
}: SelectProps) {
	const [openOptions, setOpenOptions] = useState(false)
	const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
	const [value, setValue] = useState(currentValue || "")
	const [optionsList, setOptionsList] = useState(options)
	const optionsRef = useRef<HTMLDivElement>(null)
	const inputRef = useRef<HTMLInputElement>(null)
	const optionRefs = useRef<(HTMLDivElement | null)[]>([])
	const [lastOptionSelected, setLastOptionSelected] = useState("")
	const filteredOptions = optionsList.filter((option) =>
		option.label.toLowerCase().includes(value.toLowerCase()),
	)

	useEffect(() => {
		if (currentValue) {
			setValue(currentValue)
			setLastOptionSelected(currentValue)
		}
	}, [currentValue])

	function handleSelectOption(option: string) {
		setLastOptionSelected(option)
		onSelect(option)
	}

	useEffect(() => {
		if (currentValue) {
			setValue(currentValue)
		}
	}, [currentValue])

	useEffect(() => {
		setOptionsList(options)
	}, [options])

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				optionsRef.current &&
				!optionsRef.current.contains(event.target as Node)
			) {
				setOpenOptions(false)
			}
		}

		if (openOptions) {
			document.addEventListener("mousedown", handleClickOutside)
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [openOptions])

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (!openOptions) return

			switch (event.key) {
				case "ArrowDown":
					setSelectedIndex((prevIndex) =>
						prevIndex === null ||
						prevIndex === filteredOptions.length - 1
							? 0
							: prevIndex + 1,
					)
					break
				case "ArrowUp":
					setSelectedIndex((prevIndex) =>
						prevIndex === null || prevIndex === 0
							? filteredOptions.length - 1
							: prevIndex - 1,
					)
					break
				case "Enter":
					if (selectedIndex !== null) {
						setValue(filteredOptions[selectedIndex].label)
						handleSelectOption(filteredOptions[selectedIndex].label)
						setOpenOptions(false)
					}
					break
				default:
					break
			}
		}

		document.addEventListener("keydown", handleKeyDown)
		return () => {
			document.removeEventListener("keydown", handleKeyDown)
		}
	}, [openOptions, filteredOptions, selectedIndex])

	useEffect(() => {
		setSelectedIndex(null)
	}, [value])

	useEffect(() => {
		if (selectedIndex !== null && optionRefs.current[selectedIndex]) {
			optionRefs.current[selectedIndex]!.scrollIntoView({
				block: "nearest",
			})
		}
	}, [selectedIndex])

	useLayoutEffect(() => {
		if (
			openOptions &&
			!value &&
			filteredOptions &&
			filteredOptions?.length > 0
		) {
			const findOptionIndex = filteredOptions.findIndex(
				(option) => option.label === value,
			)
			const selectedElement = document.getElementById(
				`option-${findOptionIndex}`,
			)
			if (selectedElement) {
				selectedElement.scrollIntoView({ block: "center" })
			}
		}
	}, [openOptions, selectedIndex, value, filteredOptions])

	useEffect(() => {
		if (!optionsList || optionsList.length === 0) return
		const valuesMatchOption = optionsList.some(
			(option) =>
				option.label.toLowerCase() === value.toLowerCase() ||
				option.label.toLowerCase() === `{${value.toLowerCase()}}`,
		)

		if (
			(!openOptions && filteredOptions.length === 0) ||
			!valuesMatchOption
		) {
			setValue("")
			handleSelectOption("")
		}
	}, [openOptions])

	const placeholderWithIconStyle =
		placeholderWithIcon && lastOptionSelected ? `pl-[48px]` : ""

	return (
		<div
			className="relative"
			onClick={() => {
				if (disabled) return
				setOpenOptions(!openOptions)
				if (clearable && !openOptions) {
					setValue("")
					handleSelectOption("")
				}
			}}
			ref={optionsRef}
		>
			<div className="relative">
				{placeholderWithIcon && lastOptionSelected && (
					<div className="absolute top-3 left-4 z-10 w-6 h-6">
						{
							optionsList.find(
								(option) => option.label === lastOptionSelected,
							)?.startIcon
						}
					</div>
				)}
				<InputField
					className={`h-[48px] w-full ${placeholderWithIconStyle}`}
					onChange={(e) => setValue(e.target.value)}
					value={value}
					placeholder={placeholder}
					ref={inputRef}
					disabled={disabled}
				/>
				{placeholderWithIcon && lastOptionSelected && (
					<div className="absolute top-[14px] right-12 z-10">
						{
							optionsList.find(
								(option) => option.label === lastOptionSelected,
							)?.endIcon
						}
					</div>
				)}
			</div>
			<div
				className={`absolute top-[28%] right-[16px] fill-current cursor-pointer ${
					openOptions && "rotate-180"
				}`}
				onClick={() => {
					if (disabled) return
					setOpenOptions(!openOptions)
					if (clearable && !openOptions) {
						setValue("")
						handleSelectOption("")
					}
				}}
			>
				<FitArrowIcon className="fill-current" />
			</div>
			{openOptions && (
				<div className="overflow-y-auto absolute top-[56px] left-0 h-fit max-h-[160px] w-full  bg-white shadow-outlined-hover rounded z-50">
					{filteredOptions.length > 0 ? (
						<>
							{filteredOptions.map((option, index) => (
								<div
									key={option.value}
									className={`p-2 cursor-pointer text-cta-2 h-[32px] flex items-center justify-between text-brand-gray-3 hover:text-dark-blue-2 bg-brand-white-2 hover:bg-brand-white-3 ${
										selectedIndex === index &&
										"bg-brand-white-3 text-dark-blue-2"
									} ${
										value === option.label &&
										`font-semibold bg-brand-white-3 !text-dark-blue-1`
									}`}
									ref={(el) =>
										(optionRefs.current[index] = el)
									}
									id={`option-${index}`}
									onClick={() => {
										setValue(option.label)
										handleSelectOption(option.label)
										setOpenOptions(false)
										inputRef.current?.focus()
									}}
								>
									<div className="flex items-center gap-2">
										{option?.startIcon && (
											<div className="w-4 h-4 flex items-center justify-center">
												{option?.startIcon}
											</div>
										)}

										<p>{option.label}</p>
									</div>

									{option?.endIcon}
								</div>
							))}
						</>
					) : (
						!whitoutCreate && (
							<div
								className="flex items-center text-brand-gray-3 hover:text-dark-blue-2 cursor-pointer p-2 gap-2"
								onClick={() => {
									setOptionsList((prev) => [
										...prev,
										{ value: value, label: value },
									])
									handleSelectOption(value)
								}}
							>
								<PlusIcon size="16" />
								<p className="text-cta-2">{`Criar "${value}"`}</p>
							</div>
						)
					)}
				</div>
			)}
		</div>
	)
}
