import { Position, useReactFlow } from "@xyflow/react"
import Center from "components/center"
import Handle from "../subcomponents/Handle"
import { TbEye, TbStatusChange } from "react-icons/tb"
import { BsBraces } from "react-icons/bs"
import { useEffect, useRef, useState, useCallback, RefObject } from "react"
import { graphExecutionAtom, inputVariablesAtom, variablesAtom } from "../atoms"
import { useAtom } from "jotai"
import ActionNodeHandler from "../subcomponents/ActionNodeHandler"
import { Node, NodeData } from "../subcomponents/Flow"
import AutosizeInput from "react-input-autosize"
import VariablesModal from "../subcomponents/VariablesModal"
import NodeError from "../subcomponents/NodeError"
import NodeSuccess from "../subcomponents/NodeSuccess"
import { useSaveNode } from "../hooks/useSaveNode"
import useAgentBuilder from "hooks/useAgentBuilder"
import { useMutation } from "@tanstack/react-query"
import CustomResizable from "components/custom-resizable"
import { NodeExecutionAtom } from "types/agentBuilder"
import LoadingOverlay from "../subcomponents/LoadingOverlay"
import NodeTextArea from "../subcomponents/NodeTextArea"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"

export default function Transform({
	data,
	id,
}: {
	data: NodeData
	id: string
}) {
	const [showOutput, setShowOutput] = useState(true)
	const [nodeName, setNodeName] = useState(data.nodeData.name)
	const [editTitle, setEditTitle] = useState(false)
	const [openVariablesModal, setOpenVariablesModal] = useState(false)

	const { nodeData, graphId } = data
	const { setNodes, getNodes, getNode } = useReactFlow()
	const nodes = getNodes()
	const currentNode = getNode(id) as Node | undefined
	const [graphExecution] = useAtom(graphExecutionAtom)
	const [variables] = useAtom(variablesAtom)
	const [inputVariables] = useAtom(inputVariablesAtom)

	const inputRef: RefObject<AutosizeInput> &
		(string | RefObject<HTMLInputElement>) = useRef(null)
	const { nodeExecutionStart } = useAgentBuilder()
	const nodeExecutionMutation = useMutation({
		mutationFn: nodeExecutionStart,
		onSuccess: (data) => {
			if (data) setOpenVariablesModal(false)
		},
		onError: (error) => {
			showToast((error as Error)?.message, ToastType.Error)
		},
	})

	const updateNodeData = useCallback(
		(field: keyof typeof data.nodeData, value: any) => {
			const newNodes = nodes.map((node) => {
				if (node.id === id) {
					return {
						...node,
						data: {
							...node.data,
							nodeData: {
								...(typeof node.data.nodeData === "object"
									? node.data.nodeData
									: {}),
								[field]: value,
							},
						},
					}
				}
				return node
			})
			if (!nodeName) {
				setNodeName(data.nodeData.name)
			}
			setNodes(newNodes)
		},
		[nodes, id, setNodes, data.nodeData],
	)

	const onRename = useCallback(() => {
		setEditTitle(true)
	}, [])

	const onSaveTitle = useCallback(() => {
		updateNodeData("name", nodeName || data.nodeData.name)
		setEditTitle(false)
	}, [nodeName, updateNodeData])

	const handleKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === "Enter") {
				event.preventDefault()
				onSaveTitle()
			}
		},
		[onSaveTitle],
	)

	const handleChangePrompt = useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement>) => {
			updateNodeData("query", e.target.value)
		},
		[updateNodeData],
	)

	const getPromptValue = useCallback(() => {
		return nodeData?.query && nodeData?.query !== "query"
			? nodeData.query
			: ""
	}, [nodeData])

	const extractPlaceholders = useCallback(
		(currentVariables?: Record<string, string>) => {
			if (!currentNode) return {}
			const regex = /{([^}]+)}/g
			const placeholders: Record<string, string> = {}
			let match: RegExpExecArray | null

			while (
				(match = regex.exec(currentNode.data.nodeData.query)) !== null
			) {
				const totalVariables = [...variables, ...inputVariables].filter(
					(variable) => variable.type === "TEXT",
				)
				const key = match[1]
				const variableInState = totalVariables?.find(
					(v) => v.name === `{${key}}` || v.name === key,
				)
				placeholders[key] =
					variableInState?.value || currentVariables?.[key] || ""
			}
			return placeholders
		},
		[currentNode, variables],
	)

	const onResizeNode = useCallback(
		(width: number, height: number) => {
			const newNodes = nodes.map((node) => {
				if (node.id === id) {
					return { ...node, size: { width, height } }
				}
				return node
			})
			setNodes(newNodes)
		},
		[nodes, id, setNodes],
	)

	useEffect(() => {
		if (editTitle && inputRef.current) {
			inputRef.current.select()
		}
	}, [editTitle])

	useSaveNode({ currentNode, graphId, id })

	const nodeExecution = graphExecution?.[id] as NodeExecutionAtom | undefined

	return (
		<ActionNodeHandler
			id={id}
			onRename={onRename}
			isError={!!(nodeExecution && nodeExecution?.reason === "ERROR")}
		>
			<div className="min-w-[400px] p-2 flex flex-col gap-2 bg-white border-[1px] border-brand-white-4 rounded-2xl h-full">
				<Handle type="target" position={Position.Left} />
				<Handle type="source" position={Position.Right} />

				<div className="flex items-center justify-between bg-[#D3E5EF] p-2 pr-[20px] rounded-lg">
					<div className="flex items-center text-[#3D88B4] font-semibold gap-3">
						<Center className="w-[48px] h-[48px] p-2 bg-white rounded">
							<TbStatusChange size="32px" color="#3D88B4" />
						</Center>

						<div className="flex flex-col">
							<p className="text-small-1">Transformar</p>
							{!editTitle ? (
								<h4 className="text-[#3D88B4] max-w-[178px] truncate">
									{nodeName || "prompt_name"}
								</h4>
							) : (
								<AutosizeInput
									inputClassName="text-[#3D88B4] p-0 m-0 h-[27px] text-h4 font-semibold border-none bg-transparent focus-visible:!outline-none max-w-[178px] overflow-hidden text-ellipsis whitespace-nowrap"
									value={nodeName || "prompt_name"}
									onChange={(event) =>
										setNodeName(event.target.value)
									}
									onBlur={onSaveTitle}
									autoFocus
									ref={inputRef}
									onKeyDown={handleKeyDown}
									maxLength={50}
								/>
							)}
						</div>
					</div>
				</div>

				<CustomResizable
					onResizeStop={(_, __, elementRef) => {
						onResizeNode(
							elementRef.clientWidth,
							elementRef.clientHeight,
						)
					}}
					defaultSize={{
						width: currentNode?.size.width,
						height: currentNode?.size.height,
					}}
				>
					<div className="p-4 bg-brand-white-2 rounded-lg h-full">
						<div className="flex items-center justify-between w-full mb-3">
							<p className="text-cta-2">Input</p>
							<Center className="p-[4px] rounded border-[1px] border-[#F0F0F0]">
								<BsBraces size="16px" color="#6D6D6D" />
							</Center>
						</div>
						<NodeTextArea
							className="h-[calc(100%-34px)] resize-none"
							placeholder="Digite seu comando"
							value={getPromptValue()}
							onChange={handleChangePrompt}
						/>
					</div>
				</CustomResizable>

				{nodeExecution && nodeExecution?.reason !== "ERROR" && (
					<div className="p-4 bg-brand-white-2 rounded-lg">
						<div
							className={`flex items-center justify-between w-full ${
								showOutput && "mb-3"
							}`}
						>
							<p className="text-cta-2">Output</p>
							<Center
								className="p-[4px] rounded border-[1px] border-[#F0F0F0] nodrag cursor-pointer"
								onClick={() => setShowOutput(!showOutput)}
							>
								<TbEye size="16px" color="#6D6D6D" />
							</Center>
						</div>

						{showOutput && (
							<div className="relative">
								{nodeExecution?.reason !== "START" ? (
									<div className="relative">
										<NodeTextArea
											className="resize-y"
											value={
												nodeExecution?.data?.result
													?.result || ""
											}
											onChange={() => {}}
											disabled={
												nodeExecution?.data?.status ===
												"START"
											}
										/>
									</div>
								) : (
									<>
										<NodeTextArea />
										<LoadingOverlay />
									</>
								)}
							</div>
						)}
					</div>
				)}

				{nodeExecution && nodeExecution?.data?.status === "ERROR" && (
					<NodeError error={nodeExecution?.detail?.error || ""} />
				)}
				{nodeExecution && nodeExecution?.data?.status === "SUCCESS" && (
					<NodeSuccess nodeExecutionStatus={nodeExecution?.data} />
				)}

				<VariablesModal
					open={openVariablesModal}
					setOpen={setOpenVariablesModal}
					nodeId={id}
					extractPlaceholders={extractPlaceholders}
					nodeExecutionMutation={nodeExecutionMutation}
				/>
			</div>
		</ActionNodeHandler>
	)
}
