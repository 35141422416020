import LoadingSpinner from "pages/MyDocumentsPage/subcomponents/LoadingSpinner"

export default function LoadingOverlay() {
	return (
		<div className="absolute inset-0 flex items-center justify-center">
			<div className="opacity-50 bg-white w-full h-full" />
			<div className="absolute">
				<LoadingSpinner />
			</div>
		</div>
	)
}
