import { Position, useReactFlow } from "@xyflow/react"
import Center from "components/center"
import Handle from "../subcomponents/Handle"

import { TbEye, TbListSearch } from "react-icons/tb"
import { RefObject, useCallback, useEffect, useRef, useState } from "react"
import ActionNodeHandler from "../subcomponents/ActionNodeHandler"
import { Node, NodeData } from "../subcomponents/Flow"
import AutosizeInput from "react-input-autosize"
import NodeTextArea from "../subcomponents/NodeTextArea"
import { useSaveNode } from "../hooks/useSaveNode"
import { graphExecutionAtom } from "../atoms"
import { useAtom } from "jotai"
import { NodeExecutionAtom } from "types/agentBuilder"
import LoadingOverlay from "../subcomponents/LoadingOverlay"
import NodeError from "../subcomponents/NodeError"
import NodeSuccess from "../subcomponents/NodeSuccess"
import CustomResizable from "components/custom-resizable"

export default function JurisprudenceSearch({
	data,
	id,
}: {
	data: NodeData
	id: string
}) {
	const { nodeData, graphId } = data
	const [showOutput, setShowOutput] = useState(true)
	const [nodeName, setNodeName] = useState(nodeData.name)
	const [editTitle, setEditTitle] = useState(false)
	const { setNodes, getNodes, getNode } = useReactFlow()
	const currentNode = getNode(id) as Node | undefined
	const nodes = getNodes()
	const inputRef: RefObject<AutosizeInput> &
		(string | RefObject<HTMLInputElement>) = useRef(null)

	const [graphExecution] = useAtom(graphExecutionAtom)

	useEffect(() => {
		if (editTitle && inputRef.current) {
			inputRef.current.select()
		}
	}, [editTitle])

	const onRename = () => {
		setEditTitle(true)
	}

	const onSaveTitle = () => {
		const newNodes = nodes.map((node) => {
			if (node.id === id) {
				return {
					...node,
					data: {
						...node.data,
						nodeData: {
							...(typeof node.data.nodeData === "object"
								? node.data.nodeData
								: {}),
							name: nodeName || nodeData.name,
						},
					},
				}
			}
			return node
		})
		if (!nodeName) {
			setNodeName(nodeData.name)
		}
		setNodes(newNodes)
		setEditTitle(false)
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			event.preventDefault()
			onSaveTitle()
		}
	}

	const updateNodeData = useCallback(
		(field: keyof typeof data.nodeData, value: any) => {
			const newNodes = nodes.map((node) => {
				if (node.id === id) {
					return {
						...node,
						data: {
							...node.data,
							nodeData: {
								...(typeof node.data.nodeData === "object"
									? node.data.nodeData
									: {}),
								[field]: value,
							},
						},
					}
				}
				return node
			})
			setNodes(newNodes)
		},
		[nodes, id, setNodes, data.nodeData],
	)

	const handleChangeComand = useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement>) => {
			updateNodeData("query", e.target.value)
		},
		[updateNodeData],
	)

	const getComandValue = useCallback(() => {
		return nodeData?.query && nodeData?.query !== "query"
			? nodeData.query
			: ""
	}, [nodeData])

	const onResizeNode = useCallback(
		(width: number, height: number) => {
			const newNodes = nodes.map((node) => {
				if (node.id === id) {
					return { ...node, size: { width, height } }
				}
				return node
			})
			setNodes(newNodes)
		},
		[nodes, id, setNodes],
	)

	useSaveNode({ currentNode, graphId, id })
	const graphReason = graphExecution?.graph_execution.reason
	const nodeExecution = graphExecution?.[id] as NodeExecutionAtom | undefined

	return (
		<ActionNodeHandler id={id} onRename={onRename}>
			<div className="w-full p-2 flex flex-col gap-2 bg-white border-[1px] border-brand-white-4 rounded-2xl">
				<Handle type="target" position={Position.Left} />
				<Handle type="source" position={Position.Right} />

				<div className="flex items-center justify-between bg-[#D3E5EF] p-2 pr-[20px] rounded-lg">
					<div className="  flex items-center text-[#3D88B4] font-semibold  gap-3">
						<Center className="w-[48px] h-[48px] p-2 bg-white rounded">
							<TbListSearch size="32px" color="#3D88B4" />
						</Center>

						<div className="flex flex-col ">
							<p className="text-small-1">
								Busca de jurisprudência
							</p>
							{!editTitle ? (
								<h4 className=" text-[#3D88B4] max-w-[248px] truncate">
									{nodeName}
								</h4>
							) : (
								<AutosizeInput
									inputClassName="text-[#3D88B4] p-0  m-0 h-[27px]  text-h4 font-semibold border-none bg-transparent  focus-visible:!outline-none max-w-[248px] overflow-hidden text-ellipsis whitespace-nowrap"
									value={nodeName}
									onChange={(event) =>
										setNodeName(event.target.value)
									}
									onBlur={onSaveTitle}
									autoFocus
									ref={inputRef}
									onKeyDown={handleKeyDown}
									maxLength={50}
								/>
							)}
						</div>
					</div>
				</div>
				<CustomResizable
					onResizeStop={(_, __, elementRef) => {
						onResizeNode(
							elementRef.clientWidth,
							elementRef.clientHeight,
						)
					}}
					defaultSize={{
						width: currentNode?.size.width,
						height: currentNode?.size.height,
					}}
				>
					<div className="p-4 bg-brand-white-2 rounded-lg h-full">
						<div className="flex items-center justify-between w-full mb-3">
							<p className="text-cta-2">Input</p>
							<p className="text-cta-2 text-[#6D6D6D]">{`{tribunais}`}</p>
						</div>

						<NodeTextArea
							className="h-[calc(100%-34px)] resize-none"
							placeholder="Digite seu comando"
							value={getComandValue()}
							onChange={handleChangeComand}
						/>
					</div>
				</CustomResizable>

				{nodeExecution && nodeExecution?.data?.status !== "ERROR" && (
					<div className="p-4 bg-brand-white-2 rounded-lg">
						<div
							className={`flex items-center justify-between w-full  ${
								showOutput && "mb-3"
							}`}
						>
							<p className="text-cta-2">Output</p>
							<Center
								className="p-[4px] rounded border-[1px] border-[#F0F0F0] nodrag cursor-pointer"
								onClick={() => setShowOutput(!showOutput)}
							>
								<TbEye size="16px" color="#6D6D6D" />
							</Center>
						</div>
						{showOutput && (
							<div className="relative">
								{nodeExecution.reason !== "START" ? (
									<NodeTextArea
										className="resize-y"
										value={
											nodeExecution?.data?.result
												?.result || ""
										}
										onChange={() => {}}
										disabled={
											nodeExecution?.data?.status ===
											"START"
										}
									/>
								) : (
									<>
										<NodeTextArea />
										<LoadingOverlay />
									</>
								)}
							</div>
						)}
					</div>
				)}

				{nodeExecution?.data && nodeExecution?.reason === "ERROR" && (
					<NodeError error={nodeExecution?.detail?.error || ""} />
				)}
				{nodeExecution?.data &&
					nodeExecution?.data?.status === "SUCCESS" &&
					graphReason !== "START" && (
						<NodeSuccess
							nodeExecutionStatus={nodeExecution?.data}
						/>
					)}
			</div>
		</ActionNodeHandler>
	)
}
