import InputField from "components/input-field"
import { areaIcons, areas } from "pages/FlowBuilderPage/constants"
import { useEffect, useLayoutEffect, useRef, useState, useMemo } from "react"
import { Flow } from "types/flowBuilder"

interface SelectProps {
	options: Record<string, Flow[]> | undefined
	onSelect: (flowName: string) => void
	whitoutCreate?: boolean
	placeholder: JSX.Element
	clearable?: boolean
	disabled?: boolean
}

export default function Select({
	options,
	onSelect,
	placeholder,
	clearable,
	disabled,
}: SelectProps) {
	const [openOptions, setOpenOptions] = useState(false)
	const [selectedIndex, setSelectedIndex] = useState<number | null>(null)
	const [value, setValue] = useState("")
	const optionsRef = useRef<HTMLDivElement>(null)
	const inputRef = useRef<HTMLInputElement>(null)
	const optionRefs = useRef<(HTMLDivElement | null)[]>([])

	const filteredGroupedOptions = useMemo(() => {
		if (!options) return {} as Record<string, Flow[]>
		const grouped: Record<string, Flow[]> = {}
		areas.forEach((area) => {
			const flows = options[area.value]
			if (flows) {
				const filteredFlows = flows.filter((flow) =>
					flow.name.toLowerCase().includes(value.toLowerCase()),
				)
				if (filteredFlows.length > 0) {
					grouped[area.value] = filteredFlows
				}
			}
		})
		return grouped
	}, [options, value])

	const flattenedOptions = useMemo(() => {
		return areas.reduce((acc: Flow[], area) => {
			if (filteredGroupedOptions[area.value]) {
				return acc.concat(filteredGroupedOptions[area.value])
			}
			return acc
		}, [])
	}, [filteredGroupedOptions])

	function handleSelectOption(flowName: string) {
		onSelect(flowName)
	}

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				optionsRef.current &&
				!optionsRef.current.contains(event.target as Node)
			) {
				setOpenOptions(false)
			}
		}
		if (openOptions) {
			document.addEventListener("mousedown", handleClickOutside)
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [openOptions])

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (!openOptions) return
			switch (event.key) {
				case "ArrowDown":
					setSelectedIndex((prev) =>
						prev === null || prev === flattenedOptions.length - 1
							? 0
							: prev + 1,
					)
					break
				case "ArrowUp":
					setSelectedIndex((prev) =>
						prev === null || prev === 0
							? flattenedOptions.length - 1
							: prev - 1,
					)
					break
				case "Enter":
					if (selectedIndex !== null) {
						const selectedFlow = flattenedOptions[selectedIndex]
						setValue(selectedFlow.name)
						handleSelectOption(selectedFlow.id)
						setOpenOptions(false)
					}
					break
				default:
					break
			}
		}
		document.addEventListener("keydown", handleKeyDown)
		return () => {
			document.removeEventListener("keydown", handleKeyDown)
		}
	}, [openOptions, flattenedOptions, selectedIndex])

	useEffect(() => {
		setSelectedIndex(null)
	}, [value])

	useEffect(() => {
		if (selectedIndex !== null && optionRefs.current[selectedIndex]) {
			optionRefs.current[selectedIndex]?.scrollIntoView({
				block: "nearest",
			})
		}
	}, [selectedIndex])

	useLayoutEffect(() => {
		if (openOptions && !value && flattenedOptions.length > 0) {
			const selectedElement = document.getElementById(
				`option-${selectedIndex !== null ? selectedIndex : 0}`,
			)
			if (selectedElement) {
				selectedElement.scrollIntoView({ block: "center" })
			}
		}
	}, [openOptions, selectedIndex, value, flattenedOptions])

	return (
		<div
			className="relative"
			onClick={() => {
				if (disabled) return
				setOpenOptions(!openOptions)
				if (clearable && !openOptions) {
					setValue("")
					handleSelectOption("")
				}
			}}
			ref={optionsRef}
		>
			<div className="relative" onClick={() => inputRef.current?.focus()}>
				<InputField
					className={`h-[56px] w-[400px] !rounded-[16px] !p-4 bg-[white] shadow-input-shadow !border-[#F0F0F0] font-semibold placeholder:font-semibold focus:placeholder:text-transparent focus:outline-[1px] focus:outline-[#3083FF]`}
					onChange={(e) => setValue(e.target.value)}
					value={value}
					ref={inputRef}
					disabled={disabled}
				/>

				<div
					className={`absolute top-[14px] left-[20px] w-full text-[#B0B0B0] ${
						openOptions || value ? "hidden" : "block"
					}`}
				>
					{placeholder}
				</div>
			</div>

			{openOptions && (
				<div className="absolute top-[64px] left-0 h-fit w-full bg-white shadow-outlined-hover rounded-[16px] z-50 pr-[0.5px] overflow-hidden">
					<div className="overflow-y-auto max-h-[190px] 0xl:max-h-[300px] bg-brand-white-2">
						{Object.entries(filteredGroupedOptions).map(
							([group, flows]) => {
								let groupStartIndex = 0
								Object.entries(filteredGroupedOptions).forEach(
									([grp, f]) => {
										if (grp === group) return
										groupStartIndex += f.length
									},
								)
								return (
									<div key={group}>
										{!value && (
											<p className="text-cta-2 text-[#B0B0B0] pl-2  h-[32px] flex items-center">
												{
													areas.find(
														(area) =>
															area.value ===
															group,
													)?.label
												}
											</p>
										)}
										{flows.map((flow, index) => {
											const overallIndex =
												groupStartIndex + index
											return (
												<div
													key={flow.id}
													id={`option-${overallIndex}`}
													className={`p-2 cursor-pointer text-cta-2 h-[32px] flex items-center justify-between text-brand-gray-3  bg-brand-white-2 hover:bg-[#F5F5F5] ${
														selectedIndex ===
															overallIndex &&
														"bg-[#F5F5F5] "
													} ${
														value === flow.name &&
														`font-semibold bg-[#F5F5F5] !text-dark-blue-1`
													}`}
													ref={(el) =>
														(optionRefs.current[
															overallIndex
														] = el)
													}
													onClick={() => {
														setValue(flow.name)
														handleSelectOption(
															flow.id,
														)
														setOpenOptions(false)
														inputRef.current?.focus()
													}}
												>
													<div className="flex items-center gap-2">
														<div className="w-[20px] h-[20px] flex items-center justify-center">
															{
																areaIcons[
																	group as keyof typeof areaIcons
																]
															}
														</div>

														<p>{flow.name}</p>
													</div>
												</div>
											)
										})}
									</div>
								)
							},
						)}
					</div>
				</div>
			)}
		</div>
	)
}
