import { useReactFlow } from "@xyflow/react"
import { WS_SERVER } from "config"
import useAgentBuilder from "hooks/useAgentBuilder"
import { useJwt } from "hooks/useJwt"
import { useEffect, useRef } from "react"
import { GraphExecutionAtom, NodeExecutionAtom } from "types/agentBuilder"
import { graphExecutionAtom } from "../atoms"
import { useAtom } from "jotai"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import { toast } from "react-toastify"

export const initialGraphExecution: GraphExecutionAtom = {
	graph_execution: {
		id: "",
		detail: null,
		_created_at: "",
		_updated_at: "",
		reason: "",
		graph_execution: "",
		data: null,
	},
}

const useNodeExecutionStatus = () => {
	const wsRef = useRef<WebSocket>()
	const [jwt] = useJwt()
	const [, setGraphExecution] = useAtom(graphExecutionAtom)
	const { getNodeExecution, getGraphExecution } = useAgentBuilder()
	const { getNode } = useReactFlow()

	async function getNodeExecutionData(message: any) {
		try {
			const { node_execution_id, node_id } = message

			const data = await getNodeExecution(node_execution_id)
			const node = getNode(node_id)

			setGraphExecution((prevState) => {
				const currentValue = prevState?.[node_id]

				const previousNodeExecution: NodeExecutionAtom[] =
					Array.isArray(currentValue) ? currentValue : []

				if (node?.type === "prompt") {
					return {
						...prevState,
						[node_id]: [
							...previousNodeExecution,
							{
								...message,
								data,
							},
						],
					}
				} else {
					return {
						...prevState,
						[node_id]: {
							...message,
							data,
						},
					}
				}
			})
		} catch (error) {
			console.error("Erro ao obter dados de execução do nó:", error)
		}
	}

	async function getGraphExecutionData(message: any) {
		try {
			const { graph_execution } = message

			const data = await getGraphExecution(graph_execution)

			setGraphExecution((prevState) => ({
				...prevState,
				graph_execution: {
					...message,
					data,
				},
			}))
		} catch (error) {
			console.error("Erro ao obter dados de execução do grafo:", error)
		}
	}

	useEffect(() => {
		const socket = new WebSocket(`${WS_SERVER.baseUrl}/?token=${jwt}`)
		wsRef.current = socket

		socket.onopen = () => {}

		socket.onmessage = (e) => {
			if (e.data === "ping") {
				socket.send("pong")
				return
			}

			let data: any
			try {
				data = JSON.parse(e.data)
			} catch (error) {
				console.error("Erro ao fazer parse da mensagem:", e.data, error)
				return
			}

			switch (data.event_type) {
				case "NODE_EXECUTION_STATUS_UPDATE": {
					if (data.payload.reason === "START") {
						const node = getNode(data.payload.node_id)
						setGraphExecution((prevState) => ({
							...prevState,
							[data.payload.node_id]:
								node?.type === "prompt"
									? [data.payload]
									: data.payload,
						}))
					} else {
						getNodeExecutionData(data.payload)
					}
					toast.clearWaitingQueue()
					break
				}
				case "GRAPH_EXECUTION_STATUS_UPDATE": {
					const toastIsActive = toast.isActive("graph")

					if (data.payload.reason === "ERROR" && !toastIsActive) {
						showToast(
							data?.payload?.detail?.error,
							ToastType.Error,
							undefined,
							{
								toastId: "graph",
							},
						)
					}
					if (data.payload.reason === "SUCCESS") {
						getGraphExecutionData(data.payload)
					} else {
						setGraphExecution((prevState) => ({
							...prevState,
							graph_execution: data.payload,
						}))
						toast.clearWaitingQueue()
					}
					break
				}
				default:
					console.warn("Tipo de evento não tratado:", data.event_type)
			}
		}

		socket.onerror = () => {}

		socket.onclose = () => {}

		return () => {
			socket.close(1000, "finished")
		}
	}, [jwt])
}

export default useNodeExecutionStatus
