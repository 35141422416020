import { ToastOptions, toast } from "react-toastify"
import { ToastType } from "./types"
import { ToastMessage } from "."
import { toastClassName } from "./variables"

export function showToast(
	message: string,
	type: ToastType = ToastType.Success,
	ctaButton?: { text: string; onClick: () => void },
	options?: ToastOptions,
): void {
	toast(
		<ToastMessage message={message} type={type} ctaButton={ctaButton} />,
		{
			icon: false,
			className: `${toastClassName[type].background}  !p-0 flex  rounded-md justify-between w-fit cursor-pointer !min-h-[0px] !m-0  w-full`,
			bodyClassName() {
				return (
					toastClassName[type].background +
					" text-sm font-white block p-0"
				)
			},

			hideProgressBar: true,
			closeButton: false,
			autoClose: 3000,
			position: "bottom-center",
			closeOnClick: !ctaButton,
			toastId: options?.toastId,

			...options,
		},
	)
}
