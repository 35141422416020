import { ButtonVariant } from "components/button/types"
import Modal from "components/modal"
import React, { useState } from "react"
import Button from "components/button-v2"
import InputField from "components/input-field"

export default function DeleteModal({
	open,
	setOpen,
	handleDelete,
	title,
	description,
	hasConfirm,
	confirmName,
}: {
	open: boolean
	setOpen: any
	handleDelete: () => void
	title: string
	description: React.ReactNode
	hasConfirm?: boolean
	confirmName?: string
}) {
	const [confirmation, setConfirmation] = useState("")

	return (
		<Modal
			openModal={open}
			setOpenModal={setOpen}
			title={title}
			width="w-[560px]  overflow-visible !font-Red-Hat-Display"
			newButtonClose
		>
			<div>
				{description}

				{hasConfirm && (
					<div className="my-8  w-full">
						<InputField
							className="w-full"
							placeholder={confirmName}
							onChange={(e) => setConfirmation(e.target.value)}
						/>
					</div>
				)}

				<div className="w-full flex items-center justify-between gap-4 mt-6">
					<Button
						variant={ButtonVariant.Outlined}
						onClick={() => setOpen(false)}
						className="w-full  justify-center"
					>
						Cancelar
					</Button>

					<Button
						className="w-full"
						onClick={() => handleDelete()}
						color="red"
						disabled={
							hasConfirm ? confirmation !== confirmName : false
						}
					>
						Excluir
					</Button>
				</div>
			</div>
		</Modal>
	)
}
