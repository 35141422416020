import { UseMutationResult, useQueryClient } from "@tanstack/react-query"
import TitleTooltip from "components/title-tooltip"
import { showToast } from "components/toast/functions"
import { useJwt } from "hooks/useJwt"
import React, { RefObject, useEffect, useRef, useState } from "react"
import AutosizeInput from "react-input-autosize"
import { RetrieveLegalDocumentResponse } from "types/legalDocument"
import {
	RetrieveLegalDocumentResponse as RetrieveLegalDocumentResponseV2,
	UpdateLegalDocumentRequestBody,
	UpdateLegalDocumentResponse,
} from "types/legalDocumentV2"

interface HeaderProps {
	status?: "SUCCESS" | "START" | "ERROR"
	data?: RetrieveLegalDocumentResponseV2 | RetrieveLegalDocumentResponse
	documentId: string
	updateMutation: UseMutationResult<
		UpdateLegalDocumentResponse,
		unknown,
		{
			id: string
			payload: UpdateLegalDocumentRequestBody
		},
		unknown
	>
}

const Header = ({ data, documentId, updateMutation, status }: HeaderProps) => {
	const [isEdit, setIsEdit] = useState(false)
	const [inputTitle, setInputTitle] = useState(data?.title)
	const inputRef: RefObject<AutosizeInput> &
		(string | RefObject<HTMLInputElement>) = useRef(null)

	const queryClient = useQueryClient()

	const [jwt] = useJwt()

	const onSaveTitle = () => {
		if (inputTitle !== data?.title) {
			updateMutation
				.mutateAsync({
					id: documentId,
					payload: {
						title: inputTitle,
					},
				})
				.then(() => {
					queryClient.invalidateQueries({
						queryKey: ["listLegalDocuments", jwt],
					})
					queryClient.invalidateQueries({
						queryKey: ["legalDocument", documentId],
					})
					queryClient.invalidateQueries({
						queryKey: ["legalDocumentType", documentId],
					})
					showToast("O título do documento foi alterado com sucesso.")
				})
		}
		setIsEdit(false)
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			event.preventDefault()
			onSaveTitle()
		}
	}

	useEffect(() => {
		if (data && status !== "START") {
			setInputTitle(data.title)
		}
	}, [data])

	useEffect(() => {
		if (isEdit && inputRef.current) {
			inputRef.current.select()
		}
	}, [isEdit])

	return (
		<div className="flex items-center ">
			{!isEdit ? (
				<div className="flex items-center ">
					<TitleTooltip
						disable={inputTitle ? inputTitle?.length < 58 : false}
						text={inputTitle || ""}
					>
						<h1
							onClick={() => setIsEdit(true)}
							className={`font-semibold mr-[2px] text-dark-blue-1 max-w-[calc(100vw-600px)] w-fit overflow-hidden text-ellipsis whitespace-nowrap text-cta-1`}
						>
							{inputTitle ||
								data?.title ||
								"Documento sem título"}
						</h1>
					</TitleTooltip>
				</div>
			) : (
				<AutosizeInput
					inputClassName="text-dark-blue-1 p-0 m-0 text-cta-1 font-semibold border-none bg-transparent  focus-visible:!outline-none max-w-[calc(100vw-600px)] overflow-hidden text-ellipsis whitespace-nowrap"
					value={inputTitle || ""}
					onChange={(event) => setInputTitle(event.target.value)}
					onBlur={onSaveTitle}
					autoFocus
					ref={inputRef}
					onKeyDown={handleKeyDown}

					// maxLength={50}
				/>
			)}
		</div>
	)
}

export default Header
