import { Dropdown, DropdownSectionProps } from "components/dropdown"
import { useState, useRef, useEffect } from "react"
import { createPortal } from "react-dom"
import { TbDots } from "react-icons/tb"

const DropdownPortal = ({
	sections,
	open,
	setOpen,
}: {
	sections: DropdownSectionProps[]
	open: boolean
	setOpen: (open: boolean) => void
}) => {
	const triggerRef = useRef<HTMLDivElement>(null)
	const [position, setPosition] = useState({ top: 0, left: 0 })

	useEffect(() => {
		if (open && triggerRef.current) {
			const rect = triggerRef.current.getBoundingClientRect()
			setPosition({
				top: rect.bottom + window.scrollY,
				left: rect.left + window.scrollX,
			})
		}
	}, [open])

	return (
		<>
			<div
				ref={triggerRef}
				onClick={(e) => {
					setOpen(true)
					e.stopPropagation()
				}}
				className="cursor-pointer"
			>
				<TbDots color="#6D6D6D" size="16px" />
			</div>

			{open &&
				createPortal(
					<div
						style={{
							position: "fixed",
							top: position.top,
							left: position.left,
							zIndex: 9999,
						}}
					>
						<Dropdown
							sections={sections}
							className="w-[165px]"
							open={open}
							setOpen={setOpen}
						>
							<></>
						</Dropdown>
					</div>,
					document.body,
				)}
		</>
	)
}

export default DropdownPortal
