export default function CircleIcon2() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="360"
			height="360"
			viewBox="0 0 360 360"
			fill="none"
		>
			<g filter="url(#filter0_f_3_883)">
				<circle
					cx="180"
					cy="180"
					r="90"
					fill="#3083FF"
					fillOpacity="0.06"
				/>
			</g>
			<defs>
				<filter
					id="filter0_f_3_883"
					x="0"
					y="0"
					width="360"
					height="360"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="BackgroundImageFix"
						result="shape"
					/>
					<feGaussianBlur
						stdDeviation="45"
						result="effect1_foregroundBlur_3_883"
					/>
				</filter>
			</defs>
		</svg>
	)
}
