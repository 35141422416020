export default function FlowTag({ isActive }: { isActive: boolean }) {
	return (
		<div
			className={`py-1 px-2 flex items-center justify-center  rounded w-fit gap-1  h-[24px] ${
				isActive ? "bg-[#E4FFEC]" : "bg-[#FFEEEE]"
			}`}
		>
			<div
				className={`w-2 h-2 rounded-full ${
					isActive ? "bg-[#068F2D]" : "bg-[#D01313]"
				}`}
			/>
			<p
				className={`text-cta-2  ${
					isActive ? "text-[#068F2D]" : "text-[#D01313]"
				}`}
			>

				{isActive ? "Ativo" : "Inativo"}

			</p>
		</div>
	)
}
