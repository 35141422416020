import CloseIcon from "icons/CloseIcon"
import { GraphCheckpoint, GraphData } from "types/agentBuilder"
import useAgentBuilder from "hooks/useAgentBuilder"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { ToastType } from "components/toast/types"
import { showToast } from "components/toast/functions"

import Step from "./Step"
import { format } from "date-fns"
import ClickAwayListener from "react-click-away-listener"
interface StepperProps {
	checkpoints: GraphCheckpoint[]
	setOpenStepper: (value: boolean) => void
	setLoading: (value: boolean) => void
	graph: GraphData
}

export default function Stepper({
	checkpoints,
	setOpenStepper,
	setLoading,
	graph,
}: StepperProps) {
	const { checkpointRestore, publishCheckpoint } = useAgentBuilder()
	const queryClient = useQueryClient()

	const checkpointRestoreMutation = useMutation({
		mutationFn: checkpointRestore,
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["graph", graph.id] })
		},
		onError: (error) => {
			setLoading(false)
			showToast((error as Error)?.message, ToastType.Error)
		},
	})

	const publishCheckpointMutation = useMutation({
		mutationFn: publishCheckpoint,
		onSuccess: (data) => {
			queryClient.invalidateQueries({
				queryKey: ["checkpoints", graph.id],
			})
			queryClient.invalidateQueries({
				queryKey: ["graph", graph.id],
			})
			showToast(
				`Versão “${format(
					new Date(data._created_at),
					"dd/MM/yyyy - HH:mm",
				)}” publicada`,
				ToastType.Success,
			)
		},
		onError: (error) => {
			showToast((error as Error)?.message, ToastType.Error)
		},
	})

	const handlePublishCheckpoint = (checkpoint: string) => {
		publishCheckpointMutation.mutate({
			checkpoint,
		})
	}

	async function onRestoreCheckPoint(checkpointId: string) {
		setLoading(true)
		checkpointRestoreMutation.mutateAsync(checkpointId)
	}

	const sortedCheckpoints = checkpoints.sort((a, b) => {
		return (
			new Date(b._created_at).getTime() -
			new Date(a._created_at).getTime()
		)
	})

	return (
		<ClickAwayListener onClickAway={() => setOpenStepper(false)}>
			<div className="overflow-y-auto overflow-x-hidden h-[calc(100vh-52px)]">
				<div className="flex items-center justify-between w-[286px] mx-6 mt-6 ">
					<p className="font-semibold text-cta-1">Versões salvas</p>

					<div
						className="cursor-pointer"
						onClick={() => setOpenStepper(false)}
					>
						<CloseIcon size="20px" />
					</div>
				</div>
				<ol className="relative text-gray-500 mt-[24px]   dark:text-gray-400  w-[305px] ">
					{sortedCheckpoints.map((checkpoint, index) => {
						return (
							<Step
								checkpoint={checkpoint}
								checkpoints={checkpoints}
								index={index}
								key={checkpoint.id}
								onRestoreCheckPoint={onRestoreCheckPoint}
								handlePublishCheckpoint={
									handlePublishCheckpoint
								}
								graph={graph}
							/>
						)
					})}
				</ol>
			</div>
		</ClickAwayListener>
	)
}
