import { Button } from "components/button"
import { ButtonVariant } from "components/button/types"
import Prompts from "./subcomponents/Prompts"
import useAgentBuilder from "hooks/useAgentBuilder"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Navigate, useNavigate } from "react-router-dom"
import PlusIcon from "icons/PlusIcon"
import { nodesDefaultSizes, serializeNode } from "pages/GraphPage/utils"
import { useFeatureFlag } from "hooks/useFeatureFlag"
import FEATURE_FLAGS from "feature-flags"
import { useAtom } from "jotai"
import { graphExecutionAtom } from "pages/GraphPage/atoms"
import { defaultInputVariables } from "pages/GraphPage/constants"
import { initialGraphExecution } from "pages/GraphPage/hooks/useNodeExecutionStatus"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import { useState } from "react"
import { Graph } from "types/agentBuilder"
import DeleteModal from "components/delete-modal"

const columnHeader = [
	{
		title: "Nome",
		key: "name",
		width: "w-[30%]",
	},
	{
		title: "Status",
		key: "status",
		width: "w-[15%]",
	},
	{
		title: "Última modificação",
		key: "lastModified",
		width: "w-[17%]",
	},
	{
		title: "Última publicação",
		key: "lastPublished",
		width: "w-[17%]",
	},
	{
		title: "Performance",
		key: "performance",
		width: "w-[16%]",
	},
	{
		title: "",
		key: "actions",
		width: "w-[5%]",
	},
]

export default function AgentBuilderPage() {
	const { data: isAdminUserFeatureFlagEnabled } = useFeatureFlag(
		FEATURE_FLAGS.isAdminUser,
	)
	const {
		listGraphs,
		addGraph,
		addNode,
		addInputVariable,
		addNewEdge,
		deleteGraph,
	} = useAgentBuilder()
	const [, setGraphExecution] = useAtom(graphExecutionAtom)
	const [graphToDelete, setGraphToDelete] = useState<Graph | false>(false)
	const queryClient = useQueryClient()

	const { data } = useQuery(["listGraphs"], listGraphs)
	const navigate = useNavigate()
	const addNodeMutation = useMutation({
		mutationFn: addNode,
	})

	const addInputVariableMutation = useMutation({
		mutationFn: addInputVariable,
		onError: (error) => {
			showToast((error as Error)?.message, ToastType.Error)
		},
	})

	const deleteGraphMutation = useMutation({
		mutationFn: deleteGraph,
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["listGraphs"],
			})
			if (graphToDelete) {
				showToast(
					`Flow “${graphToDelete.name}” foi excluído`,
					ToastType.Error,
				)
			}
			setGraphToDelete(false)
		},
		onError: (error) => {
			showToast((error as Error)?.message, ToastType.Error)
		},
	})

	const addNewEdgeMutation = useMutation({
		mutationFn: addNewEdge,
		onError: (error) => {
			showToast((error as Error)?.message, ToastType.Error)
		},
	})

	const addGraphMutation = useMutation({
		mutationFn: addGraph,
		onSuccess: async (data) => {
			setGraphExecution(initialGraphExecution)
			const variables = serializeNode(
				{
					id: "1",
					type: "variables",
					data: {
						nodeData: {
							name: "Variáveis_1",
						},
						graphId: "",
					},
					position: { x: 250, y: 20 },
					size: {
						...nodesDefaultSizes[
							"variables" as keyof typeof nodesDefaultSizes
						],
					},
				},
				data.id,
			)

			const input = serializeNode(
				{
					id: "2",
					type: "predefinedInput",
					data: {
						nodeData: {
							name: "Input",
							parser: "input_parser",
						},
						graphId: "",
					},
					position: { x: 800, y: 5 },
					size: {
						...nodesDefaultSizes[
							"predefinedInput" as keyof typeof nodesDefaultSizes
						],
					},
				},
				data.id,
			)

			const variableNode = await addNodeMutation.mutateAsync(variables)

			const inputNode = await addNodeMutation.mutateAsync(input)

			addNewEdgeMutation.mutateAsync({
				from_node: variableNode.id,
				to_node: inputNode.id,
			})

			Promise.all(
				defaultInputVariables.map((variable) =>
					addInputVariableMutation.mutateAsync({
						...variable,
						graph: data.id,
					}),
				),
			)

			navigate(`/graph/${data.id}`)
		},
	})

	function onAddGraph() {
		addGraphMutation.mutate({
			name: "Novo Agente",
		})
	}

	if (
		!isAdminUserFeatureFlagEnabled &&
		isAdminUserFeatureFlagEnabled !== undefined
	)
		return <Navigate to="/" />

	if (!data) return

	return (
		<div className="flex flex-col gap-8 w-full ">
			<div className="flex items-center justify-between ">
				<h3>Agentes</h3>
				<div className="flex items-center gap-4">
					<Button
						variant={ButtonVariant.Contained}
						icon={<PlusIcon />}
						onClick={onAddGraph}
					>
						Novo agente
					</Button>
				</div>
			</div>

			<div className="w-full flex items-center px-4 ">
				{columnHeader.map((column) => (
					<div key={column.key} className={`${column.width}`}>
						<p className="font-semibold text-dark-blue-1">
							{column.title}
						</p>
					</div>
				))}
			</div>
			<div className="flex flex-col gap-6">
				{data.results.map((graph) => (
					<Prompts
						columnHeader={columnHeader}
						key={graph.id}
						graph={graph}
						setGraphToDelete={setGraphToDelete}
					/>
				))}
			</div>

			{graphToDelete && (
				<DeleteModal
					hasConfirm
					confirmName={graphToDelete.name}
					open={!!graphToDelete}
					setOpen={setGraphToDelete}
					title="Excluir agente"
					handleDelete={() =>
						deleteGraphMutation.mutate(graphToDelete.id)
					}
					description={
						<>
							<p className="text-brand-gray-3 mb-1 ">
								Tem certeza de que deseja excluir o agente{" "}
								<span className="font-semibold">
									“{graphToDelete.name}”
								</span>
							</p>
							<p className="text-brand-gray-3 mb-1 ">
								Digite o nome do agente para confirmar.
							</p>
						</>
					}
				/>
			)}
		</div>
	)
}
