export default function NodeTextArea({
	value,
	onChange,
	placeholder,
	className,
	disabled,
	...props
}: {
	value?: string
	onChange?: (e: any) => void
	placeholder?: string
	className?: string
	disabled?: boolean
}) {
	return (
		<textarea
			className={`nodrag  nowheel p-3 rounded-lg border-[#F0F0F0] placeholder:text-[#B0B0B0] min-h-[104px] w-full ${className}`}
			value={value}
			onChange={onChange}
			placeholder={placeholder}
			disabled={disabled}
			{...props}
		/>
	)
}
