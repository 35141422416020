export const autocompleteSuggestions = [
	"Ação Civil Pública Cível",
	"Ação Penal - Procedimento Ordinário",
	"Ação Penal - Procedimento Sumário",
	"Ação Rescisória",
	"Agravo de Execução Penal",
	"Agravo de Instrumento",
	"Agravo de Instrumento em Recurso Extraordinário",
	"Agravo Interno Cível",
	"Agravo Interno Criminal",
	"Agravo Regimental Cível",
	"Apelação",
	"Apelação Cível",
	"Apelação Criminal",
	"Carta Testemunhável",
	"Cautelar Inominada",
	"Cautelar Inominada Criminal",
	"Conflito de Competência",
	"Conflito de competência cível",
	"Conflito de Competência Infância e Juventude",
	"Conflito de Jurisdição",
	"Correição Parcial Cível",
	"Correição Parcial Criminal",
	"Correição Parcial ou Reclamação Correicional",
	"Crimes de Calúnia, Injúria e Difamação de Competência do Juiz Singular",
	"Cumprimento de sentença",
	"Cumprimento Provisório de Sentença",
	"Declaratória de Constitucionalidade",
	"Desaforamento de Julgamento",
	"Direta de Inconstitucionalidade",
	"Dissídio Coletivo de Greve",
	"Embargos",
	"Embargos à Execução",
	"Embargos de Declaração Cível",
	"Embargos de Declaração Criminal",
	"Embargos de Terceiro",
	"Embargos de Terceiro Cível",
	"Embargos Infringentes",
	"Embargos Infringentes e de Nulidade",
	"Exceção da Verdade",
	"Exceção de Coisa Julgada",
	"Exceção de Impedimento",
	"Exceção de Incompetência de Juízo",
	"Exceção de Suspeição",
	"Exceção de Suspeição Infância e Juventude",
	"Exibição de Documento ou Coisa Cível",
	"Exibição de Documento ou Coisa Infância e Juventude",
	"Habeas Corpus Cível",
	"Habeas Corpus Criminal",
	"Habeas Data",
	"Habeas Data Criminal",
	"Habilitação",
	"Impugnação ao Valor da Causa Cível",
	"Impugnação de Assistência Judiciária",
	"Incidente De Arguição de Inconstitucionalidade Cível",
	"Incidente de Arguição de Inconstitucionalidade Criminal",
	"Incidente de Assunção de Competência",
	"Incidente de Falsidade",
	"Incidente de Impedimento Cível",
	"Incidente de Resolução de Demandas Repetitivas",
	"Incidente de Suspeição Cível",
	"Inquérito Policial",
	"Insanidade Mental do Acusado",
	"Interpelação",
	"Intervenção em Município",
	"Mandado de Injunção",
	"Mandado de Segurança Cível",
	"Mandado de Segurança Coletivo",
	"Mandado de Segurança Criminal",
	"Mandado de Segurança Infância e Juventude",
	"Notificação para Explicações",
	"Oposição",
	"Pedido de Efeito Suspensivo à Apelação",
	"Pedido de Providências",
	"Petição Cível",
	"Petição Criminal",
	"Procedimento Comum Cível",
	"Procedimento Investigatório Criminal (PIC-MP)",
	"Processo Administrativo",
	"Processo Cautelar",
	"Produção Antecipada da Prova",
	"Reclamação",
	"Reclamação Criminal",
	"Recurso Administrativo",
	"Recurso em sentido estrito",
	"Recurso em Sentido Estrito",
	"Remessa Necessária Cível",
	"Remessa Necessária Criminal",
	"Representação Criminal",
	"Restauração de Autos",
	"Restituição de Coisas Apreendidas",
	"Revisão Criminal",
	"Suspensão de Liminar e de Sentença",
	"Suspensão de Liminar ou Antecipação de Tutela",
	"Suspensão de Segurança Cível",
	"Termo Circunstanciado",
	"Tutela Antecipada Antecedente",
	"Tutela Cautelar Antecedente",
	"Tutela Provisória de Urgência e Tutela Provisória de Evidência",
]
