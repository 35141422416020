import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDropzone } from "react-dropzone"
import CourtModal from "./subcomponents/CourtModal"
import AttachInitialStep from "./steps/AttachInitialStep"
import AddDetailsStep from "./steps/AddDetailsStep"
import DocumentCompleted from "./steps/DocumentCompleted"
import useLegalDocumentV2 from "hooks/useLegalDocumentV2"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useNavigate, useParams } from "react-router-dom"
import useFlowBuilder from "hooks/useFlowBuilder"
import useAgentBuilder from "hooks/useAgentBuilder"
import { Flow } from "types/flowBuilder"
import { AxiosError } from "axios"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import debounce from "lodash/debounce"
import { RetrieveLegalDocumentResponse } from "types/legalDocumentV2"
import { hideSidebarAtom } from "components/sidebar/atoms"
import { useAtom } from "jotai"
import { WS_SERVER } from "config"
import { useJwt } from "hooks/useJwt"
import { getOldDocumentStatus } from "utils/getOldDocumentStatus"

enum Step {
	ATTACH_INITIAL = 1,
	ADD_DETAILS,
	DOCUMENT_COMPLETE,
}

export interface OptionalUpload {
	file: File
	status: "loading" | "success"
	id?: string
	fileSize?: number
}

export interface Tag {
	icon?: JSX.Element
	text: string
	docId?: string
}

export default function NewDocumentPage() {
	const { documentId } = useParams()
	const queryClient = useQueryClient()
	const {
		editLegalDocument,
		legalDocumentGenerateContent,
		retrieveLegalDocument,
		deleteUploadedFile,
		uploadOptionalAttachment,
		uploadMainAttachment,
	} = useLegalDocumentV2()
	const { getGraphExecution } = useAgentBuilder()
	const { listValidFlows } = useFlowBuilder()
	const [jwt] = useJwt()
	const navigate = useNavigate()
	const [, setHideSidebar] = useAtom(hideSidebarAtom)
	const [step, setStep] = useState<Step | null>(null)
	const [openCourtModal, setOpenCourtModal] = useState(false)
	const [selectedCourts, setSelectedCourts] = useState<string[]>([])
	const [selectedWriting, setSelectedWriting] = useState<
		"objetiva" | "detalhada"
	>("objetiva")
	const [focusTextarea, setFocusTextarea] = useState(false)
	const [optionalUploads, setOptionalUploads] = useState<OptionalUpload[]>([])
	const [userInput, setUserInput] = useState<string>("")
	const [selectedFlow, setSelectedFlow] = useState<Flow | null>(null)
	const [graphExecutionStatus, setGraphExecutionStatus] = useState<
		"SUCCESS" | "ERROR" | "START" | ""
	>("")

	const wsRef = useRef<WebSocket>()
	const lastSentInput = useRef<string>("")
	const { data: legalDocument } = useQuery(
		["retrieveLegalDocument", documentId],
		() => retrieveLegalDocument(documentId || ""),
		{
			refetchOnWindowFocus: false,
		},
	)

	const { data: flows } = useQuery(["listValidFlows"], listValidFlows)
	const oldDocumentStatus = getOldDocumentStatus(legalDocument)

	const graphExecution = legalDocument?.graph_execution || ""
	const { data: graph } = useQuery(
		["graph", graphExecution],
		() => getGraphExecution(graphExecution),
		{
			refetchOnWindowFocus: false,
			enabled: Boolean(graphExecution),
		},
	)

	const uploadMainAttachmentMutation = useMutation({
		mutationFn: uploadMainAttachment,
		onError: (error: AxiosError) => {
			if (error.response?.status === 400) {
				showToast(
					"Arquivo com excesso de texto. Por favor, tente outro.",
					ToastType.Error,
				)
			} else if (error.response?.status === 413) {
				showToast(
					"Arquivo muito grande. Anexe até 15MB em arquivos.",
					ToastType.Error,
				)
			} else {
				showToast(
					"Um erro inesperado aconteceu. Por favor, tente novamente.",
					ToastType.Error,
				)
			}
		},
	})

	const uploadOptionalAttachmentMutation = useMutation({
		mutationFn: uploadOptionalAttachment,
		onError: (error: AxiosError) => {
			if (error.response?.status === 400) {
				showToast(
					"Arquivo com excesso de texto. Por favor, tente outro.",
					ToastType.Error,
				)
			} else if (error.response?.status === 413) {
				showToast(
					"Arquivo muito grande. Anexe até 15MB em arquivos.",
					ToastType.Error,
				)
			} else {
				showToast(
					"Um erro inesperado aconteceu. Por favor, tente novamente.",
					ToastType.Error,
				)
			}
		},
	})

	const legalDocumentGenerateContentMutation = useMutation({
		mutationFn: legalDocumentGenerateContent,
		onSuccess: () => {},
	})

	const editLegalDocumentMutation = useMutation({
		mutationFn: editLegalDocument,
		onError: () => {
			showToast(
				"Um erro inesperado aconteceu. Por favor, tente novamente.",
				ToastType.Error,
			)
		},
	})

	const deleteUploadedFileMutation = useMutation({
		mutationFn: deleteUploadedFile,
	})

	const handleEditLegalDocument = useCallback(
		async (
			data: Partial<RetrieveLegalDocumentResponse>,
			callback?: () => void,
		) => {
			if (!legalDocument) return

			await editLegalDocumentMutation.mutateAsync({
				id: documentId || "",
				payload: {
					...data,
				},
			})
			if (callback) {
				callback()
			}
			handleInvalidateQueries()
		},

		[documentId, editLegalDocumentMutation, legalDocument],
	)

	const handleGenerateContent = useCallback(() => {
		if (!legalDocument) return
		legalDocumentGenerateContentMutation.mutate({
			id: documentId || "",
			payload: {
				courts: selectedCourts,
				writing_tone: selectedWriting,
				main_attachments: [],
				optional_attachments: optionalUploads
					.map((upload) => upload.id)
					.filter((id): id is string => id !== undefined),
				user_input: userInput,
			},
		})
		setStep(Step.DOCUMENT_COMPLETE)
	}, [
		legalDocument,
		documentId,
		selectedCourts,
		selectedWriting,
		optionalUploads,
		userInput,
		legalDocumentGenerateContentMutation,
	])

	const onDrop = async (acceptedFiles: File[]) => {
		if (acceptedFiles.length === 0) return
		const file = acceptedFiles[0]
		const formData = new FormData()
		formData.append("file_path", file)
		try {
			const response = await uploadMainAttachmentMutation.mutateAsync({
				id: documentId,
				payload: formData,
			})

			handleEditLegalDocument(
				{
					main_attachments: [response.id],
					user_input: response.summary,
				},
				() => {
					setStep(Step.ADD_DETAILS)
				},
			)
		} catch (error) {
			showToast(
				"Um erro inesperado aconteceu. Por favor, tente novamente.",
				ToastType.Error,
			)
		}
	}

	const mainFile = useDropzone({
		onDrop,
		accept: {
			"application/pdf": [],
			"text/plain": [],
			"application/vnd.openxmlformats-officedocument.wordprocessingml.document":
				[],
		},
		multiple: false,
		maxSize: 15 * 1024 * 1024,
		onDropRejected() {
			showToast(
				"O arquivo não é suportado ou é muito grande. Por favor, tente outro.",
				ToastType.Error,
			)
		},
		disabled: uploadMainAttachmentMutation.isLoading,
	})

	function handleInvalidateQueries() {
		queryClient.invalidateQueries(["retrieveLegalDocument", documentId])
	}

	const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const selectedFiles = e.target.files
		if (!selectedFiles) return
		const filesArray = Array.from(selectedFiles)
		const newUploads = filesArray.map((file, index) => ({
			file,
			status: "loading" as const,
			id: `${index + 1}`,
		}))
		setOptionalUploads((prev) => [...prev, ...newUploads])
		await Promise.all(
			newUploads.map(async (upload) => {
				const formData = new FormData()
				formData.append("file_path", upload.file)
				try {
					const response =
						await uploadOptionalAttachmentMutation.mutateAsync({
							id: documentId,
							payload: formData,
						})
					setOptionalUploads((prevUploads) =>
						prevUploads.map((u) =>
							u.file === upload.file
								? { ...u, status: "success", id: response.id }
								: u,
						),
					)
					return response.id
				} catch (error) {
					setOptionalUploads((prevUploads) =>
						prevUploads.filter((u) => u.id !== upload.id),
					)
					return ""
				}
			}),
		)
	}
	const handleRemoveOptionalUpload = async (id: string) => {
		setOptionalUploads((prev) => prev.filter((upload) => upload.id !== id))
		await handleEditLegalDocument({
			optional_attachments: optionalUploads
				.filter((upload) => upload.id !== id)
				.map((upload) => upload.id) as string[],
		})
		deleteUploadedFileMutation.mutateAsync(id)
	}

	const debouncedEdit = useMemo(
		() =>
			debounce((value: string) => {
				if (value !== lastSentInput.current) {
					lastSentInput.current = value
					handleEditLegalDocument({ user_input: value })
				}
			}, 2000),
		[handleEditLegalDocument],
	)

	useEffect(() => {
		debouncedEdit(userInput)
		return () => {
			debouncedEdit.cancel()
		}
	}, [userInput, debouncedEdit])

	useEffect(() => {
		if (!legalDocument || !flows || flows.length === 0) return

		const {
			flow: documentFlow,
			optional_attachments,
			attached_documents,
			writing_tone,
			courts,
			user_input,
			graph_execution,
			main_attachments,
		} = legalDocument

		const currentFlow =
			flows.find((flow) => flow.id === documentFlow?.id) || null
		setSelectedFlow(currentFlow)
		const flowHasMainUpload =
			currentFlow?.addons?.includes("main_upload") || false

		setOptionalUploads(
			optional_attachments?.map((id) => {
				const file = attached_documents.find((doc) => doc.id === id)
				return {
					file: new File([], file?.file_name || "", {
						type: file?.file_type || "",
					}),
					status: "success",
					id,
					fileSize: file?.file_size,
				}
			}) || [],
		)

		setSelectedWriting(writing_tone as "objetiva" | "detalhada")
		setSelectedCourts(courts)
		if (!userInput) {
			setUserInput(user_input || "")
		}

		if (step === Step.DOCUMENT_COMPLETE) return

		if (
			graph_execution ||
			(oldDocumentStatus && oldDocumentStatus === "SUCCESS")
		) {
			setStep(Step.DOCUMENT_COMPLETE)
		} else {
			setStep(
				flowHasMainUpload &&
					!main_attachments &&
					step !== Step.ADD_DETAILS
					? Step.ATTACH_INITIAL
					: Step.ADD_DETAILS,
			)
		}
	}, [legalDocument, flows, setHideSidebar])

	useEffect(() => {
		if (step === Step.DOCUMENT_COMPLETE) {
			setHideSidebar(true)
		} else {
			setHideSidebar(false)
		}
	}, [step])
	useEffect(() => {
		const socket = new WebSocket(`${WS_SERVER.baseUrl}/?token=${jwt}`)
		wsRef.current = socket
		socket.onopen = () => {}
		socket.onmessage = (e) => {
			if (e.data === "ping") {
				socket.send("pong")
				return
			}
			try {
				const data = JSON.parse(e.data)
				if (data.event_type === "GRAPH_EXECUTION_STATUS_UPDATE") {
					handleInvalidateQueries()
					setGraphExecutionStatus(data.payload.reason)
				} else {
					console.warn("Tipo de evento não tratado:", data.event_type)
				}
			} catch (error) {
				console.error("Erro ao fazer parse da mensagem:", e.data, error)
			}
		}
		socket.onerror = () => {}
		socket.onclose = () => {}
		return () => {
			socket.close(1000, "finished")
		}
	}, [jwt])

	useEffect(() => {
		if (graph) {
			if (graph.status === "ERROR") {
				showToast(
					"Ocorreu um erro ao gerar o documento. Tente novamente mais tarde.",
					ToastType.Error,
				)

				setTimeout(() => {
					navigate("/")
				}, 500)
			}
			setGraphExecutionStatus(graph.status)
		}
	}, [graph])

	const renderStep = useCallback(() => {
		switch (step) {
			case Step.ATTACH_INITIAL:
				return (
					<AttachInitialStep
						mainFile={mainFile}
						selectedFlow={selectedFlow}
						onSkipStep={() => setStep(Step.ADD_DETAILS)}
						isLoading={uploadMainAttachmentMutation.isLoading}
					/>
				)
			case Step.ADD_DETAILS:
				return (
					<AddDetailsStep
						focusTextarea={focusTextarea}
						setFocusTextarea={setFocusTextarea}
						selectedCourts={selectedCourts}
						setOpenCourtModal={setOpenCourtModal}
						handleFileSelect={handleFileSelect}
						optionalUploads={optionalUploads}
						selectedWriting={selectedWriting}
						setSelectedWriting={setSelectedWriting}
						selectedFlow={selectedFlow}
						handleEditLegalDocument={handleEditLegalDocument}
						userInput={userInput}
						setUserInput={setUserInput}
						handleGenerateContent={handleGenerateContent}
						handleRemoveOptionalUpload={handleRemoveOptionalUpload}
					/>
				)
			case Step.DOCUMENT_COMPLETE:
			default:
				return (
					<DocumentCompleted
						documentId={documentId || ""}
						graphExecutionStatus={
							graphExecutionStatus || oldDocumentStatus
						}
						legalDocument={legalDocument}
					/>
				)
		}
	}, [
		step,
		mainFile,
		selectedFlow,
		focusTextarea,
		selectedCourts,
		optionalUploads,
		selectedWriting,
		userInput,
		handleEditLegalDocument,
		handleGenerateContent,
		documentId,
		graphExecutionStatus,
	])

	if (!legalDocument || !step) return null

	return (
		<>
			{renderStep()}
			{openCourtModal && (
				<CourtModal
					open={openCourtModal}
					setOpen={setOpenCourtModal}
					setSelectedCourts={setSelectedCourts}
					selectedCourts={selectedCourts}
					handleEditLegalDocument={handleEditLegalDocument}
				/>
			)}
		</>
	)
}
