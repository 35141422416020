import CloseIcon from "icons/CloseIcon"
import RangeSlider from "components/range-slider"
import { useAtom } from "jotai"
import { retrievalRangesAtom } from "../atoms"
import { ButtonVariant } from "components/button/types"
import Center from "components/center"
import { defaultRetrievalRanges } from "../constants"
import { useSaveNode } from "../hooks/useSaveNode"
import { useReactFlow } from "@xyflow/react"
import { Node } from "./Flow"
import { useParams } from "react-router-dom"
import Button from "components/button-v2"
import ClickAwayListener from "react-click-away-listener"

export default function RetrievalConfig({
	setOpenRetrievalConfig,
	openRetrievalConfig,
}: {
	setOpenRetrievalConfig: (value: string | null) => void
	openRetrievalConfig: string
}) {
	const [retrievalRanges, setRetrievalRanges] = useAtom(retrievalRangesAtom)
	const currentRange = retrievalRanges.find(
		(r) => r.nodeId === openRetrievalConfig,
	)
	const { getNode } = useReactFlow()
	const { graphId = "" } = useParams()

	const currentNode = getNode(openRetrievalConfig) as Node | undefined

	if (!currentRange) return null

	function onResetDefault() {
		const newRanges = retrievalRanges.map((r) => {
			if (r.nodeId === openRetrievalConfig) {
				return {
					...r,
					retrievalRanges: defaultRetrievalRanges[0].retrievalRanges,
				}
			}
			return r
		})
		setRetrievalRanges(newRanges)
	}

	useSaveNode({
		currentNode,
		graphId,
		id: openRetrievalConfig,
		additionalParamToSave: retrievalRanges,
	})

	return (
		<ClickAwayListener onClickAway={() => setOpenRetrievalConfig(null)}>
			<div className="bg-white w-[330px] h-[calc(100vh-52px)] fixed z-50 right-0 top-[52px] border-l-[1px] border-[#F0F0F0]">
				<div className="h-full flex flex-col justify-between">
					<div className="h-full">
						<div className="p-6 flex flex-col gap-6">
							<div className="flex items-center justify-between text-dark-blue-1">
								<p className="text-cta-1">Configurações</p>
								<div
									onClick={() => setOpenRetrievalConfig(null)}
									className="cursor-pointer"
								>
									<CloseIcon size="20" />
								</div>
							</div>

							<div className="w-full h-[1px] bg-[#F0F0F0] " />
						</div>
						<div className="p-6 pt-0 flex flex-col gap-6">
							{currentRange.retrievalRanges.map(
								(range, index) => (
									<RangeSlider
										title={range.title}
										max={range.max}
										step={
											range.id === "similarity_score"
												? 0.05
												: 1
										}
										onChange={(value: number) => {
											setRetrievalRanges((prev) =>
												prev.map((r) =>
													r.nodeId ===
													openRetrievalConfig
														? {
																...r,
																retrievalRanges:
																	r.retrievalRanges.map(
																		(rr) =>
																			rr.id ===
																			range.id
																				? {
																						...rr,
																						value,
																				  }
																				: rr,
																	),
														  }
														: r,
												),
											)
										}}
										value={range.value}
										key={index}
									/>
								),
							)}
						</div>
					</div>

					<Center className="px-6 pb-6">
						<Button
							variant={ButtonVariant.Outlined}
							className="w-full"
							onClick={onResetDefault}
						>
							Reset default
						</Button>
					</Center>
				</div>
			</div>
		</ClickAwayListener>
	)
}
