import { useState, useEffect } from "react"
import Modal from "components/modal"
import { IoIosArrowUp } from "react-icons/io"
import Center from "components/center"
import Button from "components/button-v2"
import { ButtonVariant } from "components/button/types"
import { otherCourts, TJs, TRFs, TRTs } from "pages/GraphPage/constants"
import { RetrieveLegalDocumentResponse } from "types/legalDocumentV2"

interface CheckboxLabelProps {
	label: string
	value: string
	checked: boolean
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	className?: string
}

function CheckboxLabel({
	label,
	value,
	checked,
	onChange,
	className,
}: CheckboxLabelProps) {
	return (
		<div
			className={`flex items-center p-2 gap-2 h-[36px] hover:bg-[#F5F5F5] ${className} cursor-pointer`}
			onClick={() => {
				onChange({
					...({} as React.ChangeEvent<HTMLInputElement>),
					target: { ...({} as HTMLInputElement), checked: !checked },
				})
			}}
		>
			<input
				type="checkbox"
				value={value}
				checked={checked}
				className="rounded-sm focus:shadow-none focus:ring-0 focus:outline-none"
			/>
			<p>{label}</p>
		</div>
	)
}

export default function CourtModal({
	open,
	setOpen,
	selectedCourts,
	setSelectedCourts,
	handleEditLegalDocument,
}: {
	open: boolean
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
	selectedCourts: string[]
	setSelectedCourts: React.Dispatch<React.SetStateAction<string[]>>
	handleEditLegalDocument: (
		data: Partial<RetrieveLegalDocumentResponse>,
	) => void
}) {
	const [groupsVisibility, setGroupsVisibility] = useState({
		TJs: false,
		TRTs: false,
		TRFs: false,
	})
	const [tempSelectedCourts, setTempSelectedCourts] =
		useState<string[]>(selectedCourts)

	useEffect(() => {
		if (open) {
			setTempSelectedCourts(selectedCourts)
		}
	}, [open, selectedCourts])

	const toggleGroup = (group: "TJs" | "TRTs" | "TRFs") => {
		setGroupsVisibility((prev) => ({
			...prev,
			[group]: !prev[group],
		}))
	}

	const handleCheckbox = (
		e: React.ChangeEvent<HTMLInputElement>,
		value: string,
	) => {
		setTempSelectedCourts((prev) =>
			e.target.checked
				? [...prev, value]
				: prev.filter((court) => court !== value),
		)
	}

	const handleGroupCheckbox = (
		e: React.ChangeEvent<HTMLInputElement>,
		groupValues: string[],
	) => {
		setTempSelectedCourts((prev) => {
			if (e.target.checked) {
				const novosValores = groupValues.filter(
					(val) => !prev.includes(val),
				)
				return [...prev, ...novosValores]
			} else {
				return prev.filter((val) => !groupValues.includes(val))
			}
		})
	}

	const allCourts = [
		...Object.values(otherCourts),
		...Object.values(TJs),
		...Object.values(TRTs),
		...Object.values(TRFs),
	]

	const allSelected = allCourts.every((court) =>
		tempSelectedCourts.includes(court),
	)

	const tjsValues = Object.values(TJs)
	const tjsAllSelected = tjsValues.every((val) =>
		tempSelectedCourts.includes(val),
	)

	const trtsValues = Object.values(TRTs)
	const trtsAllSelected = trtsValues.every((val) =>
		tempSelectedCourts.includes(val),
	)

	const trfsValues = Object.values(TRFs)
	const trfsAllSelected = trfsValues.every((val) =>
		tempSelectedCourts.includes(val),
	)

	return (
		<Modal
			openModal={open}
			setOpenModal={setOpen}
			title="Escolher tribunais"
			width="w-[560px] "
			titleClassName="!text-[20px] text-[#3D3D3D] !font-Red-Hat-Display"
			newButtonClose={true}
		>
			<div className="mt-6 text-[14px] text-[#6D6D6D] max-h-[418px] overflow-y-auto pr-2 !font-Red-Hat-Display">
				<CheckboxLabel
					label="Todos"
					value="todos"
					checked={allSelected}
					onChange={(e) => {
						if (e.target.checked) {
							setTempSelectedCourts(allCourts)
						} else {
							setTempSelectedCourts([])
						}
					}}
				/>
				<div>
					{Object.entries(otherCourts).map(([key, value]) => (
						<CheckboxLabel
							key={key}
							label={`${key} (${value})`}
							value={value}
							checked={tempSelectedCourts.includes(value)}
							onChange={(e) => handleCheckbox(e, value)}
							className="pl-[34px] "
						/>
					))}
					<div className="flex items-center relative hover:bg-[#F5F5F5] pl-[12px]">
						<div
							className="cursor-pointer "
							onClick={() => toggleGroup("TJs")}
						>
							<IoIosArrowUp
								className={`text-[#6D6D6D] transform transition-transform duration-200 ${
									groupsVisibility.TJs ? "" : "rotate-180"
								}`}
							/>
						</div>
						<CheckboxLabel
							label="Tribunais de Justiça (TJ)"
							value="tjs-group"
							checked={tjsAllSelected}
							onChange={(e) => handleGroupCheckbox(e, tjsValues)}
						/>
					</div>
					{groupsVisibility.TJs && (
						<div>
							{Object.entries(TJs).map(([key, value]) => (
								<CheckboxLabel
									key={key}
									label={`${key} (${value})`}
									value={value}
									checked={tempSelectedCourts.includes(value)}
									onChange={(e) => handleCheckbox(e, value)}
									className="pl-[60px]"
								/>
							))}
						</div>
					)}
					<div className="flex items-center  hover:bg-[#F5F5F5] pl-[12px]">
						<div
							className="cursor-pointer"
							onClick={() => toggleGroup("TRTs")}
						>
							<IoIosArrowUp
								className={`text-[#6D6D6D] transform transition-transform duration-200 ${
									groupsVisibility.TRTs ? "" : "rotate-180"
								}`}
							/>
						</div>
						<CheckboxLabel
							label="Tribunais Regionais do Trabalho (TRT)"
							value="trts-group"
							checked={trtsAllSelected}
							onChange={(e) => handleGroupCheckbox(e, trtsValues)}
						/>
					</div>
					{groupsVisibility.TRTs && (
						<div>
							{Object.entries(TRTs).map(([key, value]) => (
								<CheckboxLabel
									key={key}
									label={`${key} (${value})`}
									value={value}
									checked={tempSelectedCourts.includes(value)}
									onChange={(e) => handleCheckbox(e, value)}
									className="pl-[60px]"
								/>
							))}
						</div>
					)}
					<div className="flex items-center  hover:bg-[#F5F5F5] pl-[12px]">
						<div
							className="cursor-pointer "
							onClick={() => toggleGroup("TRFs")}
						>
							<IoIosArrowUp
								className={`text-[#6D6D6D] transform transition-transform duration-200 ${
									groupsVisibility.TRFs ? "" : "rotate-180"
								}`}
							/>
						</div>
						<CheckboxLabel
							label="Tribunais Regionais Federais (TRF)"
							value="trfs-group"
							checked={trfsAllSelected}
							onChange={(e) => handleGroupCheckbox(e, trfsValues)}
						/>
					</div>
					{groupsVisibility.TRFs && (
						<div>
							{Object.entries(TRFs).map(([key, value]) => (
								<CheckboxLabel
									key={key}
									label={`${key} (${value})`}
									value={value}
									checked={tempSelectedCourts.includes(value)}
									onChange={(e) => handleCheckbox(e, value)}
									className="pl-[60px]"
								/>
							))}
						</div>
					)}
				</div>
			</div>
			<Center className="w-full gap-4 mt-6">
				<Button
					variant={ButtonVariant.Outlined}
					className="w-full"
					onClick={() => setOpen(false)}
				>
					Cancelar
				</Button>
				<Button
					className="w-full"
					onClick={() => {
						if (tempSelectedCourts.length === 0) {
							setSelectedCourts(allCourts)
							setOpen(false)
							handleEditLegalDocument({
								courts: allCourts,
							})
						} else {
							setSelectedCourts(tempSelectedCourts)
							setOpen(false)
							handleEditLegalDocument({
								courts: tempSelectedCourts,
							})
						}
					}}
				>
					Salvar
				</Button>
			</Center>
		</Modal>
	)
}
