import Center from "components/center"
import { GoAlertFill } from "react-icons/go"
import { LuClock7 } from "react-icons/lu"
import {
	TbCurrencyDollar,
	TbPlayerPlayFilled,
	TbPlayerStopFilled,
} from "react-icons/tb"
import useAgentBuilder from "hooks/useAgentBuilder"
import { useMutation, useQuery } from "@tanstack/react-query"

import {
	courtAtom,
	graphExecutionAtom,
	inputVariablesAtom,
	variablesAtom,
} from "../atoms"
import { useAtom } from "jotai"
import { getExecutionTime } from "utils/getExecutionTime"
import { formatCost } from "utils/formatCost"
import LoadingSpinner from "pages/MyDocumentsPage/subcomponents/LoadingSpinner"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import Flex from "components/flex"

export default function GraphLog({ graphId }: { graphId: string }) {
	const [variables] = useAtom(variablesAtom)
	const [inputVariables] = useAtom(inputVariablesAtom)
	const { graphExecutionStart, getGraph, statusUpdate } = useAgentBuilder()
	const [graphExecution] = useAtom(graphExecutionAtom)
	const [selectedCourts] = useAtom(courtAtom)

	const { data } = useQuery(["graph", graphId], () => getGraph(graphId), {
		refetchOnWindowFocus: false,
	})

	const statusUpdateMutation = useMutation({
		mutationFn: statusUpdate,
		onError: (error) => {
			showToast((error as Error)?.message, ToastType.Error)
		},
	})

	const graphExecutionStartMutation = useMutation({
		mutationFn: graphExecutionStart,
		onError: (error) => {
			showToast((error as Error)?.message, ToastType.Error)
		},
	})

	function transformVariablesTosend() {
		if (!data) return
		const allVariables = [...inputVariables, ...variables].filter(
			(variable) => variable.name,
		)

		const validVariables = allVariables.filter((variable) => {
			const variableName = variable.name.replace(/[{}]/g, "")
			return data.input_variables.some(
				(graphItem) => graphItem.name === variableName,
			)
		})

		const variablesToSend = validVariables.reduce<Record<string, any>>(
			(acc, item) => {
				const key = item.name.replace(/[{}]/g, "")
				acc[key] = {
					key,
					type: item.type,
					value:
						item.type === "FILES"
							? Array.isArray(item.value)
								? item.value.map((file) => file.id)
								: item.value
							: item.value,
				}
				return acc
			},
			{},
		)

		if (
			data.input_variables.some(
				(graphItem) => graphItem.name === "tribunais",
			)
		) {
			variablesToSend["tribunais"] = {
				key: "tribunais",
				type: "COURTS",
				value: selectedCourts,
			}
		}

		return variablesToSend
	}

	function getErrorObjects() {
		const errors: any[] = []

		Object.keys(graphExecution).forEach((key) => {
			if (key === "graph_execution") return
			const value = graphExecution[key]

			if (Array.isArray(value)) {
				value.forEach((item) => {
					if (item.reason === "ERROR") {
						errors.push(item)
					}
				})
			} else if (
				value &&
				typeof value === "object" &&
				value.reason === "ERROR"
			) {
				errors.push(value)
			}
		})

		return errors
	}
	const graphErros = getErrorObjects()

	const graphReasonStart = graphExecution.graph_execution.reason === "START"

	return (
		<Center className="gap-4 absolute top-[72px] right-4 ">
			{graphExecution?.graph_execution?.reason === "ERROR" && (
				<Center className="rounded p-1 gap-1 bg-red-opacity h-[24px] relative group ">
					<div className="text-light-red">
						<GoAlertFill size="16px" />
					</div>
					<p className="font-semibold text-small-1">Erro</p>
					<div className="absolute top-8 right-0 bg-[#FFF1F1] border border-[#FFC7C7] group-hover:block hidden text-cta-2 text-[#3D3D3D] p-4 rounded-[8px] w-[384px]">
						<Flex className="gap-1 mb-4">
							<div className="text-light-red">
								<GoAlertFill size="16px" />
							</div>

							<p className="font-bold">Erros detectados</p>
						</Flex>

						{graphErros.length > 0 && (
							<div className="flex flex-col gap-4">
								{graphErros.map((error, index) => {
									if (!error.detail.error) return null
									return (
										<div
											className="rounded-[8px] border border-[#F0F0F0] bg-white p-3"
											key={index}
										>
											<p className="font-bold">
												Node: {error?.data?.node_name}
											</p>

											<p className="mt-4">
												{error.detail.error}
											</p>
										</div>
									)
								})}
							</div>
						)}
					</div>
				</Center>
			)}

			<Center className="rounded p-1 gap-1  border-[1px] border-[#ECF5FF] bg-[#F5F5F5] h-[24px]">
				<LuClock7 size="16px" />

				{graphReasonStart ? (
					<LoadingSpinner className="!w-[15px]" />
				) : (
					<p className="font-semibold text-small-1">
						{getExecutionTime(
							graphExecution?.graph_execution?.data?.start_time,
							graphExecution?.graph_execution?.data?.end_time,
						)}
					</p>
				)}
			</Center>

			<Center className="rounded p-1 gap-1  border-[1px] border-[#ECF5FF] bg-[#F5F5F5] h-[24px]">
				<TbCurrencyDollar size="16px" />
				{graphReasonStart ? (
					<LoadingSpinner className="!w-[15px]" />
				) : (
					<p className="font-semibold text-small-1">
						{formatCost(
							graphExecution?.graph_execution?.data?.cost || 0,
						)}
					</p>
				)}
			</Center>
			{graphReasonStart ? (
				<Center
					className="rounded p-1 gap-1  border-[1px] border-[#ECF5FF] bg-white w-[36px] h-[36px] text-light-blue-4 cursor-pointer "
					onClick={() =>
						statusUpdateMutation.mutate({
							graph_execution:
								graphExecution.graph_execution.graph_execution,
							reason: "STOP",
						})
					}
				>
					<TbPlayerStopFilled size="20px" />
				</Center>
			) : (
				<Center
					className="rounded p-1 gap-1  border-[1px] border-[#ECF5FF] bg-white w-[36px] h-[36px] text-light-blue-4 cursor-pointer "
					onClick={() =>
						graphExecutionStartMutation.mutate({
							graph: graphId,
							input_variables: transformVariablesTosend() || {},
						})
					}
				>
					<TbPlayerPlayFilled size="20px" />
				</Center>
			)}
		</Center>
	)
}
