import DotsIcon from "icons/DotsIcon"
import { PromptColumnHeader } from "types/prompts"
import { Dropdown } from "components/dropdown"
import { useState } from "react"
import PromptIcon from "icons/PromptIcon"
import FlowTag from "../FlowTag"
import { Flow } from "types/flowBuilder"
import useFlowBuilder from "hooks/useFlowBuilder"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import DeleteModal from "components/delete-modal"

import { addonsIcons, areaIcons } from "pages/FlowBuilderPage/constants"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"

export default function FlowColumnItem({
	columnHeader,
	result,
	setFlowToEdit,
	setOpenModal,
}: {
	columnHeader: PromptColumnHeader[]
	result: Flow
	setFlowToEdit: (flow: Flow) => void
	setOpenModal: (value: boolean) => void
}) {
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const { editFlow, deleteFlow } = useFlowBuilder()
	const queryClient = useQueryClient()

	const editFlowMutation = useMutation({
		mutationFn: editFlow,
		onSuccess: () => {
			queryClient.invalidateQueries(["listFlows"])

			showToast(
				`Flow “${result.name}” foi ${
					result.active ? "desativado" : "ativado"
				}`,
				result.active ? ToastType.Error : ToastType.Success,
			)
		},
	})

	const deleteFlowMutation = useMutation({
		mutationFn: deleteFlow,
		onSuccess: () => {
			queryClient.invalidateQueries(["listFlows"])
			showToast(`Flow “${result.name}” foi excluído`, ToastType.Error)
		},
	})

	const handleSetStatus = () => {
		editFlowMutation.mutate({
			id: result.id,
			data: {
				...result,
				active: !result.active,
			},
		})
	}

	const handleDelete = () => {
		deleteFlowMutation.mutate(result.id)
	}

	function isValidIconKey(key: string): key is keyof typeof addonsIcons {
		return key in addonsIcons
	}

	const sections = [
		{
			items: [
				{
					label: "Editar",
					onClick: () => {
						setFlowToEdit(result)
						setOpenModal(true)
					},
				},
				{
					label: result.active ? "Desativar" : "Ativar",
					onClick: () => {
						handleSetStatus()
					},
				},
				{
					label: "Excluir",
					deleteLabel: true,
					onClick: () => {
						setOpenDeleteModal(true)
					},
				},
			],
		},
	]

	function renderAddons() {
		const addons = result?.addons || []
		const checkAddons = addons.filter(
			(addon) =>
				addon === "tom_escrita" ||
				addon === "tribunais" ||
				addon === "main_upload",
		)

		if (checkAddons.length > 0) {
			return checkAddons.map((addon) => {
				if (isValidIconKey(addon)) {
					return <span key={addon}>{addonsIcons[addon]}</span>
				}
			})
		}

		return "-"
	}

	return (
		<div
			className="relative rounded bg-white shadow-1 hover:bg-[#FCFCFC] cursor-pointer h-[88px]"
			onClick={() => {}}
		>
			<div className="p-4 flex items-center w-full text-dark-blue-1 text-cta-2 ">
				<div className={`flex flex-col gap-2 ${columnHeader[0].width}`}>
					<div className="flex items-center gap-2">
						<div className="w-[20px] h-[20px]">
							{areaIcons[result.area_of_law]}
						</div>

						<p className=" font-semibold">{result.name}</p>
					</div>

					<div className="flex items-center gap-2 text-[#ACACAC]">
						<PromptIcon size="20" />
						<p className=" text-brand-gray-2">
							{result.graph_name}
						</p>
					</div>
				</div>

				<div className={`${columnHeader[1].width} `}>
					<FlowTag isActive={result.is_valid} />
				</div>

				<div
					className={`${columnHeader[2].width} text-[#6D6D6D] flex items-center  gap-4`}
				>
					{renderAddons()}
				</div>

				<div className={`${columnHeader[3].width}`}>
					<p className=" text-[#646464]">
						{result.attachment_title || "-"}
					</p>
				</div>

				<div className={`${columnHeader[4].width}`}>
					<p className="text-[#646464]  line-clamp-2 ">
						{result.text_placeholder}
					</p>
				</div>

				<div
					className={`${columnHeader[5].width} flex items-center justify-end gap-4 `}
					onClick={(e) => e.stopPropagation()}
				>
					<Dropdown sections={sections} className="right-0 w-[165px]">
						<div className="fill-dark-blue-1 hover:fill-dark-blue-2 cursor-pointer">
							<DotsIcon className="fill-dark-blue-1 hover:fill-dark-blue-2" />
						</div>
					</Dropdown>
				</div>
			</div>

			<DeleteModal
				handleDelete={handleDelete}
				open={openDeleteModal}
				setOpen={setOpenDeleteModal}
				title="Excluir flow"
				description={
					<p className="text-brand-gray-3 mb-1 ">
						Deseja realmente excluir o flow{" "}
						<span className="font-semibold">“{result.name}”</span>?
						Esta ação é irreversível.
					</p>
				}
			/>
		</div>
	)
}
