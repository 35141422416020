import { Position, useReactFlow } from "@xyflow/react"
import Center from "components/center"
import Handle from "../subcomponents/Handle"

import { TbEye, TbTransform } from "react-icons/tb"
import { RefObject, useEffect, useRef, useState } from "react"
import ActionNodeHandler from "../subcomponents/ActionNodeHandler"
import { Node, NodeData } from "../subcomponents/Flow"
import { useMutation, useQuery } from "@tanstack/react-query"
import useAgentBuilder from "hooks/useAgentBuilder"
import AutosizeInput from "react-input-autosize"
import { serializeNode } from "../utils"
import NodeError from "../subcomponents/NodeError"
import NodeSuccess from "../subcomponents/NodeSuccess"
import { useAtom } from "jotai"
import { graphExecutionAtom } from "../atoms"
import CustomResizable from "components/custom-resizable"
import { NodeExecutionAtom } from "types/agentBuilder"
import NodeTextArea from "../subcomponents/NodeTextArea"
import LoadingOverlay from "../subcomponents/LoadingOverlay"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"

export default function Parser({ data, id }: { data: NodeData; id: string }) {
	const [showOutput, setShowOutput] = useState(true)
	const { listParsers } = useAgentBuilder()
	const { editNode } = useAgentBuilder()
	const { graphId } = data
	const { data: parsers } = useQuery(["parsers"], () => listParsers())
	const { setNodes, getNodes, getNode } = useReactFlow()
	const nodes = getNodes()
	const [nodeName, setNodeName] = useState(data.nodeData.name)
	const [editTitle, setEditTitle] = useState(false)
	const [graphExecution] = useAtom(graphExecutionAtom)

	const inputRef: RefObject<AutosizeInput> &
		(string | RefObject<HTMLInputElement>) = useRef(null)

	const saveTimeout = useRef<NodeJS.Timeout | null>(null)
	const currentNode = getNode(id) as Node | undefined
	const editNodeMutation = useMutation({
		mutationFn: editNode,
		onError: (error) => {
			showToast((error as Error)?.message, ToastType.Error)
		},
	})

	useEffect(() => {
		if (editTitle && inputRef.current) {
			inputRef.current.select()
		}
	}, [editTitle])

	const handleSelectParser = (selectedParser: string) => {
		const newNodes = nodes.map((node) => {
			if (node.id === id) {
				return {
					...node,
					data: {
						...node.data,
						nodeData: {
							...(typeof node.data.nodeData === "object"
								? node.data.nodeData
								: {}),
							parser: selectedParser,
						},
					},
				}
			}
			return node
		})
		setNodes(newNodes)
	}

	const onRename = () => {
		setEditTitle(true)
	}

	const onSaveTitle = () => {
		const newNodes = nodes.map((node) => {
			if (node.id === id) {
				return {
					...node,
					data: {
						...node.data,
						nodeData: {
							...(typeof node.data.nodeData === "object"
								? node.data.nodeData
								: {}),
							name: nodeName || data.nodeData.name,
						},
					},
				}
			}
			return node
		})
		if (!nodeName) {
			setNodeName(data.nodeData.name)
		}
		setNodes(newNodes)
		setEditTitle(false)
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			event.preventDefault()
			onSaveTitle()
		}
	}

	async function saveNode() {
		if (!currentNode) return
		const serializedNode = serializeNode(currentNode, graphId)
		await editNodeMutation.mutateAsync(serializedNode)
	}

	useEffect(() => {
		if (saveTimeout.current) {
			clearTimeout(saveTimeout.current)
		}
		saveTimeout.current = setTimeout(() => {
			saveNode()
		}, 1000)

		return () => {
			if (saveTimeout.current) {
				clearTimeout(saveTimeout.current)
			}
		}
	}, [currentNode])

	const nodeExecution = graphExecution?.[id] as NodeExecutionAtom | undefined
	const nodeResult = nodeExecution?.data?.result?.result

	return (
		<ActionNodeHandler
			id={id}
			onRename={onRename}
			isError={
				!!(nodeExecution && nodeExecution?.data?.status === "ERROR")
			}
		>
			<div className="min-w-[400px] p-2 flex flex-col gap-2 bg-white border-[1px] border-brand-white-4 rounded-2xl ">
				<Handle type="target" position={Position.Left} />
				<Handle type="source" position={Position.Right} />

				<div className="flex items-center justify-between bg-[#D3E5EF] p-2 pr-[20px] rounded-lg">
					<div className="  flex items-center text-[#3D88B4] font-semibold  gap-3">
						<Center className="w-[48px] h-[48px] p-2 bg-white rounded">
							<TbTransform size="32px" color="#3D88B4" />
						</Center>

						<div className="flex flex-col nodrag">
							<p className="text-small-1">Parser</p>

							{!editTitle ? (
								<h4 className=" text-[#3D88B4] max-w-[248px] truncate">
									{nodeName}
								</h4>
							) : (
								<AutosizeInput
									inputClassName="text-[#3D88B4] p-0  m-0 h-[27px]  text-h4 font-semibold border-none bg-transparent  focus-visible:!outline-none max-w-[248px] overflow-hidden text-ellipsis whitespace-nowrap"
									value={nodeName}
									onChange={(event) =>
										setNodeName(event.target.value)
									}
									onBlur={onSaveTitle}
									autoFocus
									ref={inputRef}
									onKeyDown={handleKeyDown}
									maxLength={50}
								/>
							)}
						</div>
					</div>
				</div>

				<div className="p-4 bg-brand-white-2 rounded-lg">
					<div className="flex items-center justify-between w-full mb-3">
						<p className="text-cta-2">Input</p>
					</div>

					<select
						value={data.nodeData.parser}
						onChange={(e) => handleSelectParser(e.target.value)}
						id="parser"
						className="text-gray-900 text-sm rounded-md focus:ring-blue-500  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 bg-white dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-[1px] border-brand-gray-1 py-3 placeholder:text-[#ACACAC] focus:border-light-blue-4"
					>
						{parsers &&
							parsers.map((parser) => (
								<option value={parser.id} key={parser.id}>
									{parser.name}
								</option>
							))}
					</select>
				</div>

				{nodeExecution &&
					nodeExecution?.reason &&
					nodeExecution?.data?.status !== "ERROR" && (
						<CustomResizable>
							<div className="p-4 bg-brand-white-2 rounded-lg overflow-y-auto h-full nowheel">
								<div
									className={`flex items-center justify-between w-full  ${
										showOutput && "mb-3"
									}`}
								>
									<p className="text-cta-2">Output</p>
									<Center
										className="p-[4px] rounded border-[1px] border-[#F0F0F0] nodrag cursor-pointer"
										onClick={() =>
											setShowOutput(!showOutput)
										}
									>
										<TbEye size="16px" color="#6D6D6D" />
									</Center>
								</div>
								{showOutput && (
									<>
										{nodeExecution.reason !== "START" ? (
											Array.isArray(nodeResult) ? (
												nodeResult.map(
													(
														result: string,
														index: number,
													) => (
														<NodeTextArea
															key={index}
															value={result}
															onChange={() => {}}
														/>
													),
												)
											) : (
												<NodeTextArea
													value={
														nodeExecution?.data
															?.result?.result
													}
													onChange={() => {}}
												/>
											)
										) : (
											<>
												<NodeTextArea />
												<LoadingOverlay />
											</>
										)}
									</>
								)}
							</div>
						</CustomResizable>
					)}

				{nodeExecution?.data && nodeExecution?.reason === "ERROR" && (
					<NodeError error={nodeExecution?.detail?.error || ""} />
				)}
				{nodeExecution?.data &&
					nodeExecution?.data?.status === "SUCCESS" && (
						<NodeSuccess
							nodeExecutionStatus={nodeExecution?.data}
						/>
					)}
			</div>
		</ActionNodeHandler>
	)
}
