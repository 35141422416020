interface ButtonProps {
	children?: React.ReactNode
	variant?: "contained" | "outlined" | "text"
	color?: "red" | "purple" | "gray" | "default"
	size?: "small" | "medium" | "large"
	loading?: boolean
	disabled?: boolean
	icon?: React.ReactNode
	iconPosition?: "left" | "right"
	onClick?: () => void
	className?: string
}

export default function Button({
	children,
	variant = "contained",
	color = "default",
	size = "large",
	disabled = false,
	icon,
	onClick,
	iconPosition = "left",
	className,
}: ButtonProps) {
	const sizes = {
		large: "px-4 py-3 text-cta-1",
		medium: "px-3 py-2 text-cta-2",
		small: "p-1 text-small-1 h-[24px] !rounded",
	}

	const baseStyles = {
		contained: `rounded-lg  bg-[#3083FF] hover:bg-[#1B64F5] text-white font-semibold ${sizes[size]}`,
		outlined: `rounded-lg  bg-white hover:bg-[#EEF6FF] border-[1px] border-[#EEF6FF]  text-[#3083FF] font-semibold ${sizes[size]}`,
		text: `rounded-lg p-0 bg-transparent  text-[#3083FF] hover:text-[#1B64F5] font-semibold `,
	}

	const buttonColors = {
		contained: {
			default: "",
			red: "bg-[#FF6363] hover:bg-[#F83B3B] text-white",
			purple: `bg-[#A734FC] hover:bg-[#9D35EB] text-white hover:shadow-purple-button disabled:shadow-none`,
			gray: "",
		},
		outlined: {
			default: "",
			red: " text-[#FF6363] hover:bg-[#FFF1F1]",
			purple: "border-[#A734FC] text-[#A734FC]",
			gray: "border-[#F0F0F0] text-[#6D6D6D] hover:bg-[#F0F0F0]",
		},
		text: {
			default: "",
			red: "text-[#FF6363] hover:text-[#F83B3B]",
			purple: "",
			gray: "",
		},
	}

	const disabledStyles = {
		contained:
			"!bg-[#F5F5F5] !text-[#B0B0B0] hover:!bg-[#F5F5F5] cursor-not-allowed",
		outlined:
			"!border-[#F5F5F5] !text-[#BDBDBD] !bg-white cursor-not-allowed",
		text: "!text-[#BDBDBD] cursor-not-allowed",
	}

	return (
		<button
			className={`flex items-center justify-center gap-2 !font-Red-Hat-Display ${
				baseStyles[variant]
			} ${buttonColors[variant][color]} ${
				disabled ? disabledStyles[variant] : ""
			} ${className}`}
			onClick={onClick}
			disabled={disabled}
		>
			{icon && iconPosition === "left" && (
				<span className="button-icon">{icon}</span>
			)}
			{children}
			{icon && iconPosition === "right" && (
				<span className="button-icon">{icon}</span>
			)}
		</button>
	)
}
