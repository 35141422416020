import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import { useEffect, useRef, useState } from "react"
import DocumentIcon from "icons/DocumentIcon"
import { Dropdown, DropdownSectionProps } from "components/dropdown"
import ConfigIcon from "icons/ConfigIcon"
import ExitIcon from "icons/ExitIcon"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import Toast from "components/toast"
import { ROUTES } from "routes"
import { useAtom } from "jotai"
import { WS_SERVER } from "config"
import SaveDocumentModal from "./subcomponents/SaveDocumentModal"
import Profile from "./subcomponents/Profile"
import Credit from "./subcomponents/Credit"
import MenuItem from "./subcomponents/MenuItem"
import LogoContainer from "./subcomponents/LogoContainer"
import BellIcon from "icons/BellIcon"
import { useJwt } from "hooks/useJwt"
import { saveNotificationsInLocalStorage } from "utils/saveNotificationsInLocalStorage"
import ExclamationIcon from "icons/ExclamationIcon"
import { formatDate } from "utils/formatDate"
import UpgradeButton from "components/upgrade-button"
import SupportIcon from "icons/SupportIcon"
import { getDocumentStatus } from "utils/getDocumentStatus"
import ModalOnboarding from "pages/MyDocumentsPage/subcomponents/ModalOnboarding"
import HammerIcon from "icons/HammerIcon"
import FEATURE_FLAGS from "feature-flags"
import { useFeatureFlag } from "hooks/useFeatureFlag"
import { modalAtom, openLexAtom, showLexAtom } from "./subcomponents/atoms"
import { showToast } from "components/toast/functions"
import { ToastType } from "components/toast/types"
import { haveFooterAtom } from "pages/AnalizeDocumentPage/subcomponents/LexAssistant/atoms"
import useLegalDocumentAPI from "hooks/useLegalDocumentAPI"
import useNotificationAPI from "hooks/useNotificationAPI"
import useSubscriptionAPI from "hooks/useSubscriptionAPI"
import useCurrentUser from "hooks/useCurrentUser"
import StepModal from "components/step-modal"
import ModelIcon from "icons/ModelIcon"
import useFeatureModalAPI from "hooks/useFeatureModalAPI"
import { isBefore } from "date-fns"
import useFreePlan from "hooks/useFreePlan"
import ChatMagicIcon from "icons/ChatMagicIcon"
import ArrowChangeIcon from "icons/ArrowChangeIcon"
import { stepAtom } from "pages/DocumentPage/atoms"
import useBasicPlan from "hooks/useBasicPlan"
import NewSidebar from "components/sidebar"

export default function SidebarPages() {
	const [openModalSaveDocument, setOpenModalSaveDocument] = useAtom(modalAtom)
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const { documentId } = useParams()

	const { retrieveLegalDocument } = useLegalDocumentAPI()
	const { getFeatureModal } = useFeatureModalAPI()
	const { logout } = useAuth0()
	const [jwt] = useJwt()
	const [, setOpenLex] = useAtom(openLexAtom)
	const [, setShowLex] = useAtom(showLexAtom)
	const [openMenu, setOpenMenu] = useState(false)
	const [routeToNavigate, setRouteToNavigate] = useState("")
	const [openOnboardingModal, setOpenOnboardingModal] = useState(false)
	const [openStepModal, setOpenStepModal] = useState(false)
	const { data: featureModal } = useQuery(["featureModal"], getFeatureModal)
	const { data } = useQuery(
		["legalDocument", documentId],
		() => retrieveLegalDocument(documentId!),
		{
			enabled: !!documentId,
		},
	)
	const isFreeUser = useFreePlan()
	const isBasicUser = useBasicPlan()

	const { data: isAdminUserFeatureFlagEnabled } = useFeatureFlag(
		FEATURE_FLAGS.isAdminUser,
	)
	const { data: newDocumentsFlow } = useFeatureFlag(
		FEATURE_FLAGS.newDocumentsFlow,
	)
	const [documentStep] = useAtom(stepAtom)
	const wsRef = useRef<WebSocket>()
	const queryClient = useQueryClient()
	const [haveFooter, setHaveFooter] = useAtom(haveFooterAtom)

	useEffect(() => {
		if (!pathname.includes("/document/")) {
			setHaveFooter(false)
			setShowLex(false)
			setOpenLex(false)
		}
	}, [pathname])

	const handleLogout = async () => {
		logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		})
	}

	function handleNavigateToSettings() {
		if (!documentId) {
			navigate(ROUTES.settings())
			return
		}
		const splittedPathName = pathname.split("/")[1]

		if (splittedPathName === "document") {
			const status = getDocumentStatus(data)
			if (status !== "draft") {
				navigate(ROUTES.settings())
				return
			}

			const localStorageSaveDocument = localStorage.getItem(
				"documentAlreadyCheck",
			)
				? JSON.parse(localStorage.getItem("documentAlreadyCheck")!)
				: []

			if (
				!localStorageSaveDocument ||
				!localStorageSaveDocument.includes(documentId)
			) {
				setRouteToNavigate(ROUTES.settings())
				setOpenModalSaveDocument({
					open: true,
					isCancel: false,
				})
				return
			}

			if (localStorageSaveDocument.includes(documentId)) {
				navigate(ROUTES.settings())
				return
			}
		}
		navigate(ROUTES.settings())
	}

	const sectionsData = [
		isAdminUserFeatureFlagEnabled && {
			label: "Painel usuário",
			className:
				"text-[#AE30FC] flex cursor-pointer items-center px-[8px] py-[8px] duration-200 ease-out hover:bg-[#f6f6f6] hover:text-dark-blue-2",
			onClick: () => navigate(ROUTES.admin()),
			icon: <ArrowChangeIcon />,
		},
		{
			label: "Configurações",
			onClick: handleNavigateToSettings,
			icon: <ConfigIcon />,
		},
		!isFreeUser &&
			!isBasicUser && {
				label: "Suporte WhatsApp",
				onClick: () =>
					window.open("https://wa.me/554197081727", "_blank"),
				icon: <SupportIcon />,
			},
		{
			label: "Sair",
			onClick: handleLogout,
			icon: <ExitIcon />,
		},
	].filter(Boolean)

	const sections = [
		{
			items: sectionsData,
		},
	]

	const { listNotifications } = useNotificationAPI()
	const { subscriptionReactivatePlan } = useSubscriptionAPI()
	const user = useCurrentUser()
	const subscriptionReactivatePlanMutation = useMutation({
		mutationFn: subscriptionReactivatePlan,
	})

	const { data: notifications } = useQuery(["notifications"], () =>
		listNotifications(),
	)

	const menuItems = [
		{
			Icon: DocumentIcon,
			name: "Documentos",
			path: ROUTES.myDocuments(),
		},

		{
			Icon: () => <HammerIcon size="24" />,
			name: "Jurisprudência",
			path: ROUTES.jurisprudencesSearch(),
		},
		{
			Icon: () => <ModelIcon className="fill-current" />,
			name: "Modelos",
			path: ROUTES.listModels(),
		},
		{
			Icon: ChatMagicIcon,
			name: "Assistente jurídico",
			path: ROUTES.legalAssistant(),
		},
		{
			Icon: BellIcon,
			name: "Notificações",
			path: ROUTES.notification(),
		},
	]

	const isDocumentCompletePage =
		pathname.includes("/document/") && documentStep === 1

	function handleKeepPlan() {
		subscriptionReactivatePlanMutation.mutateAsync().then(() => {
			queryClient.invalidateQueries(["current-user", jwt])
			showToast("Sucesso! Sua assinatura será mantida.")
		})
	}

	useEffect(() => {
		wsRef.current = new WebSocket(`${WS_SERVER.baseUrl}/?token=${jwt}`)
		wsRef.current.onopen = () => {}

		wsRef.current.onmessage = (e) => {
			const parsedEvent =
				e.data === "Establish connection successfully!"
					? e.data
					: JSON.parse(e.data)
			if (e.data === "ping") {
				wsRef.current?.send("pong")
				return
			}

			if (parsedEvent.event_type === "NOTIFICATION") {
				if (parsedEvent.payload.visualized) return
				if (parsedEvent.payload.type === "success_generate_document") {
					showToast(
						`O documento ${parsedEvent.payload.details.legal_document.title} está completo.`,
						ToastType.Success,
						{
							text: "Ver documento",
							onClick: () => {
								navigate(
									ROUTES.documentDetail({
										id: parsedEvent.payload.details
											.legal_document.id,
									}),
								)
							},
						},
						{
							toastId:
								parsedEvent.payload.details.legal_document.id,
						},
					)
				}
				if (parsedEvent.payload.type === "error_generate_document") {
					showToast(
						`Tivemos um erro ao tentar gerar o documento ${parsedEvent.payload.details.legal_document.title}, por favor tente novamente.`,
						ToastType.Error,
						{
							text: "Verificar",
							onClick: () => {
								navigate(
									ROUTES.documentDetail({
										id: parsedEvent.payload.details
											.legal_document.id,
									}),
								)
							},
						},
						{
							toastId:
								parsedEvent.payload.details.legal_document.id,
						},
					)
				}

				queryClient.invalidateQueries(["notifications"])
			}
		}
		wsRef.current.onclose = () => {}

		return () => {
			wsRef.current?.close(1000, "finished")
		}
	}, [])

	useEffect(() => {
		if (notifications?.results) {
			saveNotificationsInLocalStorage(notifications?.results)
		}
	}, [notifications])

	useEffect(() => {
		if (user && featureModal) {
			const isBeforeData = isBefore(
				new Date(user.user_state.seen_feature_modal!),
				new Date(featureModal?.published_at),
			)
			if (
				(isBeforeData || !user.user_state.seen_feature_modal) &&
				featureModal.feature_modal_enabled
			) {
				setOpenStepModal(true)
			}
			setOpenOnboardingModal(!user?.is_onboarded)
		}
	}, [user, featureModal])

	if (newDocumentsFlow) {
		return <NewSidebar />
	}

	return (
		<main className="absolute inset-0 flex bg-ice-white font-lato">
			{!isDocumentCompletePage && (
				<div
					className={`flex min-w-[220px] w-[220px] flex-col bg-white overflow-y-scroll no-scrollbar`}
				>
					<div
						className={
							"z-50 col-start-1 col-end-3 row-span-full flex h-screen w-[220px] flex-col text-white"
						}
					>
						<LogoContainer />
						<div className={`mt-5 w-[100%] `}>
							{menuItems.map((item) => {
								return (
									<MenuItem
										Icon={item.Icon}
										name={item.name}
										path={item.path}
										key={item.name}
										setRouteToNavigate={setRouteToNavigate}
									/>
								)
							})}
						</div>
					</div>
					<div className="p-4">
						{user?.subscription?.cancel_at_period_end && (
							<div className="bg-brand-orange-opacity p-4 mt-2 ">
								<div className="flex items-center">
									<ExclamationIcon
										className="fill-brand-orange mr-2 mt-1 flex"
										size="20"
									/>{" "}
									<p className="text-[14px] text-brand-orange w-[128px]">
										Seu plano cancela em:{" "}
										{
											formatDate(
												user?.subscription?.end_period,
											).split(" ")[0]
										}
										<p
											className="font-bold cursor-pointer"
											onClick={handleKeepPlan}
										>
											Manter assinatura
										</p>
									</p>
								</div>
							</div>
						)}
						<div className="flex items-center flex-col">
							{isFreeUser && (
								<div className="w-full ">
									<UpgradeButton
										isBig
										className={"w-full justify-center"}
									/>
								</div>
							)}

							<Credit />
						</div>
						<div className={`mt-4 w-full `}>
							<Dropdown
								className={`right-[5px] bottom-[58px] min-w-[176px]`}
								sections={sections as DropdownSectionProps[]}
								shouldCloseAfterClick
								open={openMenu}
								setOpen={setOpenMenu}
							>
								<div className="align-center flex cursor-pointer">
									{user && (
										<Profile
											user={user}
											openMenu={openMenu}
										/>
									)}
								</div>
							</Dropdown>
						</div>
					</div>
				</div>
			)}

			<div
				className={`${
					!isDocumentCompletePage
						? `col-span-full col-start-3 row-span-full h-[100vh] row-start-3 w-full overflow-y-scroll relative px-6 pt-6 pb-[80px] ${
								haveFooter && "mb-[80px]"
						  }`
						: "w-full h-screen overflow-y-hidden"
				}`}
				id="document-content"
			>
				<Outlet />
			</div>
			<Toast />

			<SaveDocumentModal
				open={openModalSaveDocument}
				setOpen={setOpenModalSaveDocument}
				documentId={pathname.split("/")[2]}
				routeToNavigate={routeToNavigate}
			/>
			<ModalOnboarding
				open={openOnboardingModal}
				setOpen={setOpenOnboardingModal}
			/>
			{featureModal && (
				<StepModal
					open={openStepModal}
					setOpen={setOpenStepModal}
					featureModal={featureModal}
				/>
			)}
		</main>
	)
}
