import Flex from "components/flex"

interface TagShortcutProps {
	icon?: React.ReactNode
	text: string
	className?: string
	textClassName?: string
	onClick?: () => void
}

export default function TagShortcut({
	icon,
	text,
	className,
	textClassName,
	onClick,
}: TagShortcutProps) {
	return (
		<Flex
			className={`gap-2 py-2 px-3 bg-[rgba(255, 255, 255, 0.50)] border border-[#F0F0F0] rounded-[500px] hover:bg-[#F5F5F5] hover:text-[#3D3D3D] text-[#6D6D6D] cursor-pointer w-fit ${className}`}
			onClick={onClick}
		>
			{icon && <div>{icon}</div>}
			<p className={`text-cta-2 font-semibold ${textClassName}`}>
				{text}
			</p>
		</Flex>
	)
}
